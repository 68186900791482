import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Employee from '../employee/property';
import { getCivilityLabel } from '../person/services';
import Property from '../shared/property';
import { viewEndPoint } from './constants';
import Phone from '../shared/phone';
import Address from '../address/property';

const Info = (props) => {
    const name = props.link ? <Link to={`${viewEndPoint}/${props.id}`}>{props.name}</Link> : props.name;


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
                <Property label="Nom" value={<Employee data={name} />} />
                <Property label="Civilité" value={getCivilityLabel(props.civility)} />
                <Property label="Poste" value={props.jobTitle} />
                <Property label="Téléphone" value={<Phone data={props.phone} />} />
                <Property label="Fax" value={<Phone data={props.fax} />} />
                <Property label="Adresse" value={<Address data={props.address}/>} />
            </Grid>
        </Grid>
    );
};

export default Info;
