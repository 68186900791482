import React, { useEffect, useContext } from 'react';
import Print from './print';
import PrintIcon from '@material-ui/icons/Print';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import { Table } from '../shared/grid';
import { default as useGlobalStyles } from '../shared/theme';
import { viewEndPoint, editEndPoint, apiEndPoint } from './constants';
import { columns, sort } from './columns';
import { ToolbarHeader } from './toolbar';
import useDialog from '../../hooks/dialog';
import Page from '../shared/page';
const List = () => {
    const globalClasses = useGlobalStyles();
    const history = useHistory();
    const toolBar = useToolbar();
    const { state: { refresh } } = useContext(GlobalContext);
    const authorization = useAuthorization();
    const dialog = useDialog();

    const download = (data) => {
        dialog.popup(<Print data={data} />);
    };

    const onAdd = () => {
        history.push(editEndPoint);
    };
    const handleRowClick = (event, key, row) => {
        const path = `${viewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else if (authorization.isInRoles(roles.system, roles.administrator, roles.preparer)) {
            history.push(path);
        }
    };

    useEffect(() => {
        toolBar.show({
            title: <ToolbarHeader />,
            actions: {
                add: {
                    onClick: onAdd,
                    hide: !authorization.isInRoles(roles.system, roles.administrator),
                },
                customs: [
                    {
                        icon: <PrintIcon fontSize="small" />,
                        label: 'Imprimer',
                        onClick: download,
                    },
                ],
            },
        });
    }, [refresh]);

    return (
        <Page title = "Lots">
            <div className={globalClasses.content}>

                <Table
                    rowKey="id"
                    sort={sort}
                    endPoint={apiEndPoint}
                    onRowClick={handleRowClick}
                    columns={columns}
                />
            </div>
        </Page>
    );
};

export default List;
