/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import NoteIcon from '@material-ui/icons/ChatBubble';
import { useParams } from 'react-router';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import useApi from './api';
import Tabs from '../shared/tabs';
import { status as orderStatus, editEndPoint } from './constants';
import { roles } from '../authorization/constants';
import Info from './info';
import BottomAppBar from '../shared/bottomAppBar';
import Property from '../shared/property';
import { formatter } from '../../services/currency';
import { default as useGlobalStyles } from '../shared/theme';
import useStyles from './theme';
import Page from '../shared/page';
import { ToolbarHeader } from './toolbar';
import { Table } from '../shared/grid';
import { columns as purchaseItemsColumns, sort as purchaseItemsSort } from '../purchasesItem/columns';
import { useHistory } from 'react-router-dom';

const Details = () => {
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const api = useApi();
    const authorization = useAuthorization();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);
    const history = useHistory();

    const handleEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };
    const handleDisplayItem = (event, index, row) => {
        if (row.lot) {
         dialog.displayPurchasesItem(row);
        }
    };

    const handleEditNote = (data) => {
        dialog.updatePurchaseNote(data);
    };

    const handleConfirm = (data) => {
        dialog.confirm(`Voulez-vous confirmer la commande d'achat ${data.id}`, () => {
            handleStatusChangeConfirmed(data, orderStatus.confirmed);
        });
    };

    const handleStatusChangeConfirmed = (data, status) => {
        api.updateStatus(data, status);
    };

    const handleDelete = (data) => {
        dialog.confirm(`Voulez-vous supprimer la commande d'achat ${data.id}`, () => {
            hendleDeleteConfirmed(data);
        });
    };

    const hendleDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const getActions = (data) => ({
        edit: {
            onClick: handleEdit,
            hide: data.status !== orderStatus.draft || (!authorization.isInRoles(roles.system, roles.administrator)),
        },
        'delete': {
            onClick: handleDelete,
            hide: data.status !== orderStatus.draft || (!authorization.isInRoles(roles.system, roles.administrator)),
        },
        customs: [
            {
                icon: <NoteIcon fontSize="small" />,
                label: 'Note',
                onClick: handleEditNote,
            },
            {
                icon: <CheckIcon fontSize="small" />,
                label: 'Confirmer',
                onClick: handleConfirm,
                hide: data.status !== orderStatus.draft || (!authorization.isInRoles(roles.system, roles.administrator) && !isOwner(data)),
            },
        ],
    });

    const GeneralContent = ({ data }) => <>
     <Info {...data} />
     <Table
       rowKey="id"
       sort={purchaseItemsSort}
       data={state.items}
       // actions={getItemActions(state)}
       onRowClick={handleDisplayItem}
       columns={purchaseItemsColumns}
       rowsPerPage={10}
     />
                                         </>;
    const tabs = [
        {
            label: 'Général',
            component: <GeneralContent data={state} />,
        },

    ];

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    useEffect(() => {
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    return (

        <>
            {
                state && (
                    <Page title ={`[${state.id}] Commande d'achat`}>
                        <Tabs tabs={tabs} data={state} />
                        <div className={globalClasses.content}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <BottomAppBar>
                                        <Box className={classes.flexGrow} />
                                        <Box display="flex" spacing={1}>
                                            {
                                                state.discountAmount > 0 && (
                                                    <Box className={classes.amountProperty}>
                                                        <Property
                                                            valueVariant="subtitle1"
                                                            label="MT REMISE"
                                                            valueColor="secondary"
                                                            value={formatter.format(state.discountAmount)}
                                                        />
                                                    </Box>
                                                )
                                            }
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant HT" valueColor="default" value={formatter.format(state.etAmount)} />
                                            </Box>
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant TVA" valueColor="default" value={formatter.format(state.vatAmount)} />
                                            </Box>
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant TTC" value={formatter.format(state.itAmount)} />
                                            </Box>
                                        </Box>
                                    </BottomAppBar>
                                </Grid>
                            </Grid>
                        </div>
                    </Page>)
            }
        </>

    );
};

export default Details;
