import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { formatPercent } from '../../services/number';
import { getDiscountTypeLabel } from '../shared/services';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'CODE',
        field: 'code',
        sorting: true,
    },
    {
        label: 'Nom',
        field: 'product.displayName',
        sorting: true,
    },
    {
        label: 'PU',
        field: 'price',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.price),
    },
    {
        label: 'Remise de vente',
        field: 'saleDiscountType',
        sorting: true,
        render: (index, column, row) => getDiscountTypeLabel(row.saleDiscountType),
    },
    {
        label: '% UG vente',
        field: 'saleDiscountRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatPercent(row.saleDiscountRate),
    },
    {
        label: 'Date d\'achat',
        field: 'purchaseDate',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.purchaseDate),
    },
    {
        label: 'Date de péremp.',
        field: 'expirationDate',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.expirationDate),
    },
    {
        label: 'Quantité colis',
        field: 'quantityPackage',
        align: 'right',
        sorting: true,
    },
    {
        label: 'Quantité',
        field: 'currentQuantity',
        align: 'right',
        sorting: true,
    },
    {
        label: 'Montant',
        field: 'purchaseAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.purchaseAmount),
    },
];
