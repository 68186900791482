import React from 'react';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Login from './components/authentification/login/form';
import AuthorizeRoute from './components/authorization/route';
import { default as Dashboard } from './components/dashboard/index';
import { default as DeliveryDetails } from './components/delivery/details';
import { default as DeliveryEdit } from './components/delivery/edit';
import { default as DeliveryList } from './components/delivery/list';
import { default as DeliveryStatusEdit } from './components/delivery/status';
import { default as EmployeeDetails } from './components/employee/details';
import { default as EmployeeEdit } from './components/employee/edit';
import { default as EmployeeList } from './components/employee/list';
import { default as LotDetails } from './components/lot/details';
import { default as LotEdit } from './components/lot/edit';
import { default as LotList } from './components/lot/list';
import { default as OrderDetails } from './components/order/details';
import { default as OrderList } from './components/order/list';
import { default as OrganizationDetails } from './components/organization/details';
import { default as OrganizationEdit } from './components/organization/edit';
import { default as OrganizationList } from './components/organization/list';
import { default as PurchasesDetails } from './components/purchases/details';
import { default as PurchasesList } from './components/purchases/list';
import { Edit as PurchasesEdit } from './components/purchases/edit';
import { default as PaymentDetails } from './components/payment/details';
import { default as PaymentEdit } from './components/payment/edit';
import { default as PaymentList } from './components/payment/list';
import { default as ProductDetails } from './components/product/details';
import { default as ProductList } from './components/product/list';
import { default as Reports } from './components/report';
import Error from './components/shared/error';
import Layout from './components/shared/layout';
import { default as UserDetails } from './components/user/details';
import { default as UserList } from './components/user/list';
import Container from './container';
import Provider from './provider';
import { default as InvoiceList } from './components/invoice/list';
import { default as InvoiceDetails } from './components/invoice/details';

const theme = createTheme({
    palette: {

    },
});

const App = () => (
    <SnackbarProvider
        maxSnack={6}
        anchorOrigin={
            {
                vertical: 'bottom',
                horizontal: 'left',
            }
        }
    >
        <Provider>
            <ThemeProvider theme={theme}>
                <Container>
                    <Router>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Layout>
                                <AuthorizeRoute exact path="/" component={Dashboard} />
                                <AuthorizeRoute exact path="/error" component={Error} />
                                <AuthorizeRoute exact path="/error/:number" component={Error} />
                                <AuthorizeRoute exact path="/orders" component={OrderList} />
                                <AuthorizeRoute exact path="/orders/:id" component={OrderDetails} />
                                <AuthorizeRoute exact path="/invoices" component={InvoiceList} />
                                <AuthorizeRoute exact path="/invoices/view/:id" component={InvoiceDetails} />
                                <AuthorizeRoute exact path="/purchases" component={PurchasesList} />
                                <AuthorizeRoute exact path="/purchases/view/:id" component={PurchasesDetails} />
                                <AuthorizeRoute exact path="/purchases/edit/:id?" component={PurchasesEdit} />
                                <AuthorizeRoute exact path="/deliveries" component={DeliveryList} />
                                <AuthorizeRoute exact path="/deliveries/edit/:id?" component={DeliveryEdit} />
                                <AuthorizeRoute exact path="/deliveries/edit/:id/status" component={DeliveryStatusEdit} />
                                <AuthorizeRoute exact path="/deliveries/view/:id" component={DeliveryDetails} />
                                <AuthorizeRoute exact path="/products" component={ProductList} />
                                <AuthorizeRoute exact path="/products/:id" component={ProductDetails} />
                                <AuthorizeRoute exact path="/lots" component={LotList} />
                                <AuthorizeRoute exact path="/lots/edit/:id?" component={LotEdit} />
                                <AuthorizeRoute exact path="/lots/view/:id" component={LotDetails} />
                                <AuthorizeRoute exact path="/organizations" component={OrganizationList} />
                                <AuthorizeRoute exact path="/organizations/edit/:id?" component={OrganizationEdit} />
                                <AuthorizeRoute exact path="/organizations/view/:id" component={OrganizationDetails} />
                                <AuthorizeRoute exact path="/payments" component={PaymentList} />
                                <AuthorizeRoute exact path="/payments/edit/:id?" component={PaymentEdit} />
                                <AuthorizeRoute exact path="/payments/view/:id" component={PaymentDetails} />
                                <AuthorizeRoute exact path="/employees" component={EmployeeList} />
                                <AuthorizeRoute exact path="/employees/edit/:id?" component={EmployeeEdit} />
                                <AuthorizeRoute exact path="/employees/view/:id" component={EmployeeDetails} />
                                <AuthorizeRoute exact path="/reports" component={Reports} />
                                <AuthorizeRoute exact path="/users" component={UserList} />
                                <AuthorizeRoute exact path="/users/:id" component={UserDetails} />
                            </Layout>
                        </Switch>
                    </Router>
                </Container>
            </ThemeProvider>
        </Provider>
    </SnackbarProvider>
);

export default App;
