import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import State from '@material-ui/icons/MonetizationOn';
import useDialog from '../../hooks/dialog';
import { Formik } from 'formik';
import { State as Form } from './forms/edit';
import { validationStateSchema as validationSchema } from './validationSchema';
import useApi from './api';
import { GlobalContext } from '../../globalState';
import { toDateForForms } from '../../services/date';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Action = (props) => {
    const classes = useStyles();
    const { title, icon, onClick } = props;

    return (
        <ListItem button onClick={onClick}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const Print = (props) => {
    const dialog = useDialog();

    const clientState = () => {
        dialog.downloadState(props);
    };


    return (
        <List>
            <Action icon={<State />} title="État" onClick={() => clientState()} />
        </List>
    );
};

export default Print;

export const ClientState = (props) => {
    const { state } = useContext(GlobalContext);
    const api = useApi();
    const dialog = useDialog();


    const onSubmit = (data) => {
        api.stateDownload(toEditDataContract(data));
        dialog.hide();
    };
    const toEditDataContract = (data) => ({
        id: props.data.data.id,
        startDate: toDateForForms(data.startDate),
        endDate: toDateForForms(data.endDate),
        fileType: data.fileType,
    });


    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={
                {
                    ...props.values,
                }
            }
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    );
};
