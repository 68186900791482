import React from 'react';

import Grid from '@material-ui/core/Grid';
import { roles } from '../authorization/constants';
import useAuthorization from '../../hooks/authorization';
import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { formatPercent } from '../../services/number';
import Property from '../shared/property';
import { getDiscountTypeLabel, getMarginRate } from '../shared/services';

const Abstract = (props) => {
    const { saleDiscountType, saleDiscountRate, purchaseDiscountRate, product, price, expirationDate, currentQuantity, quantityPackage } = props;
    const marge = parseFloat(getMarginRate(product.publicPrice, price, product.vatRate, product.extraCostHonorary));
    const authorization = useAuthorization();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
                <Property label="Remise de vente" value={getDiscountTypeLabel(saleDiscountType)} />
                <Property label="Taux UG de la remise de vente" value={formatPercent(saleDiscountRate)} />
                {
                    authorization.isInRoles(roles.system, roles.administrator) && (
                        <>
                            <Property label="Taux UG de la remise d'achat" value={formatPercent(purchaseDiscountRate)} />
                        </>)
                }
                <Property label="PU" value={formatter.format(price)} />
                <Property label="Marge" value={formatPercent(marge)} />
                <Property label="Date de péremption" value={formatUtcToLocalDate(expirationDate)} />
                <Property label="Quantité Colis" value={quantityPackage} />
                <Property label="Quantité disponible" value={currentQuantity} valueColor="secondary" />
            </Grid>
        </Grid>
    );
};

export default Abstract;
