import React from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { currency } from '../../services/currency';

const Form = (props) => {
    const {
        values: { name, brand, form, dosage, conditioning, storageType, price, marginRate, extraCostHonorary, vatRate, publicPrice },
        errors,
        touched,
        handleSubmit,
        handleChange,
    } = props;

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="brand"
                        helperText={touched.brand ? errors.brand : ''}
                        error={Boolean(errors.brand)}
                        label="Nom commercial"
                        value={brand}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="name"
                        multiline
                        rows={4}
                        rowsMax={4}
                        helperText={touched.name ? errors.name : ''}
                        error={Boolean(errors.name)}
                        label="DCI"
                        value={name}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="form"
                        helperText={touched.form ? errors.form : ''}
                        error={Boolean(errors.form)}
                        label="Forme"
                        value={form}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="dosage"
                        helperText={touched.dosage ? errors.dosage : ''}
                        error={Boolean(errors.dosage)}
                        label="Dosage"
                        value={dosage}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        required
                        name="conditioning"
                        helperText={touched.conditioning ? errors.conditioning : ''}
                        error={Boolean(errors.conditioning)}
                        label="Conditionnement"
                        value={conditioning}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel id="storage-type-select-label">Nature de stockage</InputLabel>
                        <Select
                            labelId="storage-type-select-label"
                            id="storage-type-select"
                            name="storageType"
                            label="Nature de stockage"
                            value={storageType}
                            onChange={handleChange}
                        >
                            <MenuItem value={0}>Normal</MenuItem>
                            <MenuItem value={1}>Frais</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="price"
                        type="number"
                        helperText={touched.price ? errors.price : ''}
                        error={Boolean(errors.price)}
                        label="PU"
                        value={price}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="marginRate"
                        type="number"
                        helperText={touched.marginRate ? errors.marginRate : ''}
                        error={Boolean(errors.marginRate)}
                        label="Marge"
                        value={marginRate}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        type="number"
                        name="extraCostHonorary"
                        helperText={touched.extraCostHonorary ? errors.extraCostHonorary : ''}
                        error={Boolean(errors.extraCostHonorary)}
                        label="SHP"
                        value={extraCostHonorary}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="vatRate"
                        type="number"
                        helperText={touched.vatRate ? errors.vatRate : ''}
                        error={Boolean(errors.vatRate)}
                        label="TVA"
                        value={vatRate}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="publicPrice"
                        type="number"
                        helperText={touched.publicPrice ? errors.publicPrice : ''}
                        error={Boolean(errors.publicPrice)}
                        label="PPA"
                        value={publicPrice}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
