import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatUtcToLocalDate } from '../../services/date';
import Address from '../address/property';
import Employee from '../employee/property';
import Invoice from '../invoice/property';
import Order from '../order/property';
import Organization from '../organization/property';
import Delivery from '../delivery/property';
import Property from '../shared/property';
import EmailAddress from '../shared/emailAddress';
import Phone from '../shared/phone';
import { getStatusTypeLabel } from './services';

const Info = (props) => {
    const { id, date, normalParcelNumber, coldParcelNumber, note, seller, status, thirdParty, delivery, lastInvoice, children } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Property label="Numéro" value={id} />
                <Property label="Statut" value={getStatusTypeLabel(status)} />
                <Property label="Date" value={formatUtcToLocalDate(date)} />
                <Property label="Vendeur" value={<Employee data={seller} />} />
                <Property label="Note" value={note} />
                <Property label="Commande" value={<Order data={id}/>} />
                <Property label="Facture" value={<Invoice data={lastInvoice} />} />
                <Property label="Livraison" value={<Delivery data={delivery} />} />
                <Property label="Date de la livraison" value={formatUtcToLocalDate(delivery?.date)} />
                <Property label="Nombre de colis normal" value={normalParcelNumber} />
                <Property label="Nombre de colis froid" value={coldParcelNumber} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="Client" value={<Organization data={thirdParty} />} />
                <Property label="Email" value={<EmailAddress data={thirdParty?.email} />} />
                <Property label="Téléphone" value={<Phone data={thirdParty?.phone} />} />
                <Property label="Fax" value={<Phone data={thirdParty?.fax} />} />
                <Property label="N° de registre de commerce" value={thirdParty?.crn} />
                <Property label="N° d'identification fiscale" value={thirdParty?.tin} />
                <Property label="Article d'imposition" value={thirdParty?.ta} />
                <Property label="Adresse" value={<Address data={thirdParty?.address} />} />
            </Grid>
            { children }
        </Grid>
    );
};

export default Info;
