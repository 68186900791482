import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Property from '../shared/property';

const useStyles = makeStyles((theme) => ({
    chipContainer: {
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const Locations = (props) => {
    const classes = useStyles();
    const { title, color, data, onDelete } = props;

    return (
        <Property label={title} display="block">
            <div className={classes.chipContainer}>
                {
                    data.map((item) => <Chip
                        key={item.id}
                        label={item.label}
                        variant="outlined"
                        color={color ?? 'primary'}
                        size="small"
                        onDelete={typeof onDelete === 'function' ? () => onDelete(item) : undefined}
                        icon={<LocationOnIcon />}
                    />)
                }
            </div>
        </Property>
    );
};

export default Locations;
