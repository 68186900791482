/* eslint-disable no-fallthrough */
import React, { useEffect, useState, useContext } from 'react';
import Employee from '../employee/property';
import Grid from '@material-ui/core/Grid';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useLoader from '../../hooks/loader';
import useToolbar from '../../hooks/toolbar';
import mapImage from '../../images/map.png';
import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { roles } from '../authorization/constants';
import { status as orderSatus } from '../order/constants';
import Property from '../shared/property';
import { Table, useStyles as useTableStyles } from '../shared/grid';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { default as useOrderDeliveryApi } from './orderDelivery/api';
import { status as deliverySatus, editEndPoint } from './constants';
import Locations from './locations';
import { columns as orderColumns, sort } from './orderDelivery/columns';
import Print from './print';
import { getStatusTypeLabel } from './services';
import { ToolbarHeader } from './toolbar';

const Details = () => {
    const globalClasses = useGlobalStyles();
    const loader = useLoader();
    const toolBar = useToolbar();
    const history = useHistory();
    const dialog = useDialog();
    const primaryApi = useApi();
    const tableClasses = useTableStyles();
    const secondaryApi = useOrderDeliveryApi();
    const authorization = useAuthorization();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const handleOrderDeliveriesAdd = (data) => {
        dialog.addOrderDeliveries(data);
    };

    const handleOrderDeliveryDisplay = (event, index, row) => {
        dialog.displayOrder(row.order);
    };

    const handleOrderDeliveryDelete = (data) => {
        dialog.confirm(`Voulez-vous supprimer la commande ${data.order.id} ?`, () => {
            secondaryApi.deleTe(state.id, data.id);
        });
    };

    const handleOrderDeliveryDeleteByDepartement = (data) => {
        dialog.confirm(`Voulez-vous supprimer toutes les commandes du département "${data.name}" de cette livraison ?`, () => {
            secondaryApi.deleteByDepartement(state.id, data.id);
        });
    };

    const handleOrderDeliveryDeleteByCity = (data) => {
        dialog.confirm(`Voulez-vous supprimer toutes les commandes de la commune "${data.name}" de cette livraison ?`, () => {
            secondaryApi.deleteByCity(state.id, data.id);
        });
    };

    const handleEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };

    const handleDelete = (data) => {
        dialog.confirm(`Voulez-vous supprimer la livraison ${data.id}`, () => {
            handleDeleteConfirmed(data);
        });
    };

    const handleDeleteConfirmed = (data) => {
        primaryApi.deleTe(data.id);
    };

    const handleDone = (data) => {
        history.push(`${editEndPoint}/${data.id}/status`);
    };

    const handleDownload = (data) => {
        dialog.popup(<Print data={data} />);
    };

    const getItemActions = (data) => {
        const result = [];

        result.push({
            icon: <DeleteOutline />,
            color: 'secondary',
            tooltip: 'Supprimer',
            onClick: (event, data) => {
                handleOrderDeliveryDelete(data);
            },
            hide: state?.status > deliverySatus.scheduled,
        });

        return result;
    };

    const getActions = (data) => ({
        edit: {
            onClick: handleEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: handleDelete,
            hide: !authorization.isInRoles(roles.system, roles.administrator) || (data.status === deliverySatus.done),
        },
        customs: [
            {
                icon: <AddShoppingCartIcon fontSize="small" />,
                label: 'Ajouter des commandes',
                onClick: handleOrderDeliveriesAdd,
                hide: data.status !== deliverySatus.scheduled || !authorization.isInRoles(roles.system, roles.administrator),
            },
            {
                icon: <PrintIcon fontSize="small" />,
                label: 'Imprimer',
                onClick: handleDownload,
                hide: !authorization.isInRoles(roles.system, roles.administrator, roles.deliveryMan),
            },
            {
                icon: <AssignmentTurnedInIcon fontSize="small" />,
                label: 'Livraison effecuée',
                onClick: handleDone,
                hide: data.status !== deliverySatus.scheduled || !authorization.isInRoles(roles.system, roles.administrator, roles.deliveryMan),
            },
        ],
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    const handleGetRowClasses = (key, row) => {
        const result = [];

        switch (row.status) {
            case deliverySatus.missed:
                result.push(tableClasses.warning);
            case deliverySatus.removed:
                result.push(tableClasses.error);
            default:
                switch (row.order.status) {
                    case orderSatus.draft:
                        result.push(tableClasses.warning);
                    default:
                        result.push(undefined);
                }
        }

        return result;
    };

    useEffect(() => {
        loader.show();
        primaryApi.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    return (
        <div className={globalClasses.content}>
            {
                state && (
                    <Page title = {`[${state.id}] Livraison`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Property label="Statut" value={getStatusTypeLabel(state.status)} />
                                        <Property label="Date" value={formatUtcToLocalDate(state.date)} />
                                        <Property label="Livreur" value={<Employee data={state.deliveryMan} />}/>
                                        <Property label="Nombre de commandes" value={state.items.length} />
                                        <Property label="Nombre de colis" value={state.parcelNumber} />
                                        <Property label="Montant" value={formatter.format(state.amount)} color="secondary" />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        style={
                                            {
                                                position: 'relative',
                                            }
                                        }
                                    >
                                        <div style={
                                            {
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                background: `url(${mapImage})`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                filter: 'blur(4px)',
                                                WebkitFilter: 'blur(4px)',
                                                zIndex: -10,
                                            }
                                        }
                                        />
                                        <Locations
                                            title="Départements"
                                            data={
                                                state.itemsCountByDepartment.map((item) => ({
                                                    id: item.key.id,
                                                    name: item.key.name,
                                                    label: `${item.key.name} (${item.value})`,
                                                }))
                                            }
                                            onDelete={state.status === deliverySatus.scheduled ? handleOrderDeliveryDeleteByDepartement : undefined}
                                        />
                                        <Locations
                                            title="Communes"
                                            color="default"
                                            data={
                                                state.itemsCountByCity.map((item) => ({
                                                    id: item.key.id,
                                                    name: item.key.name,
                                                    label: `${item.key.name} (${item.value})`,
                                                }))
                                            }
                                            onDelete={state.status === deliverySatus.scheduled ? handleOrderDeliveryDeleteByCity : undefined}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Table
                                    rowKey="id"
                                    sort={sort}
                                    data={state.items}
                                    actions={getItemActions(state)}
                                    onRowClick={handleOrderDeliveryDisplay}
                                    getRowClasses={handleGetRowClasses}
                                    columns={orderColumns}
                                />
                            </Grid>
                        </Grid>
                    </Page>
                )
            }
        </div>

    );
};

export default Details;
