import React from 'react';

import Typography from '@material-ui/core/Typography';

import { formatDates } from './display';

export default function ModifiedOn (props) {
    const date = formatDates(props);

    if (date) {
        return (
            <Typography variant="caption">
                {date}
            </Typography>
        );
    }

    return (null);
}
