import { formatter } from '../../../services/currency';
import { formatUtcToLocalDate } from '../../../services/date';
import { getStatusTypeLabel as getOrderStatusTypeLabel } from '../../order/services';
import { getStatusTypeLabel } from '../services';


export const sort = {
    field: 'id',
    direction: 'desc',
};

export const sortOrder = {
    field: 'status',
    direction: 'desc',
};

export const orderColumns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Client',
        field: 'thirdParty.name',
        sorting: true,
    },
    {
        label: 'Département',
        field: 'thirdParty.address.city.department.name',
        sorting: true,
    },
    {
        label: 'Ville',
        field: 'thirdParty.address.city.name',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getOrderStatusTypeLabel(row.status),
    },
    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),
    },
];

export const columns = [
    {
        label: 'N°',
        field: 'order.id',
        sorting: true,
    },
    {
        label: 'Client',
        field: 'order.thirdParty.name',
        sorting: true,
    },
    {
        label: 'Département',
        field: 'order.thirdParty.address.city.department.name',
        sorting: true,
    },
    {
        label: 'Ville',
        field: 'order.thirdParty.address.city.name',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'order.date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.order.date),
    },
    {
        label: 'Statut',
        field: 'order.status',
        sorting: true,
        render: (index, column, row) => getOrderStatusTypeLabel(row.order.status),
    },
    {
        label: 'Montant TTC',
        field: 'order.itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.order.itAmount),
    },
    {
        label: 'Livraison',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.status),
    },
];
