import React from 'react';

import { Grid, FormHelperText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const Form = (props) => {
    const {
        values: { id, email, password, confirmedPassword },
        errors,
        touched,
        handleSubmit,
        handleChange,
    } = props;

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid>
                    <FormHelperText error>
                        {errors.general}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={Boolean(id)}
                        required
                        name="email"
                        helperText={touched.email ? errors.email : ''}
                        error={Boolean(errors.email)}
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="password"
                        type="password"
                        helperText={touched.password ? errors.password : ''}
                        error={Boolean(errors.password)}
                        label="Mot de passe"
                        value={password}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="confirmedPassword"
                        type="password"
                        helperText={touched.confirmedPassword ? errors.confirmedPassword : ''}
                        error={Boolean(errors.confirmedPassword)}
                        label="Mot de passe de confirmation"
                        value={confirmedPassword}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
