import React, { useContext } from 'react';
import { default as AddOrderDeliveries } from '../components/delivery/orderDelivery/add';
import { initialValue as orderInitialValue } from '../components/order/constants';
import { Edit as EditOrder, EditNote } from '../components/order/edit';
import { default as EditOrderReady } from '../components/order/ready';
import { default as SummaryOrder } from '../components/order/summary';
import { initialValue as orderItemInitialValue } from '../components/orderItem/constants';
import { default as DetailsOrderItem } from '../components/orderItem/details';
import { default as DetailsPurchaseItem } from '../components/purchasesItem/details';
import { default as DetailsInvoiceItem } from '../components/invoiceItem/details';
import { default as EditOrderItem } from '../components/orderItem/edit';
import { initialValue as organizationInitialValue, stateInitialValue } from '../components/organization/constants';
import { default as EditOrganization } from '../components/organization/edit';
import { initialValue as productInitialValue } from '../components/product/constants';
import { default as EditProduct } from '../components/product/edit';
import { initialValue as userInitialValue } from '../components/user/constants';
import { default as RegisterUser } from '../components/user/register';
import { default as SettingsUser } from '../components/user/settings';
import { GlobalContext } from '../globalState';
import { ClientState } from '../components/organization/print';

export const useDialog = () => {
    const { dispatch } = useContext(GlobalContext);

    const show = (dialog) => {
        dispatch({
            dialog: {
                ...dialog,
                open: true,
            },
        });
    };

    const hide = () => {
        dispatch({
            dialog: null,
        });
    };

    const confirm = (content, onValidate) => {
        dispatch({
            dialog: {
                open: true,
                frozen: true,
                title: 'Confirmation',
                content,
                actions: {
                    cancel: {
                        label: 'Non',
                        onClick: hide,
                    },
                    validate: {
                        label: 'Oui',
                        onClick: () => {
                            hide();
                            onValidate();
                        },
                    },
                },
            },
        });
    };

    const popup = (content) => {
        dispatch({
            dialog: {
                open: true,
                frozen: false,
                content,
                actions: {
                    close: {
                        onClick: hide,
                    },
                },
            },
        });
    };

    const updateProduct = (data) => {
        show({
            title: 'Modification du produit',
            content: <EditProduct values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const downloadState = (data) => {
        show({
            title: 'État de client',
            content: <ClientState values={stateInitialValue} data={data}/>,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const addProduct = () => {
        show({
            title: 'Ajouter un produit',
            content: <EditProduct values={productInitialValue} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const addOrder = (organization) => {
        const forOrganization = Boolean(organization);

        show({
            title: 'Ajouter une commande',
            content: <EditOrder values={
                {
                    ...orderInitialValue,
                    forOrganization,
                    thirdPartyId: organization?.Id,
                    thirdParty: organization,
                }
            }
            />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const updateOrder = (data) => {
        show({
            title: 'Modification de la commande',
            content: <EditOrder values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const updateOrderNote = (data) => {
        show({
            title: 'Ajouter une note',
            content: <EditNote values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const updateOrderStatusReady = (data) => {
        show({
            title: 'Commande prête',
            content: <EditOrderReady values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const displayOrder = (data) => {
        show({
            title: 'Détails de la commande',
            content: <SummaryOrder {...data} />,
            actions: {
                cancel: {
                    label: 'Fermer',
                    onClick: () => {
                        hide();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const addOrderItem = (order) => {
        show({
            title: 'Ajouter un article',
            content: <EditOrderItem values={
                {
                    ...orderItemInitialValue,
                    orderId: order.id,
                    order,
                }
            }
            />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const displayOrderItem = (orderItem) => {
        show({
            title: 'Détails de l\'article',
            content: <DetailsOrderItem {...orderItem} />,
            actions: {
                cancel: {
                    label: 'Fermer',
                    onClick: () => {
                        hide();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const displayInvoiceItem = (invoiceItem) => {
        show({
            title: 'Détails de l\'article',
            content: <DetailsInvoiceItem {...invoiceItem} />,
            actions: {
                cancel: {
                    label: 'Fermer',
                    onClick: () => {
                        hide();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };

    const updateOrganization = (data) => {
        show({
            title: 'Modification de la société',
            content: <EditOrganization values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const addOrganization = () => {
        show({
            title: 'Ajouter une société',
            content: <EditOrganization values={organizationInitialValue} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const updateUser = (data) => {
        show({
            title: 'Modification de l\'utilisateur',
            content: <RegisterUser values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const addUser = () => {
        show({
            title: 'Ajouter un utilisateur',
            content: <RegisterUser values={userInitialValue} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const updateUserSettings = (data) => {
        show({
            title: 'Modification de l\'utilisateur',
            content: <SettingsUser values={
                {
                    id: data.id,
                    role: data.roles && data.roles[0] ? data.roles[0] : '',
                    employee: data.employee,
                }
            }
            />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };

    const addOrderDeliveries = (data) => {
        show({
            title: 'Ajouter des commandes à livrer',
            content: <AddOrderDeliveries data={data} />,
            frozen: true,
            maxWidth: 'md',
        });
    };

    const filterTable = (data) => {
        show({
            title: 'FILTRES',
            // content: ,
            frozen: true,
            maxWidth: 'md',
        });
    };

    const updatePurchaseNote = (data) => {
        show({
            title: 'Ajouter une note',
            content: <EditNote values={data} />,
            actions: {
                cancel: {
                    onClick: () => {
                        hide();
                    },
                },
                validate: {
                    onClick: (form) => {
                        form.submit();
                    },
                },
            },
            frozen: true,
            maxWidth: 'sm',
        });
    };
    const displayPurchasesItem = (purchaseItem) => {
        show({
            title: 'Détails de l\'article',
            content: <DetailsPurchaseItem {...purchaseItem} />,
            actions: {
                cancel: {
                    label: 'Fermer',
                    onClick: () => {
                        hide();
                    },
                },
            },
            frozen: true,
            maxWidth: 'md',
        });
    };


    return {
        show,
        hide,
        confirm,
        popup,
        addOrganization,
        updateOrganization,
        addProduct,
        updateProduct,
        addOrder,
        updateOrder,
        updateOrderStatusReady,
        displayOrder,
        addOrderItem,
        displayOrderItem,
        updateUser,
        addUser,
        updateUserSettings,
        addOrderDeliveries,
        displayInvoiceItem,
        updateOrderNote,
        filterTable,
        downloadState,
        updatePurchaseNote,
        displayPurchasesItem,
    };
};

export default useDialog;
