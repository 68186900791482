/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { formatter } from '../../services/currency';
import { roles } from '../authorization/constants';
import { viewEndPoint as orderViewEndPoint } from '../order/constants';
import { columns as orderColumns, sort as orderSort } from '../order/columns';
import { viewEndPoint as invoiceViewEndPoint } from '../invoice/constants';
import { columns as invoiceColumns, sort as invoiceSort } from '../invoice/columns';
import { viewEndPoint as paymentEndPoint } from '../payment/constants';
import { columns as paymentColumns, sort as paymentSort } from '../payment/columns';
import { columns as purchasesColumns, sort as purchasesSort } from '../purchases/columns';
import { viewEndPoint as purchaseViewEndPoint } from '../purchases/constants';
import BottomAppBar from '../shared/bottomAppBar';
import Property from '../shared/property';
import { Table } from '../shared/grid';
import Tabs from '../shared/tabs';
import useApi from './api';
import { editEndPoint, getOrdersApiEndPoint, getPaymentApiEndPoint, getInvoicesApiEndPoint, getPurchasesApiEndPoint, getPurchasesPaymentApiEndPoint } from './constants';
import Info from './info';
import useStyles from './theme';
import { ToolbarHeader } from './toolbar';
import PrintIcon from '@material-ui/icons/Print';
import Print from './print';

const Details = () => {
    const toolBar = useToolbar();
    const classes = useStyles();
    const dialog = useDialog();
    const authorization = useAuthorization();
    const history = useHistory();
    const api = useApi();

    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };

    const onOrderAdd = (data) => {
        dialog.addOrder(data);
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez vous supprimer la société ${data.name}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const handleDownload = (data) => {
        dialog.popup(<Print data={data} />);
    };

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    const handleOrderRowClick = (event, key, row) => {
        const path = `${orderViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };
    const handleInvoiceRowClick = (event, key, row) => {
        const path = `${invoiceViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const handlePaymentRowClick = (event, key, row) => {
        const path = `${paymentEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const handlePurchasesRowClick = (event, key, row) => {
        const path = `${purchaseViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const handlePurchasesPaymentsRowClick = (event, key, row) => {
        const path = `${purchaseViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const getActions = (data) => ({
        edit: {
            onClick: onEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: onDelete,
            hide: data?.protected || !authorization.isInRoles(roles.system, roles.administrator),
        },
        customs: [
            {
                icon: <AddShoppingCartIcon fontSize="small" />,
                label: 'Ajouter une commande',
                onClick: onOrderAdd,
                hide: !authorization.isInRoles(roles.system, roles.administrator),
            },
            {
                icon: <PrintIcon fontSize="small" />,
                label: 'Imprimer',
                onClick: handleDownload,
                hide: !authorization.isInRoles(roles.system, roles.administrator),
            },
        ],
    });

    useEffect(() => {
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    const GeneralContent = ({ data }) => <Info {...data} />;

    const OrdersContent = ({ data }) => (
        <Table
            rowKey="id"
            sort={orderSort}
            endPoint={getOrdersApiEndPoint(data.id)}
            onRowClick={handleOrderRowClick}
            columns={orderColumns}
        />
    );

    const InvoicesContent = ({ data }) => <Table
        rowKey="id"
        sort={invoiceSort}
        endPoint={getInvoicesApiEndPoint(data.id)}
        onRowClick={handleInvoiceRowClick}
        columns={invoiceColumns}
    />;

    const PaymentsContent = ({ data }) => <Table
        rowKey="id"
        sort={paymentSort}
        endPoint={getPaymentApiEndPoint(data.id)}
        onRowClick={handlePaymentRowClick}
        columns={paymentColumns}
    />;

    const PurchasesContent = ({ data }) => <Table
        rowKey="id"
        sort={purchasesSort}
        endPoint={getPurchasesApiEndPoint(data.id)}
        onRowClick={handlePurchasesRowClick}
        columns={purchasesColumns}
    />;

    const PurchasesPaymentsContent = ({ data }) => <Table
        rowKey="id"
        sort={paymentSort}
        endPoint={getPurchasesPaymentApiEndPoint(data.id)}
        onRowClick={handlePurchasesPaymentsRowClick}
        columns={paymentColumns}
    />;

    const tabs = [
        {
            label: 'Général',
            component: <GeneralContent data={state} />,
        },
        {
            label: 'Commandes',
            component: <OrdersContent data={state} />,
        },
        {
            label: 'Commandes d\'achat',
            component: <PurchasesContent data={state} />,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        {
            label: 'Factures',
            component: <InvoicesContent data={state} />,
        },
        {
            label: 'Règlements',
            component: <PaymentsContent data={state} />,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        {
            label: 'Règlements d\'achat',
            component: <PurchasesPaymentsContent data={state} />,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
    ];

    return (

        <>
            {
                state && (
                    <>
                        <Page title = {`[${state.id}] Société`}>
                            <Tabs tabs={tabs} data={state} />
                            <BottomAppBar>
                                <Box className={classes.flexGrow} />
                                <Box display="flex" spacing={1}>
                                    <Box className={classes.amountProperty}>
                                        <Property valueVariant="subtitle1"
                                            label="Avoir"
                                            value={state.balanceOrders < 0 ? formatter.format(Math.abs(state.balanceOrders)) : formatter.format(0) }
                                        />
                                    </Box>
                                    <Box className={classes.amountProperty}>
                                        <Property
                                            valueVariant="subtitle1"
                                            label="Solde"
                                            valueColor={Math.abs(state.balanceOrders) > 0 ? 'secondary' : 'default'}
                                            value={state.balanceOrders > 0 ? formatter.format(state.balanceOrders) : formatter.format(0) }
                                        />
                                    </Box>
                                    {
                                        authorization.isInRoles(roles.system, roles.administrator) && (
                                            <>
                                                <Box className={classes.amountProperty}>
                                                    <Property valueVariant="subtitle1"
                                                        label="Avoir des achats"
                                                        value={state.balancePurchases < 0 ? formatter.format(Math.abs(state.balancePurchases)) : formatter.format(0) }
                                                    />
                                                </Box>
                                                <Box className={classes.amountProperty}>
                                                    <Property
                                                        valueVariant="subtitle1"
                                                        label="Solde des achats"
                                                        valueColor={Math.abs(state.balanceOrders) > 0 ? 'secondary' : 'default'}
                                                        value={state.balancePurchases > 0 ? formatter.format(state.balancePurchases) : formatter.format(0) }
                                                    />
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                            </BottomAppBar>
                        </Page>

                    </>
                )
            }
        </>

    );
};

export default Details;
