import React, { useContext } from 'react';

import { Formik } from 'formik';

import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useApi from './api';
import Form from './forms/settings';
import validationSchema from './validationSchemas/settings';

const Settings = (props) => {
    const dialog = useDialog();
    const api = useApi();
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.saveSettings(toEditDataContract(data));
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        employeeId: data.employee?.id,
        roles: data.role === '' ? null : [data.role],
    });

    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={props.values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    );
};

export default Settings;
