import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { currency, formatter } from '../../../services/currency';
import { toDateForForms, utcToLocalDateTime } from '../../../services/date';
import { formatPercent } from '../../../services/number';
import { default as ProductLookup } from '../../product/lookup';
import Property from '../../shared/property';
import { getDiscountTypeLabel, getMarginRate, getBasicDiscountRate, getTotalQuantity } from '../../shared/services';

const Form = (props) => {
    const [initialized, setInitialized] = useState(false);

    const {
        values: {
            id,
            createDate,
            code,
            inCatalog,
            catalogMinimumQuantity,
            price, purchaseDiscountType,
            purchaseDiscountRate,
            saleDiscountType,
            saleDiscountRate,
            purchaseDate,
            expirationDate,
            initialQuantity,
            currentQuantity,
            warehousePosition,
            product,
            quantityPackage,
            lastUpdateDate,
        },
        errors,
        touched,
        handleSubmit,
        submitCount,
        handleChange,
        setFieldValue,
    } = props;

    let editedPrice = price;

    if (editedPrice < 0) {
        editedPrice = product ? product.price : 0;
        setFieldValue('price', editedPrice);
    }

    const onInitialQuantityChange = (value) => {
        setFieldValue('initialQuantity', parseInt(value, 10));
        setFieldValue('currentQuantity', getCurrentQuantity(parseInt(value, 10)));
    };

    const getCurrentQuantity = (initial) => getTotalQuantity(purchaseDiscountType ?? 0, purchaseDiscountRate ?? 0, initial ?? 0);

    const purchaseBasicDiscountRate = getBasicDiscountRate(purchaseDiscountRate || 0);
    const saleBasicDiscountRate = getBasicDiscountRate(saleDiscountRate || 0);
    const profitBasicDiscountRate = (purchaseBasicDiscountRate - saleBasicDiscountRate).toFixed(4);
    const marginRate = product ? getMarginRate(product.publicPrice, editedPrice, product.vatRate, product.extraCostHonorary) : 0;
    const totalQuantity = id ? currentQuantity : getCurrentQuantity(initialQuantity);

    useEffect(() => {
        if (!initialized) {
            setFieldValue('purchaseDate', utcToLocalDateTime(purchaseDate));
            setFieldValue('expirationDate', utcToLocalDateTime(expirationDate));
            setFieldValue('lastUpdateDate', lastUpdateDate);
            setInitialized(true);
        }
    }, []);

    return (
        <form ref={props.forwardedRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <ProductLookup
                        disabled={Boolean(createDate)}
                        required
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="product"
                        value={product}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item xs={12} />
                {
                    product && (
                        <>
                            <Grid item xs={12} sm={6} md={3}>
                                <Property label="PU" value={formatter.format(product.price)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Property label="SHP" value={formatter.format(product.extraCostHonorary)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Property label="TVA" value={formatPercent(product.vatRate)} />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} sm={6} md={3}>
                                <Property label="PPA" value={formatter.format(product.publicPrice)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Property label="Marge" value={formatPercent(product.marginRate)} />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="purchaseDate"
                                    type="date"
                                    helperText={submitCount > 0 && errors.purchaseDate ? errors.purchaseDate : ''}
                                    error={submitCount > 0 && Boolean(errors.purchaseDate)}
                                    label="Date d'achat"
                                    defaultValue={toDateForForms(purchaseDate)}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={
                                        {
                                            shrink: true,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="quantityPackage"
                                    type="number"
                                    helperText={submitCount > 0 && errors.quantityPackage ? errors.quantityPackage : ''}
                                    error={submitCount > 0 && Boolean(errors.quantityPackage)}
                                    label="Quantité dans le colis"
                                    value={quantityPackage}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <FormControlLabel
                                    control={
                                        (
                                            <Checkbox
                                                checked={inCatalog}
                                                onChange={handleChange}
                                                name="inCatalog"
                                                color="primary"
                                                size="small"
                                            />
                                        )
                                    }
                                    label="Inclure dans le catalogue"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="catalogMinimumQuantity"
                                    type="number"
                                    helperText={submitCount > 0 && errors.catalogMinimumQuantity ? errors.catalogMinimumQuantity : ''}
                                    error={submitCount > 0 && Boolean(errors.catalogMinimumQuantity)}
                                    label="Quantité minimum pour le catalogue"
                                    value={catalogMinimumQuantity}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name="code"
                                    required
                                    helperText={submitCount > 0 && errors.code ? errors.code : ''}
                                    error={submitCount > 0 && Boolean(errors.code)}
                                    label="N° de lot"
                                    value={code}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="expirationDate"
                                    type="date"
                                    helperText={submitCount > 0 && errors.expirationDate ? errors.expirationDate : ''}
                                    error={submitCount > 0 && Boolean(errors.expirationDate)}
                                    label="Date de péremption"
                                    defaultValue={toDateForForms(expirationDate)}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={
                                        {
                                            shrink: true,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="price"
                                    type="number"
                                    helperText={submitCount > 0 && errors.price ? errors.price : ''}
                                    error={submitCount > 0 && Boolean(errors.price)}
                                    label="PU"
                                    value={editedPrice}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled
                                    required
                                    name="marginRate"
                                    type="number"
                                    label="Marge"
                                    value={marginRate}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <FormControl size="small" error={submitCount > 0 && Boolean(errors.purchaseDiscountType)} required>
                                    <FormLabel component="legend">{'Remise d\'achat'}</FormLabel>
                                    <RadioGroup
                                        name="purchaseDiscountType"
                                        row
                                        value={`${purchaseDiscountType}`}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="0" control={<Radio size="small" />} label={getDiscountTypeLabel(0)} />
                                        <FormControlLabel value="1" control={<Radio size="small" />} label={getDiscountTypeLabel(1)} />
                                    </RadioGroup>
                                    <FormHelperText>{submitCount > 0 && errors.purchaseDiscountType ? errors.purchaseDiscountType : ''}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    name="purchaseDiscountRate"
                                    type="number"
                                    helperText={submitCount > 0 && errors.purchaseDiscountRate ? errors.purchaseDiscountRate : ''}
                                    error={submitCount > 0 && Boolean(errors.purchaseDiscountRate)}
                                    label="Taux UG d'achat"
                                    value={purchaseDiscountRate}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    disabled
                                    name="purchaseBasicDiscountRate"
                                    type="number"
                                    label="Remise financière d'achat"
                                    value={purchaseBasicDiscountRate}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <FormControl component="fieldset" size="small" error={Boolean(errors.saleDiscountType)} required>
                                    <FormLabel component="legend">Remise de vente</FormLabel>
                                    <RadioGroup
                                        name="saleDiscountType"
                                        row
                                        value={`${saleDiscountType}`}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="0" control={<Radio size="small" />} label={getDiscountTypeLabel(0)} />
                                        <FormControlLabel value="1" control={<Radio size="small" />} label={getDiscountTypeLabel(1)} />
                                    </RadioGroup>
                                    <FormHelperText>{submitCount > 0 && errors.saleDiscountType ? errors.saleDiscountType : ''}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    type="number"
                                    name="saleDiscountRate"
                                    helperText={submitCount > 0 && errors.saleDiscountRate ? errors.saleDiscountRate : ''}
                                    error={submitCount > 0 && Boolean(errors.saleDiscountRate)}
                                    label="Taux UG de vente"
                                    value={saleDiscountRate}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    disabled
                                    name="saleBasicDiscountRate"
                                    type="number"
                                    label="Remise financière de vente"
                                    value={saleBasicDiscountRate}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled
                                    name="profitBasicDiscountRate"
                                    error={Boolean(profitBasicDiscountRate < 0)}
                                    label={profitBasicDiscountRate < 0 ? 'Perte' : 'Bénéfice'}
                                    type="number"
                                    value={profitBasicDiscountRate}
                                    variant="outlined"
                                    size="small"
                                    InputProps={
                                        {
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    disabled={Boolean(createDate)}
                                    name="initialQuantity"
                                    type="number"
                                    helperText={submitCount > 0 && errors.initialQuantity ? errors.initialQuantity : ''}
                                    error={submitCount > 0 && Boolean(errors.initialQuantity)}
                                    label="Quantité acheté"
                                    value={initialQuantity}
                                    onChange={
                                        (event) => {
                                            onInitialQuantityChange(event.target.value);
                                        }
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    disabled={!id}
                                    name="currentQuantity"
                                    helperText={submitCount > 0 && errors.initialQuantity ? errors.initialQuantity : ''}
                                    error={submitCount > 0 && Boolean(errors.initialQuantity)}
                                    type="number"
                                    label="Quantité"
                                    value={totalQuantity}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} />
                            <Grid item xs={12} md={4}>
                                <TextField
                                    name="warehousePosition"
                                    helperText={touched.warehousePosition ? errors.warehousePosition : ''}
                                    error={submitCount > 0 && Boolean(errors.warehousePosition)}
                                    label="Rangement"
                                    value={warehousePosition}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="outlined" size="small" required fullWidth>
                                    <InputLabel id="storage-type-select-label">Entrepôt</InputLabel>
                                    <Select
                                        disabled
                                        labelId="storage-type-select-label"
                                        id="storage-type-select"
                                        name="warehouse.id"
                                        label="Entrepôt"
                                        value={0}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>Entrepôt 1</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )
                }
            </Grid>
        </form>
    );
};

export default Form;
