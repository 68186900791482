/* eslint-disable max-len */
import React from 'react';
import { roles } from '../authorization/constants';
import Avatar from '@material-ui/core/Avatar';
import useAuthorization from '../../hooks/authorization';
import EntityLink from '../shared/entityLink';
import Icon from './icon';
import { viewEndPoint } from './constants';
import useStyles from './theme';


export const Property = (props) => {
    const { data } = props;
    const classes = useStyles();
    const authorization = useAuthorization();

    if (!data) {
        return null;
    }

    // const { id, displayName } = data;
    let id = null;

    let displayName = null;

    if (typeof data === 'object') {
        // eslint-disable-next-line prefer-destructuring
        id = data.id;
        // eslint-disable-next-line prefer-destructuring
        displayName = data.displayName;
    } else {
        displayName = data;
    }

    return (
        <EntityLink
            title={displayName}
            label={displayName}
            disabled={!Boolean(id) || Boolean(!(authorization.isInRoles(roles.system) || authorization.isInRoles(roles.administrator) || authorization.isInRoles(roles.supervisor))) }
            href={`${viewEndPoint}/${id}`}
            avatar={
                <Avatar
                    className={classes.avatar}
                >
                    {<Icon />}
                </Avatar>
            }
        />
    );
};

export default Property;
