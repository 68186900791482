import { roles } from './constants';

export const getRoleLabel = (role) => {
    switch (role) {
        case roles.system:
            return 'Système';
        case roles.administrator:
            return 'Administrateur';
        case roles.supervisor:
            return 'Superviseur';
        case roles.operator:
            return 'Opérateur';
        case roles.preparer:
            return 'Préparateur';
        case roles.deliveryMan:
            return 'Livreur';
        default:
            return 'Inconnu';
    }
};

export const getRolesLabels = (data) => {
    if (!data || data.length === 0) {
        return '';
    }

    const result = data.map((item) => getRoleLabel(item));

    return result.join(', ');
};
