import React, { useContext } from 'react';

import { Formik } from 'formik';

import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useApi from './api';
import { apiSettings } from './constants';
import Form from './forms/ready';

const Edit = (props) => {
    const dialog = useDialog();
    const api = useApi(apiSettings);
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.ready(toReadyDataContract(data));
    };

    const toReadyDataContract = (data) => ({
        id: data.id,
        normalParcelNumber: data.normalParcelNumber,
        coldParcelNumber: data.coldParcelNumber,
    });

    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={
                {
                    ...props.values,
                }
            }
            onSubmit={onSubmit}
        />
    );
};

export default Edit;
