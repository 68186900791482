import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import Page from '../shared/page';
import useToolbar from '../../hooks/toolbar';

const useStyles = makeStyles(() => createStyles({
    root: {
        flexGrow: 1,
        marginTop: '5%',
    },
}));

const Error = () => {
    const classes = useStyles();
    const toolBar = useToolbar();
    const { number } = useParams();

    const getContent = () => {
        let message = null;

        switch (number) {
            case '403':
                message = 'Accés interdit';
                break;
            case '204':
            case '404':
                message = 'Ressource introuvable';
                break;
            default:
                message = 'Une erreur s\'est produite';
                break;
        }

        return message;
    };

    useEffect(() => {
        toolBar.hide();
    }, []);

    return (
        <Page title ="Erreur">
            <div className={classes.root}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} align="center">
                        <Typography variant="h4" color="secondary">{number}</Typography>
                        <Typography variant="h5" color="secondary">{getContent()}</Typography>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
};

export default Error;
