import React, { useEffect, useState } from 'react';

import useApi from '../../hooks/api';
import Autocomplete from '../shared/autocomplete';
import { apiEndPoint, apiSettings } from './constants';

export default function Lookup ({
    required,
    label,
    disabled,
    submitCount,
    errors,
    touched,
    name,
    url,
    value,
    onChange,
}) {
    const [state, setState] = useState(null);
    const api = useApi(apiSettings);

    useEffect(() => {
        if (typeof value === 'number') {
            api.get(`${apiEndPoint}/${value}`)
                .then((response) => {
                    setState(response.data);
                });
        }
    }, []);

    return (
        <Autocomplete
            required={required}
            label={label ?? 'Produit'}
            disabled={disabled}
            submitCount={submitCount}
            errors={errors}
            touched={touched}
            name={name}
            url={url ?? apiEndPoint}
            value={state ?? value}
            getOptionLabel={(option) => option.displayName}
            onChange={onChange}
        />
    );
}
