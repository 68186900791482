import React, { useContext } from 'react';

import { Formik } from 'formik';
import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import { toDateFromForms } from '../../services/date';
import useApi from './api';
import { apiSettings, type as OrdersType } from './constants';
import { Form, NoteForm } from './forms/edit';
import validationSchema from './validationSchema';

export const Edit = (props) => {
    const dialog = useDialog();
    const api = useApi(apiSettings);
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.save(toEditDataContract(data));
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        status: data.status,
        note: data.note,
        date: toDateFromForms(data.date),
        normalParcelNumber: data.normalParcelNumber,
        coldParcelNumber: data.coldParcelNumber,
        thirdPartyId: data.thirdParty?.id,
        sellerId: data.seller?.id,
        type: OrdersType.sale,
        defaultPaymentMode: data.defaultPaymentMode,
    });

    return (

        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={
                {
                    ...props.values,
                }
            }
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />

    );
};

export const EditNote = (props) => {
    const dialog = useDialog();
    const api = useApi(apiSettings);
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.updateNote(data, data.note);
    };


    return (

        <Formik
            render={(props) => <NoteForm setRef={state.form.setRef} {...props} />}
            initialValues={
                {
                    ...props.values,
                }
            }
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />

    );
};

