import React from 'react';

import { getRolesLabels } from '../authorization/services';
import { EmailStatus, Status } from './status';

export const sort = {
    field: 'id',
    direction: 'asc',
};
export const columns = [

    {
        label: 'Email',
        field: 'email',
        sorting: true,
    },
    {
        label: 'Statut du compte',
        render: (index, column, row) => <Status data={row} fontSize="small" />,
    },
    {
        label: 'Roles',
        sorting: true,
        render: (index, column, row) => getRolesLabels(row.roles),
    },
    {
        label: 'Employé',
        field: 'employee.name',
        sorting: true,
    },
    {
        label: 'Statut de l\'email',
        field: 'employee.name',
        sorting: true,
        render: (index, column, row) => <EmailStatus data={row} fontSize="small" />,
    },
];


