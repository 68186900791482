import React from 'react';

import { AppBar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import { default as MTabs } from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

const a11yProps = (index) => ({
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {
                value === index && (
                    <Box p={2}>
                        <Typography>{children}</Typography>
                    </Box>
                )
            }
        </div>
    );
};

const Tabs = ({ tabs }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar
                position="static"
                color="default"
                style={
                    {
                        marginTop: -5,
                        boxShadow: 'none',
                    }
                }
            >
                <MTabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {
                        tabs.filter((item) => !item.hide).map((item, index) => <Tab
                            key={item.label}
                            label={item.label}
                            icon={item.icon}
                            {...a11yProps(index)}
                        />)
                    }
                </MTabs>
            </AppBar>
            {
                tabs.map((item, index) => (
                    <TabPanel
                        key={index}
                        value={value}
                        index={index}
                    >
                        {item.component}
                    </TabPanel>
                ))
            }
        </>
    );
};

export default Tabs;
