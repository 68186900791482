import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { default as RLoader } from 'react-loaders';
import 'loaders.css/loaders.css';

const useStyles = makeStyles((theme) => ({
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        '& > *': {
            background: theme.palette.secondary.main,
            border: 'none !important',
        },
    },
    overlay: {
        zIndex: 20000,
        background: 'rgba(255, 255, 255, 0.4)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
}));

const Loader = (props) => {
    const classes = useStyles();

    if (props.loading) {
        return (
            <div className={classes.overlay} tabIndex={0}>
                <RLoader active type="ball-triangle-path" innerClassName={classes.loader} />
            </div>
        );
    }

    return (null);
};

export default Loader;
