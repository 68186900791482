import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { getDiscountTypeLabel } from '../shared/services';
import { getDiscountRate, getQuantity } from './services';
import { getStatusTypeLabel } from '../order/services';
import { formatPercent } from '../../services/number';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Produit',
        field: 'lot.product.displayName',
        sorting: true,
    },
    {
        label: 'N° de lot',
        field: 'lot.code',
        sorting: true,

    },
    {
        label: 'Date de péremp.',
        field: 'lot.expirationDate',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.lot.expirationDate),
    },

    {
        label: 'PU',
        field: 'price',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.price),
    },
    {
        label: 'Remise',
        field: 'discountType',
        sorting: true,
        render: (index, column, row) => getDiscountTypeLabel(row.discountType),

    },
    {
        label: '% Remise',
        field: 'discountRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getDiscountRate(row),
    },
    {
        label: 'Quantité',
        field: 'quantity',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getQuantity(row),
    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'TVA',
        field: 'vatRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.vatAmount),
    },

    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),

    },
];

export const lotColumns = [
    {
        label: 'N°',
        field: 'order.id',
        filtering: false,
        sorting: true,
    },
    {
        label: 'Client',
        field: 'order.thirdParty.name',
        sorting: true,
    },
    {
        label: 'Vendeur',
        field: 'order.seller.name',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'order.date',
        type: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.order.date),
    },
    {
        label: 'Statut',
        field: 'order.status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.order.status),
    },
    {
        label: 'Remise',
        field: 'discountType',
        sorting: true,
        render: (index, column, row) => getDiscountTypeLabel(row.discountType),
    },
    {
        label: '% Remise',
        field: 'discountRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getDiscountRate(row),
    },
    {
        label: 'Quantité',
        field: 'quantity',
        align: 'right',
        sorting: true,
    },
    {
        label: 'Quantité totale',
        field: 'totalQuantity',
        align: 'right',
        sorting: true,
    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'TVA',
        field: 'vatRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatPercent(row.vatRate),
    },
    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),
    },
];
