import { object, string } from 'yup';

const ValidationSchema = object({
    line1: string('Saisissez le numéro et la rue')
        .nullable()
        .required('Le numéro et la rue sont requis'),
    city: object().nullable()
        .required('La ville est requise'),
});

export default ValidationSchema;
