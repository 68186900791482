export const displayName = (data) => {
    if (!data) {
        return '';
    }

    if (!data.product) {
        return data.code;
    }

    return `${data.code} - ${data.product.displayName}`;
};
