import 'moment/locale/fr';
import moment from 'moment-timezone';

import { formatter } from '../../../services/currency';
import { timeZone } from '../../../services/date';
import { colors } from './constants';

export const getDataFromDate = (data, date) => {
    if (!data) {
        return null;
    }

    return data.filter((item) => moment(item.date) >= date);
};

export const getKey = (value, byDate, bySeler) => {
    const result = {
        key: null,
        date: null,
    };

    switch (byDate) {
        case 'month':
            result.key = moment.utc(moment.utc(value.date).tz(timeZone)
                .startOf('month')
                .utc()
                .format());
            result.date = result.key.clone();
            break;
        default:
            result.key = moment.utc(value.date);
            result.date = result.key.clone();
            break;
    }

    if (bySeler) {
        result.key = `${result.key.format()}.${value.sellerId}`;
        result.sellerId = value.sellerId;
        result.name = value.name;
    }

    return result;
};

export const getSellersGroupedData = (data, byDate, bySeler) => {
    if (!data) {
        return null;
    }

    let group = [];

    data.slice().reduce((res, value) => {
        const key = getKey(value, byDate, bySeler);

        if (!res[key.key]) {
            res[key.key] = {
                date: key.date,
                count: 0,
                turnover: 0,
                positive: 0,
                profit: 0,
            };

            if (typeof key.sellerId !== 'undefined') {
                res[key.key].sellerId = key.sellerId;
                res[key.key].name = key.name;
            }

            group.push(res[key.key]);
        }

        res[key.key].count += value.count;
        res[key.key].turnover += value.turnover;
        res[key.key].positive += value.positive;
        res[key.key].profit += value.profit;

        return res;
    }, {
    });

    group = group.slice().sort((a, b) => a.date - b.date);

    return group;
};

export const getCount = (data) => getSum(data, 'count');

export const getEmployeeCount = (data, employee) => getEmployeeSum(data, employee, 'count');

export const getTurnover = (data) => getSum(data, 'turnover');

export const getEmployeeTurnover = (data, employee) => getEmployeeSum(data, employee, 'turnover');

export const getPositive = (data) => getSum(data, 'positive');

export const getEmployeePositive = (data, employee) => getEmployeeSum(data, employee, 'positive');

export const getProfit = (data) => getSum(data, 'profit');

export const getEmployeeProfit = (data, employee) => getEmployeeSum(data, employee, 'profit');

export const getSum = (data, type) => {
    if (!data) {
        return null;
    }

    let result = 0;

    data.forEach((item) => {
        result += item[type];
    });

    return result;
};

export const getEmployeeSum = (data, employee, type) => {
    if (!data) {
        return null;
    }

    let result = 0;

    data.forEach((item) => {
        if (item.sellerId === employee?.id) {
            result += item[type];
        }
    });

    return result;
};

export const format = (t, d) => `${d.datasets[t.datasetIndex].label} : ${formatter.format(t.yLabel)}`;

export const getRandomColor = (index) => {
    let i = 0;

    if (typeof index === 'number') {
        i = index;
    }

    const values = Object.values(colors.palette);

    if (i >= values.length) {
        i %= values.length;
    }

    return values[i];
};
