import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { default as MTableCell } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { default as MTableRow } from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Formik, FieldArray, getIn } from 'formik';
import { array, object } from 'yup';

import { currency, formatter } from '../../services/currency';
import { formatUtcToLocalDate, toDateForForms } from '../../services/date';

const TableCell = withStyles((theme) => ({
    head: {
        borderStyle: 'none',
        color: theme.palette.primary.main,
        background: 'transparent',
        textTransform: 'uppercase',
    },
    body: {
        borderStyle: 'none',
    },
}))(MTableCell);

const TableRow = withStyles(() => ({
    root: {
        backgroundColor: 'transparent',
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, .02)',
        },
        '&:hover': {
            // backgroundColor: "rgba(0, 0, 0, 0.06) !important",
        },
    },
}))(MTableRow);

const schema = object().shape({
    rows: array()
        .of(
            object().shape({
            }),
        ),
});

const Wizard = ({ forwardedRef, columns, data, onSubmit }) => (
    <Formik
        initialValues={
            {
                rows: data,
            }
        }
        validationSchema={schema}
        onSubmit={onSubmit}
        render={
            () => (
                <FieldArray
                    name="rows"
                    render={(props) => <Form forwardedRef={forwardedRef} columns={columns} {...props} />}
                />
            )
        }
    />
);

const Form = ({ forwardedRef, columns, form }) => {
    const byString = (o, s) => {
        if (!s) {
            return;
        }

        s = s.replace(/\[(\w+)\]/g, '.$1');
        s = s.replace(/^\./, '');
        const a = s.split('.');

        for (let i = 0, n = a.length; i < n; ++i) {
            const x = a[i];

            if (o && x in o) {
                o = o[x];
            } else {
                return;
            }
        }

        return o;
    };

    const renderHeader = () => (
        <TableHead>
            <MTableRow>
                {columns.map((column) => <TableCell key={column.title} color="primary">{column.title}</TableCell>)}
                {' '}
            </MTableRow>
        </TableHead>
    );

    const renderBody = () => <TableBody>{form.values.rows.map((row, index) => renderRow(row, index))}</TableBody>;

    const renderRow = (row, index) => <TableRow key={index}>{columns.map((column) => renderColumn(row, column, index))}</TableRow>;

    const renderColumn = (row, column, index) => (
        <TableCell>{getEditable(row, column) ? renderControl(row, column, index) : getFormatedFieldValue(row, column)}</TableCell>
    );

    const renderControl = (row, column, index) => {
        const key = `rows[${index}].${column.field}`;
        const name = `rows.${index}.${column.field}`;
        const value = getFormatedFieldValue(row, column);
        const error = getIn(form.errors, key);
        const hasError = form.submitCount > 0 && error;

        if (column.bind) {
            switch (column.type) {
                default:
                    return (
                        <FormControl error={hasError} variant="outlined" size="small" required={getRequired(row, column)} fullWidth>
                            <InputLabel id={`${column.title}-select-label`}>{column.title}</InputLabel>
                            <Select
                                disabled={getDisabled(row, column)}
                                labelId={`${column.title}-select-label`}
                                id={`${column.title}-select`}
                                label={column.title}
                                name={name}
                                value={value}
                                onChange={form.handleChange}
                            >
                                {column.bind.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                            </Select>
                            <FormHelperText>{hasError ? error : ''}</FormHelperText>
                        </FormControl>
                    );
            }
        }

        switch (column.type) {
            case 'currency':
                return (
                    <TextField
                        required={getRequired(row, column)}
                        variant="outlined"
                        size="small"
                        disabled={getDisabled(row, column)}
                        error={hasError}
                        helperText={hasError ? error : ''}
                        label={column.title}
                        type="number"
                        name={name}
                        value={getFieldValue(row, column)}
                        onChange={form.handleChange}
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                );
            case 'date':
                return (
                    <TextField
                        required={getRequired(row, column)}
                        error={hasError}
                        helperText={hasError ? error : ''}
                        variant="outlined"
                        size="small"
                        disabled={getDisabled(row, column)}
                        label={column.title}
                        type="date"
                        name={name}
                        defaultValue={toDateForForms(getFieldValue(row, column))}
                        onChange={form.handleChange}
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                );
            default:
                return (
                    <TextField
                        required={getRequired(row, column)}
                        error={hasError}
                        helperText={hasError ? error : ''}
                        variant="outlined"
                        size="small"
                        disabled={getDisabled(row, column)}
                        label={column.title}
                        type={column.type ?? 'text'}
                        name={name}
                        value={value}
                        onChange={form.handleChange}
                        fullWidth
                    />
                );
        }
    };

    const getEditable = (row, column) => typeof column.editable === 'function' ? column.editable(row) : column.editable !== false;

    const getDisabled = (row, column) => typeof column.diabled === 'function' ? column.disabled(row) : column.disabled;

    const getRequired = (row, column) => typeof column.required === 'function' ? column.required(row) : column.required;

    const getFieldValue = (row, column, lookup = true) => {
        let value = typeof row[column.field] !== 'undefined' ? row[column.field] : byString(row, column.field);

        if (column.lookup && lookup) {
            value = column.lookup[value];
        }

        return value;
    };

    const getFormatedFieldValue = (row, column, lookup = true) => {
        const value = getFieldValue(row, column, lookup);

        switch (column.type) {
            case 'currency':
                return formatter.format(value);
            case 'date':
                return formatUtcToLocalDate(value);
            default:
                return value;
        }
    };

    return (
        <form ref={forwardedRef} onSubmit={form.handleSubmit}>
            <Table size="small">
                {renderHeader()}
                {renderBody()}
            </Table>
        </form>
    );
};

export default Wizard;
