import React from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import useAuthorization from '../../../hooks/authorization';
import { roles } from '../../authorization/constants';
import { default as InvoiceStatus } from '../../invoice/status';
import AddressFormContent from '../../address/formContent';
import { default as EmployeeLookup } from '../../employee/lookup';
import { toDateForForms } from '../../../services/date';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getFileTypeLabel } from '../../shared/constants';
import FormLabel from '@material-ui/core/FormLabel';

const Form = (props) => {
    const authorization = useAuthorization();

    const {
        forwardedRef,
        values: { id, name, employee, email, phone, fax, crn, tin, ta, defaultInvoiceStatus, address },
        errors,
        touched,
        handleSubmit,
        handleChange,
        submitCount,
        setFieldValue,
    } = props;

    return (
        <form ref={forwardedRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="name"
                        helperText={submitCount > 0 && errors.name ? errors.name : ''}
                        error={submitCount > 0 && Boolean(errors.name)}
                        label="Nom"
                        value={name}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                {
                    authorization.isInRoles(roles.system, roles.administrator) && id &&
                    (
                        <Grid item xs={12} md={4}>
                            <EmployeeLookup
                                submitCount={submitCount}
                                errors={errors}
                                touched={touched}
                                label="Employé"
                                name="employee"
                                value={employee}
                                onChange={setFieldValue}
                            />
                        </Grid>
                    )
                }
                <Grid item xs={12} />
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="crn"
                        helperText={submitCount > 0 && errors.crn ? errors.crn : ''}
                        error={submitCount > 0 && Boolean(errors.crn)}
                        label="NRC"
                        value={crn}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="tin"
                        helperText={submitCount > 0 && errors.tin ? errors.tin : ''}
                        error={submitCount > 0 && Boolean(errors.tin)}
                        label="NIF"
                        value={tin}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="ta"
                        helperText={submitCount > 0 && errors.ta ? errors.ta : ''}
                        error={submitCount > 0 && Boolean(errors.ta)}
                        label="AI"
                        value={ta}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={4}>
                    <InvoiceStatus
                        name="defaultInvoiceStatus"
                        label="Statut de la facture"
                        value={defaultInvoiceStatus}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={4}>
                    <TextField
                        name="email"
                        helperText={submitCount > 0 && errors.email ? errors.email : ''}
                        error={submitCount > 0 && Boolean(errors.email)}
                        label="Email"
                        value={email}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="phone"
                        helperText={submitCount > 0 && errors.phone ? errors.phone : ''}
                        error={submitCount > 0 && Boolean(errors.phone)}
                        label="Téléphone"
                        value={phone}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="fax"
                        helperText={submitCount > 0 && errors.fax ? errors.fax : ''}
                        error={submitCount > 0 && Boolean(errors.fax)}
                        label="Fax"
                        value={fax}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <AddressFormContent {...{
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    submitCount,
                    values: address,
                }}
                />
            </Grid>
        </form>
    );
};

export default Form;

export const State = (props) => {
    const {
        values: { startDate, endDate, fileType },
        errors,
        touched,
        handleSubmit,
        handleChange,
    } = props;


    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        name="startDate"
                        type="date"
                        helperText={touched.startDate ? errors.startDate : ''}
                        error={Boolean(errors.startDate)}
                        label="Date de début"
                        defaultValue={toDateForForms(startDate)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        required
                        name="endDate"
                        type="date"
                        helperText={touched.endDate ? errors.endDate : ''}
                        error={Boolean(errors.endDate)}
                        label="Date de fin"
                        defaultValue={toDateForForms(endDate)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12} >
                    <FormLabel component="legend">Fichier</FormLabel>
                    <RadioGroup row
                        id="fileType"
                        name="fileType"
                        defaultValue={`${fileType}`}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Csv" control={<Radio size="small"/>} label={getFileTypeLabel(0)} disabled/>
                        <FormControlLabel value="Pdf" control={<Radio size="small"/>} label={getFileTypeLabel(1)} />
                    </RadioGroup>
                </Grid>

            </Grid>
        </form>
    );
};
