import { useContext } from 'react';

import { GlobalContext } from '../globalState';

export const useLoader = () => {
    const { dispatch } = useContext(GlobalContext);

    const show = () => {
        dispatch({
            loading: true,
        });
    };

    const hide = () => {
        dispatch({
            loading: false,
        });
    };

    return {
        show,
        hide,
    };
};

export default useLoader;
