import React from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

import AddressFormContent from '../address/formContent';
import { getCivilityLabel } from './services';

const FormContent = (props) => {
    const {
        values: { civility, firstName, lastName, email, phone, fax, address },
        errors,
        touched,
        handleChange,
        setFieldValue,
        submitCount,
    } = props;

    return (
        <>
            <Grid item xs={12} md={4}>
                <FormControl required size="small" error={submitCount > 0 && Boolean(errors.civility)}>
                    <FormLabel component="legend" size="small">Civilité</FormLabel>
                    <RadioGroup
                        name="civility"
                        row
                        value={`${civility}`}
                        error={submitCount > 0 && Boolean(errors.civility)}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="1" control={<Radio size="small" />} label={getCivilityLabel(1)} />
                        <FormControlLabel value="2" control={<Radio size="small" />} label={getCivilityLabel(2)} />
                    </RadioGroup>
                    <FormHelperText>{touched.civility ? errors.civility : ''}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    name="firstName"
                    helperText={submitCount > 0 && errors.firstName ? errors.firstName : ''}
                    error={submitCount > 0 && Boolean(errors.firstName)}
                    label="Prénom"
                    value={firstName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    name="lastName"
                    helperText={submitCount > 0 && errors.lastName ? errors.lastName : ''}
                    error={submitCount > 0 && Boolean(errors.lastName)}
                    label="Nom"
                    value={lastName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    name="email"
                    helperText={submitCount > 0 && errors.email ? errors.email : ''}
                    error={submitCount > 0 && Boolean(errors.email)}
                    label="Email"
                    value={email}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    name="phone"
                    helperText={submitCount > 0 && errors.phone ? errors.phone : ''}
                    error={submitCount > 0 && Boolean(errors.phone)}
                    label="Téléphone"
                    value={phone}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    name="fax"
                    helperText={submitCount > 0 && errors.fax ? errors.fax : ''}
                    error={submitCount > 0 && Boolean(errors.fax)}
                    label="Fax"
                    value={fax}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} />
            <AddressFormContent {...{
                errors,
                touched,
                handleChange,
                setFieldValue,
                submitCount,
                values: address,
            }}
            />
        </>
    );
};

export default FormContent;
