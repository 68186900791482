
export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Prénom',
        field: 'firstName',
        sorting: true,
    },
    {
        label: 'Nom',
        field: 'lastName',
        sorting: true,
    },
    {
        label: 'Téléphone',
        field: 'phone',
        sorting: false,
    },
    {
        label: 'Email',
        field: 'email',
        sorting: false,
    },
    {
        label: 'Utilisateur',
        field: 'userId',
        sorting: false,
    },
    {
        label: 'Ville',
        field: 'address.city.name',
        sorting: true,
    },
];

