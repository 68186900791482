import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import { default as MDialog } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';

const styles = (theme) => createStyles({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {
                onClose
                    ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )
                    : null
            }
        </MDialogTitle>
    );
});

export default function Dialog () {
    const { state: { dialog, form } } = useContext(GlobalContext);
    const dialogHook = useDialog();

    if (dialog && dialog.open) {
        const { title, content, ignoreDialogContent, maxWidth, actions, open, frozen } = dialog;
        const onCancel = frozen
            ? undefined
            : (actions.cancel && typeof actions.cancel.onClick === 'function' ? actions.cancel.onClick : () => dialogHook.hide()
            );

        const onClose = frozen
            ?
            undefined
            : (
                actions.close && typeof actions.close.onClick === 'function' ? actions.close.onClick : () => dialogHook.hide()
            );

        return (
            <MDialog
                fullWidth
                open={open}
                onClose={onCancel}
                maxWidth={maxWidth}
            >
                <DialogTitle onClose={onClose}>{title}</DialogTitle>
                {ignoreDialogContent ? content : <DialogContent>{content}</DialogContent>}
                {
                    actions && (
                        <DialogActions>
                            {
                                actions.cancel &&
                            typeof actions.cancel.onClick === 'function' &&
                            (
                                <Button
                                    variant="outlined"
                                    onClick={actions.cancel.onClick}
                                    color="default"
                                >
                                    {actions.cancel.label ? actions.cancel.label : 'Annuler'}
                                </Button>
                            )
                            }
                            {
                                actions.validate &&
                            typeof actions.validate.onClick === 'function' &&
                            (
                                <Button
                                    variant="outlined"
                                    onClick={
                                        () => {
                                            actions.validate.onClick(form);
                                        }
                                    }
                                    color="primary"
                                >
                                    {actions.validate.label ? actions.validate.label : 'Valider'}
                                </Button>
                            )
                            }
                        </DialogActions>
                    )
                }
            </MDialog>
        );
    }

    return (null);
}
