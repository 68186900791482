import React, { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { initialValue, endPoint, viewEndPoint } from './constants';
import Form from './forms/edit';
import { ToolbarHeader } from './toolbar';
import { validationSchema } from './validationSchema';
import Page from '../shared/page';

const Edit = () => {
    const globalClasses = useGlobalStyles();
    const dialog = useDialog();
    const toolBar = useToolbar();
    const { id } = useParams();
    const [state, setState] = useState(null);
    const history = useHistory();
    const api = useApi();
    const formRef = useRef(null);

    const onSave = () => {
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
        }));
    };

    const onCancel = (data) => {
        if (data?.id) {
            history.push(`${viewEndPoint}/${data?.id}`);
        } else {
            history.push(endPoint);
        }
    };

    const onSubmit = (data) => {
        dialog.hide();
        api.save(toEditDataContract(data)).then((d) => {
            if (!data?.id) {
                history.push(`${viewEndPoint}/${d.id}`);
            }
        });
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        name: data.name,
        employeeId: data.employee?.id,
        email: data.email !== '' ? data.email : null,
        phone: data.phone,
        fax: data.fax,
        crn: data.crn,
        tin: data.tin,
        ta: data.ta,
        defaultInvoiceStatus: data.defaultInvoiceStatus,
        type: data.type,
        address: {
            id: data.address.id,
            line1: data.address.line1,
            line2: data.address.line2,
            cityId: data.address.city.id,
        },
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: {
                cancel: {
                    onClick: onCancel,
                },
                save: {
                    onClick: onSave,
                },
            },
        });
    };

    useEffect(() => {
        if (id) {
            api.get(id)
                .then((data) => {
                    setState(data);
                });
        } else {
            setState(initialValue);
        }
    }, []);

    useEffect(() => {
        handleToolbarDisplay(state);
    }, [state]);

    return (

        <div className={globalClasses.content}>
            {
                state && (
                    <Page title={state?.id ? `[${state.id}] Société:modification ` : 'Société:création'}>
                        <Formik
                            enableReinitialize
                            render={(props) => <Form forwardedRef={formRef} {...props} />}
                            initialValues={state}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        />
                    </Page>)
            }
        </div>

    );
};

export default Edit;
