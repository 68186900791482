/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import useAuthorization from '../../../hooks/authorization';
import { toDateForForms, utcToLocalDateTime } from '../../../services/date';
import { roles } from '../../authorization/constants';
import { default as OrganizationLookup } from '../../organization/lookup';
import { currency, formatter } from '../../../services/currency';
import Property from '../../shared/property';
import Box from '@material-ui/core/Box';
import BottomAppBar from '../../shared/bottomAppBar';
import { default as useGlobalStyles } from '../../shared/theme';
import useStyles from '../theme';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getDiscountTypeLabel, getMarginRate, getBasicDiscountRate, getTotalQuantity } from '../../shared/services';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import { default as ProductLookup } from '../../product/lookup';
import { getLotEtAmount, getLotDiscountAmount, getLotVatAmount, getLotAmount, getLotItAmount } from '../services';

export const Form = (props) => {
    const {
        setRef,
        handleSubmit,
        setFieldValue,
        submitCount,
        errors,
        touched,
        handleChange,
        values: { thirdParty, date, deliveryDate, lots },
    } = props;

    const authorization = useAuthorization();
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const [state, setState] = useState({
        thirdParty,
        date,
        deliveryDate,
        lots,
    });

    const lot = {
        product: null,
        price: 0,
        code: '',
        marginRate: 0,
        expirationDate: new Date(),
        purchaseDiscountType: 0,
        purchaseDiscountRate: 0,
        purchaseBasicDiscountRate: 0,
        saleDiscountType: 0,
        saleDiscountRate: 0,
        saleBasicDiscountRate: 0,
        profitBasicDiscountRate: 0,
        warehousePosition: '',
        initialQuantity: 0,
        currentQuantity: 0,
        etAmount: 0,
        vatAmount: 0,
        itAmount: 0,
    };

    const createLot = () => ({
        product: null,
        price: 0,
        code: '',
        marginRate: 0,
        expirationDate: new Date(),
        purchaseDiscountType: 0,
        purchaseDiscountRate: 0,
        purchaseBasicDiscountRate: 0,
        saleDiscountType: 0,
        saleDiscountRate: 0,
        saleBasicDiscountRate: 0,
        profitBasicDiscountRate: 0,
        warehousePosition: '',
        initialQuantity: 0,
        currentQuantity: 0,
        etAmount: 0,
        vatAmount: 0,
        itAmount: 0,
        discountAmount: 0,
        amount: 0,
    });


    const getCurrentQuantity = (initial, index) => getTotalQuantity(lots[index].purchaseDiscountType ?? 0, lots[index].purchaseDiscountRate ?? 0, initial ?? 0);
    const purchaseBasicDiscountRate = (index) => getBasicDiscountRate(lots[index].purchaseDiscountRate || 0);
    const saleBasicDiscountRate = (index) => getBasicDiscountRate(lots[index].saleDiscountRate || 0);
    const profitBasicDiscountRate = (index) => (purchaseBasicDiscountRate(index) - saleBasicDiscountRate(index)).toFixed(4);
    const marginRate = (index) => lots[index].product ? getMarginRate(lots[index].product.publicPrice, lots[index].price, lots[index].product.vatRate, lots[index].product.extraCostHonorary) : 0;
    const totalQuantity = (index) => getCurrentQuantity(lots[index].initialQuantity, index);

    const RoundedButton = (props) => {
        const { title, color, icon, onClick, className, float } = props;

        return (
            <Tooltip title={title} >
                <IconButton
                    style={
                        {
                            border: '1px solid',
                            padding: '8px',
                            float,
                        }
                    }
                    color={color ?? 'default'}
                    onClick={onClick}
                    className={className}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        );
    };

    const addLot = () => {
        lots.push(createLot());
        setFieldValue('lots', lots);
    };

    const removeLot = (index) => {
        lots.splice(index, 1);
        setState({
            ...lots,
        });
    };

    const getEtAmount = () => {
        let sum = 0;

        lots.map((item, index) => {
            item.etAmount = getLotEtAmount(parseInt(lots[index].purchaseDiscountType, 10), getLotAmount(lots[index].initialQuantity, lots[index].price), lots[index].purchaseDiscountRate);
            sum += item.etAmount;
        });

        return sum;
    };
    const getVatAmount = () => {
        let sum = 0;

        lots.map((item) => {
            if (item.product) {
                item.vatAmount = getLotVatAmount(item.etAmount, item.product?.vatRate);
            }
            sum += item.vatAmount;
        });

        return sum;
    };
    const getItAmount = () => {
        let sum = 0;

        lots.map((item, index) => {
            item.itAmount = getLotItAmount(item.etAmount, item.vatAmount);
            sum += item.itAmount;
        });

        return sum;
    };

    const getDiscountAmount = () => {
        let sum = 0;

        lots.map((item, index) => {
            item.discountAmount = getLotDiscountAmount(item.initialQuantity * item.price, item.etAmount);
            sum += item.discountAmount;
        });

        return sum;
    };

    const getAmount = () => {
        let sum = 0;

        lots.map((item, index) => {
            item.Amount = getLotAmount(item.initialQuantity, item.price);
            sum += item.Amount;
        });

        return sum;
    };

    const getKey = (row, name, index) => `lots[${index}].${name}`;

    const getName = (row, name, index) => `lots.${index}.${name}`;

    const getError = (errors, index, field) => errors && errors.lots && errors.lots[index] && errors.lots[index][field];

    const renderLots = (rows) => rows.map((row, index) => (
        <Grid item xs={12} md={12}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                    <ProductLookup
                        required
                        name={getName(row, 'product', index)}
                        helperText={getError(errors, index, 'product')}
                        errors={Boolean(getError(errors, index, 'product'))}
                        onChange={(_, value) => setFieldValue(getName(row, 'product', index), value)}
                        value={lots[index].product}
                    />
                    <FormHelperText error>{touched.date ? getError(errors, index, 'product') : ''}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={6}/>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        required
                        name={getName(row, 'code', index)}
                        helperText={touched.date ? getError(errors, index, 'code') : ''}
                        error={Boolean(getError(errors, index, 'code'))}
                        label="N° de lot"
                        defaultValue={lot.code}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                        value={lots[index].code}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        required
                        name={getName(row, 'expirationDate', index)}
                        type="date"
                        helperText={touched.date ? getError(errors, index, 'expirationDate') : ''}
                        error={Boolean(getError(errors, index, 'expirationDate'))}
                        label="Date de péremption"
                        defaultValue={toDateForForms(lot.expirationDate)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                        value={toDateForForms(lots[index].expirationDate)}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}/>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        name={getName(row, 'warehousePosition', index)}
                        helperText={touched.date ? getError(errors, index, 'warehousePosition') : ''}
                        error={Boolean(getError(errors, index, 'warehousePosition'))}
                        label="Rangement"
                        defaultValue={lot.warehousePosition}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={lots[index].warehousePosition}
                    />
                </Grid>
                <Grid item xs={12} sm={12}/>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        required
                        name={getName(row, 'price', index)}
                        type="number"
                        helperText={touched.date ? getError(errors, index, 'price') : ''}
                        error={Boolean(getError(errors, index, 'price'))}
                        label="PU"
                        defaultValue={lot.price}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                        value={lots[index].price}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        disabled
                        required
                        name={getName(row, 'marginRate', index)}
                        helperText={touched.date ? getError(errors, index, 'marginRate') : ''}
                        error={Boolean(getError(errors, index, 'marginRate'))}
                        type="number"
                        label="Marge"
                        defaultValue={lot.marginRate}
                        value={marginRate(index)}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} sm={12}/>
                <Grid item xs={12} sm={4} md={2}>
                    <FormControl size="small" error={ Boolean(getError(errors, index, 'purchaseDiscountType'))} required>
                        <FormLabel component="legend">{'Remise d\'achat'}</FormLabel>
                        <RadioGroup
                            name={getName(row, 'purchaseDiscountType', index)}
                            defaultValue={`${lot.purchaseDiscountType}`}
                            value={`${lots[index].purchaseDiscountType}`}
                            row
                            onChange={handleChange}
                        >
                            <FormControlLabel value="0" control={<Radio size="small" />} label={getDiscountTypeLabel(0)} />
                            <FormControlLabel value="1" control={<Radio size="small" />} label={getDiscountTypeLabel(1)} />
                        </RadioGroup>
                        <FormHelperText>{touched.date ? getError(errors, index, 'purchaseDiscountType') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        required
                        name={getName(row, 'purchaseDiscountRate', index)}
                        helperText={touched.date ? getError(errors, index, 'purchaseDiscountRate') : ''}
                        error={Boolean(getError(errors, index, 'purchaseDiscountRate'))}
                        label="Taux UG d'achat"
                        type="number"
                        defaultValue={lot.purchaseDiscountRate}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                        value={lots[index].purchaseDiscountRate}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        required
                        disabled
                        name={getName(row, 'purchaseBasicDiscountRate', index)}
                        helperText={touched.date ? getError(errors, index, 'purchaseBasicDiscountRate') : ''}
                        error={Boolean(getError(errors, index, 'purchaseBasicDiscountRate'))}
                        type="number"
                        label="Remise financière d'achat"
                        defaultValue={lot.purchaseBasicDiscountRate}
                        value={purchaseBasicDiscountRate(index)}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}/>
                <Grid item xs={12} sm={4} md={2}>
                    <FormControl size="small" error={ Boolean(getError(errors, index, 'saleDiscountType'))} required>
                        <FormLabel component="legend">{'Remise de vente'}</FormLabel>
                        <RadioGroup
                            name={getName(row, 'saleDiscountType', index)}
                            defaultValue={`${lot.saleDiscountType}`}
                            value={`${lots[index].saleDiscountType}`}
                            row
                            onChange={handleChange}
                        >
                            <FormControlLabel value="0" control={<Radio size="small" />} label={getDiscountTypeLabel(0)} />
                            <FormControlLabel value="1" control={<Radio size="small" />} label={getDiscountTypeLabel(1)} />
                        </RadioGroup>
                        <FormHelperText>{touched.date ? getError(errors, index, 'saleDiscountType') : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        required
                        name={getName(row, 'saleDiscountRate', index)}
                        helperText={touched.date ? getError(errors, index, 'saleDiscountRate') : ''}
                        error={Boolean(getError(errors, index, 'saleDiscountRate'))}
                        label="Taux UG de vente"
                        type="number"
                        defaultValue={lot.saleDiscountRate}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                        value={lots[index].saleDiscountRate}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        required
                        disabled
                        name={getName(row, 'saleBasicDiscountRate', index)}
                        helperText={touched.date ? getError(errors, index, 'saleBasicDiscountRate') : ''}
                        error={Boolean(getError(errors, index, 'saleBasicDiscountRate'))}
                        type="number"
                        label="Remise financière de vente"
                        defaultValue={lot.saleBasicDiscountRate}
                        value={saleBasicDiscountRate(index)}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={12}/>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        disabled
                        name={getName(row, 'profitBasicDiscountRate', index)}
                        helperText={touched.date ? getError(errors, index, 'profitBasicDiscountRate') : ''}
                        error={Boolean(getError(errors, index, 'profitBasicDiscountRate') || Boolean(profitBasicDiscountRate(index) < 0))}
                        label={profitBasicDiscountRate(index) < 0 ? 'Perte' : 'Bénéfice'}
                        type="number"
                        defaultValue={lot.profitBasicDiscountRate}
                        value={profitBasicDiscountRate(index)}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        required
                        type="number"
                        name={getName(row, 'initialQuantity', index)}
                        helperText={touched.date ? getError(errors, index, 'initialQuantity') : ''}
                        error={Boolean(getError(errors, index, 'initialQuantity'))}
                        label="Quantité acheté"
                        defaultValue={lot.initialQuantity}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={lots[index].initialQuantity}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        disabled={true}
                        required
                        type="number"
                        name={getName(row, 'currentQuantity', index)}
                        helperText={touched.date ? getError(errors, index, 'currentQuantity') : ''}
                        error={Boolean(getError(errors, index, 'currentQuantity'))}
                        label="Quantité Total"
                        onChange={handleChange}
                        defaultValue={lot.currentQuantity}
                        value={totalQuantity(index)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <RoundedButton
                        color="secondary"
                        icon={<DeleteIcon fontSize="small" />}
                        title={'Supprimer'}
                        onClick={() => removeLot(index)}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider/>
                </Grid>
            </Grid>

        </Grid>
    ));

    return (
        <>
            <form ref={setRef} onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <OrganizationLookup
                            disabled={Boolean(!authorization.isInRoles(roles.system) && !authorization.isInRoles(roles.administrator))}
                            required
                            submitCount={submitCount}
                            errors={errors}
                            touched={touched}
                            label ="Vendeur"
                            name="thirdParty"
                            value={thirdParty}
                            onChange={setFieldValue}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            name="date"
                            type="date"
                            helperText={touched.date ? errors.date : ''}
                            error={Boolean(errors.date)}
                            label="Date d'achat"
                            defaultValue={toDateForForms(date)}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            InputLabelProps={
                                {
                                    shrink: true,
                                }
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12} md={4}>
                        <TextField
                            required
                            name="deliveryDate"
                            type="date"
                            helperText={touched.deliveryDate ? errors.deliveryDate : ''}
                            error={Boolean(errors.deliveryDate)}
                            label="Date de livraison"
                            defaultValue={toDateForForms(deliveryDate)}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            InputLabelProps={
                                {
                                    shrink: true,
                                }
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12} md={12}/>
                </Grid>
                <Grid container spacing={2}>
                    {(state && renderLots(lots))}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <RoundedButton
                            icon={<AddIcon fontSize="small" />}
                            title={'Ajouter'}
                            float={'right'}
                            onClick={addLot}
                        />

                    </Grid>
                </Grid>
            </form>
            <div className={globalClasses.content}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BottomAppBar>
                            <Box className={classes.flexGrow} />
                            <Box display="flex" spacing={1}>
                                {
                                    getDiscountAmount() > 0 && (
                                        <Box className={classes.amountProperty}>
                                            <Property
                                                valueVariant="subtitle1"
                                                label="MT REMISE"
                                                valueColor="secondary"
                                                value={formatter.format(getDiscountAmount())}
                                            />
                                        </Box>
                                    )
                                }
                                <Box className={classes.amountProperty}>
                                    <Property valueVariant="subtitle1" label="Montant HT" valueColor="default" value={formatter.format(getEtAmount())} />
                                </Box>
                                <Box className={classes.amountProperty}>
                                    <Property valueVariant="subtitle1" label="Montant TVA" valueColor="default" value={formatter.format(getVatAmount())} />
                                </Box>
                                <Box className={classes.amountProperty}>
                                    <Property valueVariant="subtitle1" label="Montant TTC" value={formatter.format(getItAmount())} />
                                </Box>
                                <Box className={classes.amountProperty}>
                                    <Property valueVariant="subtitle1" label="Montant" value={formatter.format(getAmount())} />
                                </Box>
                            </Box>
                        </BottomAppBar>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};


