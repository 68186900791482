import { blue } from '@material-ui/core/colors';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
    avatar: {
        color: '#FFF',
        backgroundColor: blue[500],
    },
}));

export default useStyles;
