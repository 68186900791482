import React from 'react';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const Form = (props) => {
    const {
        values: { normalParcelNumber, coldParcelNumber },
        errors,
        touched,
        handleSubmit,
        handleChange,
    } = props;

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="normalParcelNumber"
                        type="number"
                        helperText={touched.normalParcelNumber ? errors.normalParcelNumber : ''}
                        error={Boolean(errors.normalParcelNumber)}
                        label="Nombre de colis normal"
                        value={normalParcelNumber}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="coldParcelNumber"
                        type="number"
                        helperText={touched.coldParcelNumber ? errors.coldParcelNumber : ''}
                        error={Boolean(errors.coldParcelNumber)}
                        label="Nombre de colis froid"
                        value={coldParcelNumber}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
