import { default as useApiBase } from '../../hooks/api';
import { endPoint, apiEndPoint, apiSettings } from './constants';

export default function useApi () {
    const api = useApiBase(apiSettings);

    const get = (id) => api.get(`${apiEndPoint}/${id}`)
        .then((response) => response.data);

    const deleTe = (id) => api.deleTe(`${apiEndPoint}/${id}`, endPoint);

    const updateStatus = (data, status) => api.put(`${apiEndPoint}/${data.id}/status`, {
        status,
    });

    const save = (data) => {
        if (data.id) {
            return api.put(`${apiEndPoint}/${data.id}`, data)
                .then((response) => response.data);
        }

        return api.post(apiEndPoint, data)
            .then((response) => response.data);
    };

    const deliveryDownload = (id) => api.download(`${apiEndPoint}/delivery/download/${id}`);

    const areaDownload = (id) => api.download(`${apiEndPoint}/area/download/${id}`);

    const recoveryDownload = (id) => api.download(`${apiEndPoint}/recovery/download/${id}`);

    const missionDownload = (id) => api.download(`${apiEndPoint}/mission/download/${id}`);

    const billDownload = (id) => api.download(`${apiEndPoint}/bill/download/${id}`);

    const prepareDownload = (id) => api.download(`${apiEndPoint}/prepare/download/${id}`);

    const labelsDownload = (id) => api.download(`${apiEndPoint}/labels/download/${id}`);

    return {
        get,
        deleTe,
        updateStatus,
        save,
        deliveryDownload,
        billDownload,
        prepareDownload,
        labelsDownload,
        areaDownload,
        recoveryDownload,
        missionDownload,
    };
}
