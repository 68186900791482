import { object, string, number, date } from 'yup';

const validationSchema = object({
    product: object().nullable()
        .required('Le product est requis'),
    code: string('Saisissez le n° de lot').required('Le N° de lot est requis'),
    price: number().typeError('Le PU n\'est pas valide')
        .required('Le PU est requis')
        .min(0, 'Le PU n\'est pas valide')
        .required('Le PU n\'est pas valide'),
    quantityPackage: number().typeError('La quantité dans le colis n\'est pas valide')
        .min(0, 'La quantité dans le colis n\'est pas valide')
        .required('La quantité dans le colis est requise'),
    purchaseDiscountType: number().typeError('La remise d\'achat n\'est pas valide')
        .min(0, 'La remise d\'achat n\'est pas valide')
        .required('La remise d\'achat est requise'),
    purchaseDiscountRate: number().typeError('Le taux UG d\'achat n\'est pas valide')
        .min(0, 'Le UG remise d\'achat n\'est pas valide')
        .required('Le taux UG d\'achat est requis'),
    saleDiscountType: number().typeError('La remise de vente n\'est pas valide')
        .min(0, 'La remise de vente n\'est pas valide')
        .required('La remise de vente est requise'),
    saleDiscountRate: number().typeError('Le taux UG de vente n\'est pas valide')
        .min(0, 'Le taux de UG de vente n\'est pas valide')
        .required('Le taux UG de vente est requis'),
    purchaseDate: date().typeError('La date d\'achat n\'est pas valide')
        .required('La date d\'achat est requise'),
    catalogMinimumQuantity: number().typeError('La quantité minimum pour le catalogue n\'est pas valide')
        .min(0, 'La quantité minimum pour le catalogue n\'est pas valide')
        .required('La quantité minimum pour le catalogue est requise'),
    expirationDate: date().typeError('La date de péremption n\'est pas valide')
        .required('La date de péremption est requise'),
    initialQuantity: number().typeError('La quantité achetée n\'est pas valide')
        .min(1, 'La quantité achetée n\'est pas valide')
        .required('La quantité achetée est requise'),
});

export default validationSchema;
