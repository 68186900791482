import React, { createRef, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { default as MToolbar } from '@material-ui/core/Toolbar';

import { GlobalContext } from '../../globalState';
import Alert from './alert';
import Content from './content';
import Dialog from './dialog';
import Drawer from './drawer';
import Header from './header';
import Loader from './loader';
import Toolbar from './toolbar';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    main: {
        flexGrow: 1,
    },
}));

export default function Layout (props) {
    const classes = useStyles();
    const { state: { loading, toolbar }, dispatch } = useContext(GlobalContext);

    let form = createRef();

    const setRef = (ref) => {
        form = ref;
    };

    const onSave = () => {
        form.dispatchEvent(new Event('submit'));
    };

    useEffect(() => {
        dispatch({
            form: {
                setRef,
                submit: onSave,
            },
        });
    }, []);

    return (
        <div className={classes.root}>
            <Loader loading={loading} />
            <Header />
            <Drawer />
            <main className={classes.main}>
                <MToolbar />
                <Loader loading={loading} />
                <Toolbar open={false} />
                {toolbar && toolbar.open && <MToolbar />}
                <Content>
                    <Alert />
                    {props.children}
                </Content>
            </main>
            <Dialog />
        </div>
    );
}
