import { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { GlobalContext } from '../globalState';
import ApiClient from '../services/api';
import { format } from '../services/string';
import { default as useSnackbar } from './signalr';
import useError from './error';
import useLoader from './loader';

export const defaultSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: '{0} a été créé(e) avec succés',
        put: '{0} a été mis(e) à jour avec succés',
        'delete': '{0} a été suprimé(e) avec succés',
    },
    error: 'Une erreur est survenue',
};

export default function useApi (settings) {
    const loader = useLoader();
    const errorHandler = useError();
    const snackbar = useSnackbar();
    const history = useHistory();
    const { dispatch, state: { user } } = useContext(GlobalContext);
    const client = new ApiClient(process.env.REACT_APP_API_URL, user?.accessToken, (session) => dispatch({
        user: session,
    }));

    if (!settings) {
        settings = {
            ...settings,
            defaultSettings,
        };
    }

    const showLoader = () => {
        if (settings.loader) {
            loader.show();
        }
    };

    const hideLoader = () => {
        if (settings.loader) {
            loader.hide();
        }
    };

    const refresh = () => {
        if (settings.refresh) {
            dispatch({
                refresh: Date.now(),
            });
        }
    };

    const alertPostSuccess = (data) => {
        if (settings.alert && settings?.success?.post) {
            snackbar.handle(format(settings.success.post, data.id ?? ''), 'success');
        }
    };

    const alertPutSuccess = (data) => {
        if (settings.alert && settings?.success?.put) {
            snackbar.handle(format(settings.success.put, data.id ?? ''), 'success');
        }
    };

    const alertDeleteSuccess = (data) => {
        if (settings.alert && settings?.success?.delete) {
            snackbar.handle(format(settings.success.delete, data.id ?? ''), 'success');
        }
    };

    const alertError = () => {
        if (settings.alert && settings?.error) {
            snackbar.handle(format(settings.error), 'error');
        }
    };

    const get = (path) => {
        showLoader();

        return client.get(path)
            .then((response) => {
                if (response.status === 204) {
                    errorHandler.handle(response.status);
                }

                return Promise.resolve(response);
            })
            .catch((error) => {
                errorHandler.handle(error.response.status);

                return Promise.reject(error);
            })
            .finally(() => {
                hideLoader();
            });
    };

    const deleTe = (path, endPoint) => {
        showLoader();

        return client.delete(path)
            .then((response) => {
                if (endPoint) {
                    history.push(endPoint);
                } else {
                    refresh();
                }

                alertDeleteSuccess(response.data);

                return Promise.resolve(response);
            })
            .catch((error) => {
                alertError();

                return Promise.reject(error);
            })
            .finally(() => {
                hideLoader();
            });
    };

    const post = (path, data = {
    }) => {
        showLoader();

        return client.post(path, data)
            .then((response) => {
                refresh();
                alertPostSuccess(response.data);

                return Promise.resolve(response);
            })
            .catch((error) => {
                alertError();

                return Promise.reject(error);
            })
            .finally(() => {
                hideLoader();
            });
    };

    const put = (path, data = {
    }) => {
        showLoader();

        return client.put(path, data)
            .then((response) => {
                refresh();
                alertPutSuccess(data);

                return Promise.resolve(response);
            })
            .catch((error) => {
                alertError();

                return Promise.reject(error);
            })
            .finally(() => {
                hideLoader();
            });
    };

    const download = (path, data = {
    }) => {
        showLoader();
        client.download(path, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
            .finally(() => {
                hideLoader();
            });
    };

    return {
        get,
        deleTe,
        post,
        put,
        download,
    };
}
