export const formatAddress = (address) => {
    if (!address) {
        return '';
    }

    const { line1, line2, city } = address;

    return format(line1, line2, city?.zipCode, city?.name, city?.department?.name, city?.department?.country?.name);
};

export const format = (line1, line2, zipCode, city, department, country) => {
    let result = ((line1 || ''));

    result.trim();
    result += line2 ? `(${line2})` : ' ';
    result.trim();
    result += zipCode ? ` ${zipCode}` : ' ';
    result.trim();
    result += city ? ` ${city}` : ' ';
    result.trim();
    result += department ? ` ${department}` : ' ';
    result.trim();
    result += country ? ` ${country}` : ' ';
    result.trim();

    return result;
};
