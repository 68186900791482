import { makeStyles, createStyles } from '@material-ui/core/styles';

import Color from './color';

const useStyles = makeStyles((theme) => createStyles({
    avatar: {
        color: '#FFF',
        backgroundColor: Color[500],
    },
    flexGrow: {
        flexGrow: 1,
    },
    amountProperty: {
        margin: theme.spacing(1),
    },
}));

export default useStyles;
