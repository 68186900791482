import React, { useEffect, useContext, useState } from 'react';
import useStyles from './theme';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment-timezone';
import { GlobalContext } from '../../globalState';
import { apiEndPoint } from '../report/sales/constants';
import { orderColumns, lotColumns, sort, lotSort } from './columns';
import useError from '../../hooks/error';
import useApi from '../../hooks/api';
import 'moment/locale/fr';
import useAuthorization from '../../hooks/authorization';
import useToolbar from '../../hooks/toolbar';
import { timeZone } from '../../services/date';
import { roles } from '../authorization/constants';
import { viewEndPoint, expirationApiEndPoint as lotApiEndPoint } from '../lot/constants';
import { endPoint as orderEndPoint, apiStatusEndPoint as orderApiEndPoint, status as orderStatus } from '../order/constants';
import { TodayAmounts,
    TodayEmployeeAmounts } from '../report/sales/day/amounts';
import { TodayCount as TodaySalesCount,
    TodayEmployeeCount as TodayEmployeeSalesCount } from '../report/sales/day/counts';
import { CurrentMonthAmounts,
    CurrentMonthEmployeeAmounts } from '../report/sales/month/amounts';
import { CurrentMonthCount,
    CurrentMonthEmployeeCount } from '../report/sales/month/counts';
import { Table, useStyles as useTableStyles } from '../shared/grid';
import { useHistory } from 'react-router-dom';
import { default as useGlobalStyles } from '../shared/theme';
import Page from '../shared/page';
const Index = () => {
    const { state: { user } } = useContext(GlobalContext);
    const client = useApi();
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const toolBar = useToolbar();
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(null);
    const authorization = useAuthorization();
    const errorHandler = useError();
    const history = useHistory();
    const tableClasses = useTableStyles();
    const startPreviewMonthDate = moment.utc().tz(timeZone)
        .add(-3, 'M')
        .startOf('month');
    const endCurrentMonthDate = moment.utc().tz(timeZone)
        .endOf('month');
    const endNextMonthDate = moment.utc().tz(timeZone)
        .add(12, 'M')
        .endOf('month');

    const getTitleComponent = () => <CardHeader avatar={<Avatar className={classes.avatar}><DashboardIcon /></Avatar>} />;

    const lotExpirationApiEndPoint = `${lotApiEndPoint}?date=${endNextMonthDate.format('YYYY-MM-DD')}&timeZone=${timeZone}`;

    const handleGetRowClasses = (key, row) => {
        const rowDate = moment(new Date(row.date)).add(1, 'days');
        const nowDate = moment(new Date().toUTCString());
        const result = [];

        if (row.status === orderStatus.confirmed && rowDate.isSameOrBefore(nowDate)) {
            result.push(tableClasses.warning);
        };

        return result;
    };
    const handleOrderRowClick = (event, key, row) => {
        const path = `${orderEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };
    const handleLotRowClick = (event, key, row) => {
        const path = `${viewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else if (authorization.isInRoles(roles.system, roles.administrator, roles.preparer)) {
            history.push(path);
        }
    };

    useEffect(() => {
        toolBar.show({
            title: getTitleComponent(),
        });

        const load = () => {
            setIsLoading(true);
            // eslint-disable-next-line max-len
            client.get(`${apiEndPoint}/sales/sellers?startDate=${startPreviewMonthDate.format('YYYY-MM-DD')}&endDate=${endCurrentMonthDate.format('YYYY-MM-DD')}&timeZone=${timeZone}`)
                .then((response) => {
                    setState(response.data);
                })
                .catch((error) => {
                    errorHandler.handle(error.response.status);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        load();
    }, []);

    return (
        <Page title ="Home">
            <div className={globalClasses.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            {
                                authorization.isInRoles(roles.system, roles.supervisor) && (
                                    <Grid item xs={12} sm={3}>
                                        <TodayAmounts data={state} isLoading={isLoading} />
                                    </Grid>
                                )
                            }
                            {
                                user?.employee && (
                                    <Grid item xs={12} sm={3}>
                                        <TodayEmployeeAmounts data={state} isLoading={isLoading} employee={user?.employee} />
                                    </Grid>
                                )
                            }
                            {
                                authorization.isInRoles(roles.system, roles.supervisor) && (
                                    <Grid item xs={12} sm={3}>
                                        <TodaySalesCount data={state} isLoading={isLoading} />
                                    </Grid>
                                )
                            }
                            {
                                user?.employee && (
                                    <Grid item xs={12} sm={3}>
                                        <TodayEmployeeSalesCount data={state} isLoading={isLoading} employee={user?.employee} />
                                    </Grid>
                                )
                            }
                            {
                                authorization.isInRoles(roles.system, roles.supervisor) && (
                                    <Grid item xs={12} sm={3}>
                                        <CurrentMonthAmounts data={state} isLoading={isLoading} />
                                    </Grid>
                                )
                            }
                            {
                                user?.employee && (
                                    <Grid item xs={12} sm={3}>
                                        <CurrentMonthEmployeeAmounts data={state} isLoading={isLoading} employee={user?.employee} />
                                    </Grid>
                                )
                            }
                            {
                                authorization.isInRoles(roles.system, roles.supervisor) && (
                                    <Grid item xs={12} sm={3}>
                                        <CurrentMonthCount data={state} isLoading={isLoading} />
                                    </Grid>
                                )
                            }
                            {
                                user?.employee && (
                                    <Grid item xs={12} sm={3}>
                                        <CurrentMonthEmployeeCount data={state} isLoading={isLoading} employee={user?.employee} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Table
                                    title={<CardHeader avatar={<Avatar><MoveToInboxIcon /></Avatar>} title="Commandes en cours" />}
                                    rowKey="id"
                                    sort={sort}
                                    endPoint={orderApiEndPoint}
                                    onRowClick={handleOrderRowClick}
                                    columns={orderColumns}
                                    getRowClasses={handleGetRowClasses}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Table
                                    title={<CardHeader avatar={<Avatar><WarningIcon /></Avatar>} title="Lots bientôt expirés" />}
                                    rowKey="id"
                                    sort={lotSort}
                                    endPoint={lotExpirationApiEndPoint}
                                    onRowClick={handleLotRowClick}
                                    columns={lotColumns}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Page>
    );
};

export default Index;
