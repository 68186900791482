import React, { useState, useContext } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import { useUp } from '../../hooks/screen';
import logo from '../../images/logo-45.png';
import { default as useAuthentificationApi } from '../authentification/api';
import { roles } from '../authorization/constants';
import { drawerWidth } from './theme';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 16,
    },
    hide: {
        display: 'none',
    },
    logo: {
        flexGrow: 1,
    },
    popover: {
        '& .MuiListItemIcon-root': {
            minWidth: '32px',
        },
    },
}));

export default function Header () {
    const upMd = useUp('md');
    const classes = useStyles();
    const history = useHistory();
    const api = useAuthentificationApi();
    const authorization = useAuthorization();
    const { state: { user, drawerIsOpen }, dispatch } = useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleToogleDrawer = () => {
        dispatch({
            drawerIsOpen: !drawerIsOpen,
        });
    };

    const handleOpenAccount = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAccount = () => {
        setAnchorEl(null);
    };

    const handleUsers = () => {
        history.push('/users');
        handleCloseAccount();
    };

    const handleLogout = () => {
        api.signout();
        handleCloseAccount();
    };

    return (
        <AppBar
            position="fixed"
            className={
                clsx(classes.appBar, {
                    [classes.appBarShift]: drawerIsOpen,
                })
            }
        >
            <Toolbar style={
                {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                }
            }
            >
                <Box>
                    <IconButton
                        onClick={handleToogleDrawer}
                        edge="start"
                        color="inherit"
                        className={
                            clsx(classes.menuButton, {
                                [classes.hide]: drawerIsOpen,
                            })
                        }
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                <Box className={classes.logo}>
                    {
                        !drawerIsOpen &&
                        <img src={logo} alt="logo" height="45" />
                    }
                </Box>
                {
                    user && (
                        <div>
                            <Button
                                style={
                                    {
                                        textTransform: 'none',
                                        minWidth: '29px',
                                    }
                                }
                                color="inherit"
                                onClick={handleOpenAccount}
                                startIcon={<AccountCircleIcon />}
                            >
                                {upMd && user.name}
                            </Button>
                            <Popover
                                open={Boolean(anchorEl)}
                                onClose={handleCloseAccount}
                                anchorEl={anchorEl}
                                anchorOrigin={
                                    {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }
                                }
                                transformOrigin={
                                    {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }
                                }
                            >
                                <List component="nav" className={classes.popover}>
                                    <ListItem
                                        dense
                                        button
                                        onClick={() => handleCloseAccount()}
                                    >
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Paramètres" />
                                    </ListItem>
                                    {
                                        authorization.isInRoles(roles.system) &&
                                    (
                                        <ListItem
                                            dense
                                            button
                                            onClick={() => handleUsers()}
                                        >
                                            <ListItemIcon>
                                                <VerifiedUserIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Utilisateurs" />
                                        </ListItem>
                                    )
                                    }
                                    <ListItem
                                        dense
                                        button
                                        onClick={() => handleLogout()}
                                    >
                                        <ListItemIcon>
                                            <ExitToAppIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Déconnexion" />
                                    </ListItem>
                                </List>
                            </Popover>
                        </div>
                    )
                }
            </Toolbar>
        </AppBar>
    );
}
