import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import Page from '../../shared/page';
import { GlobalContext } from '../../../globalState';
import useApi from '../../../hooks/api';
import 'moment/locale/fr';
import useAuthorization from '../../../hooks/authorization';
import useError from '../../../hooks/error';
import { timeZone } from '../../../services/date';
import { roles } from '../../authorization/constants';
import Tabs from '../../shared/tabs';
import { apiEndPoint } from './constants';
import { Amounts as DaySalesAmounts,
    TodayAmounts,
    TodayEmployeeAmounts } from './day/amounts';
import { Counts as DaySalesCounts,
    TodayCount as TodaySalesCount,
    TodayEmployeeCount as TodayEmployeeSalesCount } from './day/counts';
import { Amounts as MonthAmounts,
    EmployeesTurnoverAmounts,
    EmployeesPositiveAmounts,
    EmployeesProfitAmounts,
    CurrentMonthAmounts,
    CurrentMonthEmployeeAmounts } from './month/amounts';
import { Counts as MonthCounts,
    CurrentMonthCount,
    CurrentMonthEmployeeCount } from './month/counts';

const Index = () => {
    const { state: { user } } = useContext(GlobalContext);
    const client = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [isSale] = useState(true);
    // const [globalAmount, setglobalAmount] = useState(null);
    const [state, setState] = useState(null);
    const authorization = useAuthorization();
    const errorHandler = useError();
    const startDate = moment.utc().tz(timeZone)
        .add(-3, 'M')
        .startOf('month');
    const endDate = moment.utc().tz(timeZone)
        .endOf('month');


    const load = () => {
        setIsLoading(true);
        client.get(`${apiEndPoint}/sales/sellers?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&timeZone=${timeZone}`)
            .then((response) => {
                setState(response.data);
            })
            .catch((error) => {
                errorHandler.handle(error.response.status);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        load();
    }, []);

    const Day = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={user?.employee && authorization.isInRoles(roles.system, roles.supervisor) ? 8 : 10}>
                <DaySalesAmounts data={state} isLoading={isLoading} employee={user?.employee} />
            </Grid>
            {
                authorization.isInRoles(roles.system, roles.supervisor) && (
                    <Grid item xs={12} sm={2}>
                        <TodayAmounts data={state} isLoading={isLoading} />
                    </Grid>
                )
            }
            {
                user?.employee && (
                    <Grid item xs={12} sm={2}>
                        <TodayEmployeeAmounts data={state} isLoading={isLoading} employee={user?.employee} />
                    </Grid>
                )
            }
            <Grid item xs={12} sm={user?.employee && authorization.isInRoles(roles.system, roles.supervisor) ? 8 : 10}>
                <DaySalesCounts data={state} isLoading={isLoading} employee={user?.employee} />
            </Grid>
            {
                authorization.isInRoles(roles.system, roles.supervisor) && (
                    <Grid item xs={12} sm={2}>
                        <TodaySalesCount data={state} isLoading={isLoading} />
                    </Grid>
                )
            }
            {
                user?.employee && (
                    <Grid item xs={12} sm={2}>
                        <TodayEmployeeSalesCount data={state} isLoading={isLoading} employee={user?.employee} />
                    </Grid>
                )
            }
        </Grid>
    );

    const Month = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={user?.employee && authorization.isInRoles(roles.system, roles.supervisor) ? 8 : 10}>
                <MonthAmounts data={state} isLoading={isLoading} employee={user?.employee} />
            </Grid>
            {
                authorization.isInRoles(roles.system, roles.supervisor) && (
                    <Grid item xs={12} sm={2}>
                        <CurrentMonthAmounts data={state} isLoading={isLoading} />
                    </Grid>
                )
            }
            {
                user?.employee && (
                    <Grid item xs={12} sm={2}>
                        <CurrentMonthEmployeeAmounts data={state} isLoading={isLoading} employee={user?.employee} />
                    </Grid>
                )
            }
            <Grid item xs={12} sm={user?.employee && authorization.isInRoles(roles.system, roles.supervisor) ? 8 : 10}>
                <MonthCounts data={state} isLoading={isLoading} employee={user?.employee} />
            </Grid>
            {
                authorization.isInRoles(roles.system, roles.supervisor) && (
                    <Grid item xs={12} sm={2}>
                        <CurrentMonthCount data={state} isLoading={isLoading} />
                    </Grid>
                )
            }
            {
                user?.employee && (
                    <Grid item xs={12} sm={2}>
                        <CurrentMonthEmployeeCount data={state} isLoading={isLoading} employee={user?.employee} />
                    </Grid>
                )
            }
            {
                authorization.isInRoles(roles.system, roles.supervisor) && (
                    <>
                        <Grid item xs={12}>
                            <EmployeesPositiveAmounts data={state} isLoading={isLoading} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeesTurnoverAmounts data={state} isLoading={isLoading} />
                        </Grid>
                        {
                            authorization.isInRoles(roles.system) && (
                                <Grid item xs={12}>
                                    <EmployeesProfitAmounts data={state} isLoading={isLoading} />
                                </Grid>
                            )
                        }
                    </>
                )
            }
        </Grid>
    );

    const tabs = [
        {
            label: 'Jours',
            component: <Day />,
        },
        {
            label: 'Mois',
            component: <Month />,
        },
    ];

    return (
        <>
            {
                (authorization.isInRoles(roles.system, roles.supervisor) || user?.employee) && isSale &&
        (
            <Page title = "Ventes">
                <Tabs tabs={tabs} data={state} />
            </Page>
        )
            }
        </>
    );
};

export default Index;
