import React from 'react';
import Grid from '@material-ui/core/Grid';
import Product from '../product/property';
import Purchase from '../purchases/property';
import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { formatPercent } from '../../services/number';
import Property from '../shared/property';
import useAuthorization from '../../hooks/authorization';
import { roles } from '../authorization/constants';
import { getDiscountTypeLabel, getMarginRate } from '../shared/services';


const Info = (props) => {
    const {
        code, price, purchaseDiscountType, purchaseDiscountRate, expirationDate,
        purchaseAmount, purchaseFinancialDiscountRate, initialQuantity, currentQuantity,
        saleDiscountType, saleDiscountRate, purchaseDate, warehouse, warehousePosition,
        product, inCatalog, catalogMinimumQuantity, quantityPackage, amount, storeAmount, purchaseAmounts, globalPurchaseAmount, orderId,
    } = props;

    const margin = formatPercent(parseFloat(getMarginRate(product.publicPrice, price, product.vatRate, product.extraCostHonorary)));
    const authorization = useAuthorization();


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
                <Property label="Numéro" value={code} />
                <Property label="Produit" value={<Product data={product} />} />
                <Property label="Date d'achat" value={formatUtcToLocalDate(purchaseDate)} />
                <Property label="PU" value={formatter.format(price)} />
                <Property label="Marge" value={margin} />
                <Property label="Date de péremption" value={formatUtcToLocalDate(expirationDate)} />
                <Property label="Quantité minimum pour le catalogue" value={`${catalogMinimumQuantity}`} />
                <Property label="Inclus dans le catalogue" value={inCatalog ? 'Oui' : 'Non'} />
                <Property label="Entrepôt" value={warehouse.name} />
                <Property label="Rangement" value={warehousePosition} />
            </Grid>
            <Grid item xs={12} sm={6} >

                <Property label="Commande d'achat" value={<Purchase data={orderId} />} />
                <Property label="Quantité achetée" value={initialQuantity} />
                {
                    !authorization.isInRoles(roles.preparer) && (
                        <>
                            <Property label="Remise d'achat" value={getDiscountTypeLabel(purchaseDiscountType)} />
                            <Property label="Taux UG d'achat" value={formatPercent(purchaseDiscountRate)} />
                            <Property label="Taux de la remise d'achat" value={formatPercent(purchaseFinancialDiscountRate)} />
                            <Property label="Montant d'achat" value={formatter.format(purchaseAmount)} />
                            <Property label="Remise de vente" value={getDiscountTypeLabel(saleDiscountType)} />
                            <Property label="Taux UG de vente" value={formatPercent(saleDiscountRate)} />
                        </>)
                }
                <Property label="Quantité dans le colis" value={`${quantityPackage}`} />
                <Property valueColor="secondary" label="Quantité disponible" value={currentQuantity} />
                {
                    !authorization.isInRoles(roles.preparer) && (
                        <>
                            <Property valueColor="secondary" label="Montant" value={formatter.format(amount)} />
                            <Property valueColor="secondary" label="Montant total" value={formatter.format(storeAmount)} />
                            <Property valueColor="secondary" label="Montant d'achat" value={formatter.format(purchaseAmounts)} />
                            <Property valueColor="secondary" label="Montant d'achat global" value={formatter.format(globalPurchaseAmount)} />
                        </>)
                }
            </Grid>
        </Grid>
    );
};

export default Info;
