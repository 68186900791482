export const getDiscountTypeLabel = (discount) => {
    if (discount === 0) {
        return 'Financière';
    }

    return 'UG';
};

export const getMarginRate = (publicPrice, price, vatRate, extraCostHonorary) => (
    (((publicPrice - getVatAmount(price, vatRate) - extraCostHonorary) / price) - 1) * 100
).toFixed(2);

export const getVatAmount = (price, vatRate) => ((price * vatRate) / 100).toFixed(2);

export const getBasicDiscountRate = (discountRate) => ((discountRate / (discountRate + 100)) * 100).toFixed(4);

export const getTotalQuantity = (discountType, discountRate, quantity) => {
    switch (discountType) {
        case 0:
        case '0':
            return quantity;
        default:
            return quantity + Math.floor((quantity * discountRate) / 100);
    }
};
