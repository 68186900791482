import moment from 'moment-timezone';
import { mode } from '../payment/constants';

export const key = 'orders';
export const endPoint = '/orders';
export const viewEndPoint = `${endPoint}`;
export const apiEndPoint = '/orders';
export const toShipApiEndPoint = '/orders/toship';

export const initialValue = {
    status: 0,
    date: moment().startOf('day'),
    defaultPaymentMode: mode.check,
    normalParcelNumber: 0,
    coldParcelNumber: 0,
};

export const status = {
    returned: -2,
    canceled: -1,
    draft: 0,
    confirmed: 1,
    preparation: 2,
    ready: 3,
    shipped: 4,
    delivered: 5,
};

export const type = {
    purchase: 0,
    sale: 1,
};

export const apiStatusEndPoint = `/orders/statuses?statuses=${status.confirmed}&statuses=${status.preparation}`;

export const getInvoicesApiEndPoint = (id) => `${apiEndPoint}/${id}/invoices`;

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La commande {0} a été créée avec succés',
        put: 'La commande {0} a été mise à jour avec succés',
        'delete': 'La commande {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
