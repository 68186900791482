import React, { useContext } from 'react';

import { AppBar, Toolbar as MToolbar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { GlobalContext } from '../../globalState';
import { useUp } from '../../hooks/screen';
import { drawerWidth, closedDrawerWidth } from './theme';

const useStyles = makeStyles(() => createStyles({
    appBar: {
        top: 'auto',
        bottom: 0,
        background: '#FFF',
    },
    grow: {
        flexGrow: 1,
    },
}));

export default function BottomAppBar (props) {
    const { state: { drawerIsOpen } } = useContext(GlobalContext);
    const classes = useStyles();
    const upMd = useUp('md');

    return (
        <>
            <MToolbar />
            <AppBar
                position="fixed"
                color="default"
                className={classes.appBar}
                style={
                    {
                        left: upMd ? drawerIsOpen ? drawerWidth : closedDrawerWidth : 0,
                        right: 0,
                        width: 'auto',
                    }
                }
            >
                <MToolbar className={classes.content}>
                    {props.children}
                </MToolbar>
            </AppBar>
        </>
    );
}
