import { getStatusKeyValues as getOrderStatusKeyValues } from '../../order/services';
import { apiEndPoint as deliveriesApiEndPoint } from '../constants';
import { getStatusKeyValues } from '../services';

export const apiEndPoint = deliveriesApiEndPoint;


export const statusColumns = [
    {
        title: 'N°',
        field: 'orderId',
        editable: false,
    },
    {
        title: 'Client',
        field: 'thirdPartyName',
        editable: false,
    },
    {
        title: 'Ville',
        field: 'thirdPartyAddress',
        editable: false,
    },
    {
        title: 'Date',
        field: 'orderDate',
        type: 'date',
        editable: false,
    },
    {
        title: 'Statut',
        field: 'orderStatus',
        required: true,
        bind: getOrderStatusKeyValues(),
    },
    {
        title: 'Mt TTC',
        field: 'orderItAmount',
        type: 'currency',
        editable: false,
    },
    {
        title: 'Livraison',
        field: 'deliveryStatus',
        required: true,
        bind: getStatusKeyValues(),
    },
];

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'Les commandes selectionnées ont été ajoutées avec succés',
        put: 'Les commandes selectionnées ont été mises à jour avec succés',
        'delete': 'Commande(s) supprimée(s) de la livraison avec succés',
    },
    error: 'Une erreur est survenue',
};
