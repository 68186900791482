import React from 'react';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { default as CityLookup } from './lookup';

const FormContent = (props) => {
    const {
        values: { line1, line2, city },
        errors,
        touched,
        handleChange,
        submitCount,
        setFieldValue,
    } = props;

    const onChange = (name, value) => {
        setFieldValue('address.city', value);
    };

    const getLabel = (data) => `${data.name} (${data.department.code}) ${data.department.name}`;

    return (
        <>
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    name="address.line1"
                    helperText={submitCount > 0 && errors.address?.line1 ? errors.address?.line1 : ''}
                    error={submitCount > 0 && Boolean(errors.address?.line1)}
                    label="Numéro et rue"
                    value={line1 || ''}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
                <TextField
                    name="address.line2"
                    helperText={submitCount > 0 && errors.address?.line2 ? errors.address?.line2 : ''}
                    error={submitCount > 0 && Boolean(errors.address?.line2)}
                    label="Complément d'adresse"
                    value={line2 || ''}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
                <CityLookup
                    required
                    submitCount={submitCount}
                    errors={
                        errors?.address ?? {
                        }
                    }
                    touched={touched}
                    name="city"
                    value={city}
                    onChange={onChange}
                    getOptionLabel={getLabel}
                />
            </Grid>
        </>
    );
};

export default FormContent;
