import { formatter } from '../../services/currency';
import { formatPercent } from '../../services/number';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Nom',
        field: 'brand',
        sorting: true,
    },
    {
        label: 'DCI',
        field: 'name',
        width: '20%',
        sorting: true,
    },
    {
        label: 'Dosage',
        field: 'dosage',
        sorting: true,
    },
    {
        label: 'Form',
        field: 'form',
        sorting: true,
    },
    {
        label: 'Condi.',
        field: 'conditioning',
        sorting: true,
    },
    {
        label: 'PU',
        field: 'price',
        sorting: false,
        render: (index, column, row) => formatter.format(row.price),
    },
    {
        label: 'SHP',
        field: 'extraCostHonorary',
        sorting: false,
        render: (index, column, row) => formatter.format(row.extraCostHonorary),
    },
    {
        label: 'PPA',
        field: 'publicPrice',
        sorting: false,
        render: (index, column, row) => formatter.format(row.publicPrice),
    },
    {
        label: 'TVA',
        field: 'vatRate',
        sorting: false,
        render: (index, column, row) => formatPercent(row.vatRate),
    },
    {
        label: 'Marge',
        field: 'marginRate',
        sorting: false,
        render: (index, column, row) => formatPercent(row.marginRate),
    },
    {
        label: 'Qté dispo.',
        field: 'quantity',
        sorting: false,
    },

];

