/* eslint-disable max-len */
import { status } from './constants';
import { round } from '../../services/number';

export const getStatusTypeLabel = (value) => {
    switch (value) {
        case status.draft:
            return 'Brouillon';
        case status.confirmed:
            return 'Confirmée';
        default:
            return 'Inconnu';
    }
};

export const getStatusKeyValues = () => {
    const result = [];

    result.push({
        key: status.returned,
        value: getStatusTypeLabel(status.returned),
    });
    result.push({
        key: status.canceled,
        value: getStatusTypeLabel(status.canceled),
    });
    result.push({
        key: status.draft,
        value: getStatusTypeLabel(status.draft),
    });
    result.push({
        key: status.confirmed,
        value: getStatusTypeLabel(status.confirmed),
    });

    return result;
};
export const getLotEtAmount = (purchaseDiscountType, amount, purchaseDiscountRate) => purchaseDiscountType === 0 ? amount - round((getFinancalDiscountRate(purchaseDiscountRate) * amount) / 100, 3) : amount;

export const getLotVatAmount = (etAmount, vatRate) => round((etAmount * vatRate) / 100, 4);

export const getLotItAmount = (etAmount, vatAmount) => etAmount + vatAmount;

export const getLotDiscountAmount = (amount, etAmount) => amount - etAmount;

export const getLotAmount = (quantity, price) => price * quantity;

export const getFinancalDiscountRate = (discountRate) => round((discountRate / (discountRate + 100)) * 100, 4);
