import React, { useState, useCallback } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';

const useStyles = makeStyles((theme) => createStyles({
    searchInput: {
        minWidth: 300,
        '& > div': {
            borderRadius: 25,
        },
    },
}));

export const SearchInput = (props) => {
    const { debounceWait, onChange } = props;
    const [value, setValue] = useState('');

    const classes = useStyles();

    const t = (event) => true;

    const debouncedHandleOnChange = useCallback(
        debounce(onChange ?? t, debounceWait)
        , [debounceWait, onChange],
    );

    const handleOnChange = (event) => {
        const { value: v } = event.target;

        setValue(v);

        if (onChange) {
            if (debounceWait) {
                debouncedHandleOnChange(event, v);
            } else {
                onChange(event, v);
            }
        }
    };

    const handleOnClear = (event) => {
        const v = '';

        setValue(v);

        if (onChange) {
            onChange(event, v);
        }
    };

    return (<TextField
        value={value}
        size="small"
        variant="outlined"
        placeholder="Rechercher"
        className={classes.searchInput}
        onChange={handleOnChange}
        InputProps={
            {
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small" disabled={!Boolean(value)} onClick={handleOnClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }
        }
    />);
};

export default SearchInput;
