import { object, date, ref, string } from 'yup';
import { default as AddressvalidationSchema } from '../address/validationSchema';
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';

export const validationSchema = object({
    name: string('Saisissez le nom').required('Le nom est requis'),
    email: string('Saisissez l\'email').nullable()
        .email('L\'adresse email n\'est pas valide'),
    phone: string('Saisissez le numéro de téléphone')
        .required('Le numéro de téléphone est requis')
        .test(
            'number',
            'Le numéro de téléphone n\'est pas valide',
            (value) => {
                try {
                    const parse = parsePhoneNumber(value, 'DZ');

                    return Boolean(parse?.isValid() && value.startsWith('+') && getCountryCallingCode('DZ') === value.trim().substring(1, 4));
                } catch {
                    return false;
                }
            },
        ),
    crn: string('Saisissez le numéro de téléphone')
        .required('Le numéro de registre de commerce est requis'),
    tin: string('Saisissez le numéro de téléphone')
        .required('Le numéro d\'identification fiscale est requis'),
    ta: string('Saisissez le numéro de téléphone')
        .required('L\'article d\'imposition est requis'),
    address: AddressvalidationSchema,
});

export const validationStateSchema = object({

    startDate: date().max(
        ref('endDate'),
        'La date doit être antérieure à la date de fin',
    ),
    endDate: date().min(
        ref('startDate'), 'La date doit être postérieure à la date de début',
    ),

});


