/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import Page from '../shared/page';
import { Formik } from 'formik';
import { useParams } from 'react-router';
import { toDateFromForms } from '../../services/date';
import useApi from './api';
import useToolbar from '../../hooks/toolbar';
import { initialValue, apiSettings, endPoint, viewEndPoint } from './constants';
import { Form } from './forms/edit';
import validationSchema from './validationSchema';
import { default as useGlobalStyles } from '../shared/theme';
import { useHistory } from 'react-router-dom';
import { ToolbarHeader } from './toolbar';
import { getDisplayName } from '../../components/product/services';
import { getLotEtAmount, getLotDiscountAmount, getLotVatAmount } from './services';

export const Edit = (props) => {
    const globalClasses = useGlobalStyles();
    const { id } = useParams();
    const toolBar = useToolbar();
    const api = useApi(apiSettings);
    const [state, setState] = useState(null);
    const history = useHistory();
    const formRef = useRef(null);

    const onSubmit = (data) => {
        api.save(toEditDataContract(data)).then((d) => {
            history.push(`${viewEndPoint}/${d.id}`);
        });
    };

    const onSave = () => {
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
        }));
    };

    const onCancel = (data) => {
        if (data?.id) {
            history.push(`${viewEndPoint}/${data?.id}`);
        } else {
            history.push(endPoint);
        }
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        status: data.status,
        thirdPartyId: data.thirdParty?.id,
        date: toDateFromForms(data.date),
        deliveryDate: toDateFromForms(data.deliveryDate),
        items: toEditDataContractListItem(data.lots),
    });

    const toEditDataContractLot = (data) => ({
        id: data.id,
        lotCode: data.code,
        quantity: data.initialQuantity,
        totalQuantity: data.currentQuantity,
        price: data.price,
        marginRate: data.marginRate,
        productId: data.product.id,
        productName: getDisplayName(data.product.brand, data.product.dosage, data.product.form, data.product.conditioning),
        productBrand: data.product.brand,
        extraCostHonorary: data.product.extraCostHonorary,
        publicPrice: data.product.publicPrice,
        vatRate: data.product.vatRate,
        discountType: parseInt(data.purchaseDiscountType, 10),
        discountRate: data.purchaseDiscountRate,
        finantialDiscountRate: data.purchaseBasicDiscountRate,
        saleDiscountType: parseInt(data.saleDiscountType, 10),
        saleDiscountRate: data.saleDiscountRate,
        saleFinantialDiscountRate: data.saleBasicDiscountRate,
        date: toDateFromForms(data.expirationDate),
        etAmount: data.etAmount,
        itAmount: data.itAmount,
        vatAmount: data.vatAmount,
        warehousePosition: data.warehousePosition,
    });

    const toEditDataContractListItem = (data) => {
        const lots = [];

        data.map((item, index) => {
            lots.push(toEditDataContractLot(item));
        });

        return lots;
    };

    const toEditDataContractLotField = (data) => ({
        id: data.id,
        code: data.lotCode,
        initialQuantity: data.quantity,
        currentQuantity: data.totalQuantity,
        price: data.price,
        marginRate: data.marginRate,
        product: data.product,
        purchaseDiscountType: data.discountType,
        purchaseDiscountRate: data.discountRate,
        purchaseBasicDiscountRate: data.finantialDiscountRate,
        saleDiscountType: data.saleDiscountType,
        saleDiscountRate: data.saleDiscountRate,
        saleBasicDiscountRate: data.saleFinantialDiscountRate,
        expirationDate: data.date,
        etAmount: getLotEtAmount(data.discountType, data.price * data.quantity, data.discountRate),
        vatAmount: getLotVatAmount(getLotEtAmount(data.discountType, data.price * data.quantity, data.discountRate), data.product?.vatRate),
        itAmount: getLotEtAmount(data.discountType, data.price * data.quantity, data.discountRate) + getLotVatAmount(getLotEtAmount(data.discountType, data.price * data.quantity, data.discountRate), data.product?.vatRate),
        discountAmount: getLotDiscountAmount(data.quantity * data.price, getLotEtAmount(data.discountType, data.price * data.quantity, data.discountRate)),
        amount: data.price * data.quantity,
        warehousePosition: data.warehousePosition,
    });

    const toEditDataContractLotsFields = (data) => {
        const lots = [];

        data.map((item, index) => {
            lots.push(toEditDataContractLotField(item));
        });

        return lots;
    };

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: {
                cancel: {
                    onClick: onCancel,
                },
                save: {
                    onClick: onSave,
                },
            },
        });
    };

    useEffect(() => {
        if (id) {
            api.get(id)
                .then((data) => {
                    setState({
                        ...data,
                        lots: toEditDataContractLotsFields(data.items),
                    });
                });
        } else {
            const newState = {
                ...initialValue,
            };

            setState(newState);
            history.replace();
        }
    }, []);

    useEffect(() => {
        handleToolbarDisplay(state);
    }, []);


    return (
        <div className={globalClasses.content}>
            {
                state && (
                    <Page title={state?.id ? `[${state.id}]Commande d'achat:modification ` : 'Commande d\'achat:création'}>
                        <Formik
                            render={(props) => <Form setRef={formRef} {...props} />}
                            initialValues={state}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        />
                    </Page>
                )
            }
        </div>
    );
};

