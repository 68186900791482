import { dateCounter } from '../../services/date';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [

    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Nom',
        field: 'name',
        sorting: true,
    },
    {
        label: 'Employé',
        field: 'employee.displayName',
        sorting: true,
    },
    {
        label: 'Téléphone',
        field: 'phone',
        sorting: false,
    },

    {
        label: 'Email',
        field: 'email',
        sorting: false,
    },
    {
        label: 'NRC',
        field: 'crn',
        sorting: false,
    },
    {
        label: 'Ville',
        field: 'address.city.name',
        sorting: false,
    },
    {
        label: 'Active',
        field: 'lastOrderDate',
        render: (index, column, row) => dateCounter(row.lastOrderDate),
    },
];
