import React from 'react';

import Grid from '@material-ui/core/Grid';

import { formatter } from '../../services/currency';
import { formatPercent } from '../../services/number';
import Property from '../shared/property';

const Abstract = (props) => {
    const {
        brand, name, form, dosage, conditioning,
        price, extraCostHonorary, vatRate, publicPrice, marginRate,
    } = props;

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
                <Property label="Nom commercial" value={brand} />
                <Property label="DCI" value={name} />
                <Property label="Forme" value={form} />
                <Property label="Dosage" value={dosage} />
                <Property label="Conditionnement" value={conditioning} />
                <Property label="SHP" value={formatter.format(extraCostHonorary)} />
                <Property label="TVA" value={formatPercent(vatRate)} />
                <Property label="Marge" value={formatPercent(marginRate)} />
                <Property label="PU" value={formatter.format(price)} />
                <Property label="PPA" value={formatter.format(publicPrice)} />
            </Grid>
        </Grid>
    );
};

export default Abstract;
