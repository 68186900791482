import React from 'react';

import StorefrontIcon from '@material-ui/icons/Storefront';

import { ToolbarHeader as SharedToolbarHeader } from '../shared/toolbar';
import { endPoint } from './constants';
import { displayName } from './services';
import useStyles from './theme';

export const ToolbarHeader = (props) => {
    const classes = useStyles();
    const { data } = props;

    return (
        <SharedToolbarHeader icon={<StorefrontIcon />} className={classes.avatar} data={data} displayName={displayName(data)} endPoint={endPoint} />
    );
};
