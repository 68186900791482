import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { GlobalContext } from '../../globalState';
import { loginPath } from './constants';

const AuthorizeRoute = ({ component: Component, ...rest }) => {
    let redirectUrl = `${loginPath}`;
    const returnUrl = encodeURI(window.location.href);
    const host = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

    if (!returnUrl.startsWith(`${host}/error`)) {
        redirectUrl += `?returnUrl=${encodeURI(window.location.href)}`;
    }

    const { state: { user } } = useContext(GlobalContext);

    return (
        <Route
            {...rest}
            render={
                (props) => {
                    if (user) {
                        return <Component {...rest} {...props} />;
                    }

                    return (<Redirect to={redirectUrl} />);
                }
            }
        />
    );
};

export default AuthorizeRoute;
