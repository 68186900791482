/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import { endPoint as orderEndPoint } from '../order/constants';
import { lotColumns as orderColumns, sort } from '../orderItem/columns';
import { Table } from '../shared/grid';
import Tabs from '../shared/tabs';
import useApi from './api';
import { editEndPoint, getOrdersApiEndPoint } from './constants';
import Info from './info';
import { ToolbarHeader } from './toolbar';

const Details = () => {
    const toolBar = useToolbar();
    const dialog = useDialog();
    const authorization = useAuthorization();
    const history = useHistory();
    const api = useApi();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez vous supprimer le lot ${data.id}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    const getActions = () => ({
        edit: {
            onClick: onEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: onDelete,
            hide: !authorization.isInRoles(roles.system),
        },
    });


   const handleRowClick = (event, key, row) => {
        const path = `${orderEndPoint}/${row.order.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
             history.push(path);
        }
    };


    useEffect(() => {
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    const GeneralContent = ({ data }) => <Info {...data} />;

    const OrdersContent = ({ data }) => <Table
        rowKey="id"
        sort={sort}
        endPoint={getOrdersApiEndPoint(data.id)}
        onRowClick={handleRowClick}
        columns={orderColumns}

    />;

    const tabs = [
        {
            label: 'Général',
            component: <GeneralContent data={state} />,
        },
        {
            label: 'Commandes',
            component: <OrdersContent data={state} />,
        },
    ];

    return (

        <>
            {
                state &&
                (<Page title = {`[${state.id}] Lot`}>
                    <>
                        <Tabs tabs={tabs} data={state} />
                    </>
                 </Page>
                )
            }
        </>

    );
};

export default Details;
