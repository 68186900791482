import React from 'react';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import PersonFormContent from '../../person/formContent';

const Form = (props) => {
    const {
        forwardedRef,
        values,
        values: { jobTitle },
        errors,
        touched,
        handleSubmit,
        handleChange,
        submitCount,
        setFieldValue,
    } = props;

    return (
        <form ref={forwardedRef} onSubmit={handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="jobTitle"
                        helperText={submitCount > 0 && errors.jobTitle ? errors.jobTitle : ''}
                        error={submitCount > 0 && Boolean(errors.jobTitle)}
                        label="Poste"
                        value={jobTitle}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} />
                <PersonFormContent {...{
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    values,
                    submitCount,
                }}
                />
            </Grid>
        </form>
    );
};

export default Form;
