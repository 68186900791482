import { makeStyles, createStyles } from '@material-ui/core/styles';

import Color from './color';

const useStyles = makeStyles(() => createStyles({
    avatar: {
        color: '#FFF',
        backgroundColor: Color[500],
    },
}));

export default useStyles;
