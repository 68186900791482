import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import useApi from '../../hooks/api';
import useToolbar from '../../hooks/toolbar';
import { status as orderSatus } from '../order/constants';
import { default as useGlobalStyles } from '../shared/theme';
import Wizard from '../shared/wizard';
import { initialValue, apiEndPoint, endPoint, viewEndPoint, apiSettings, status as deliverySatus } from './constants';
import { statusColumns } from './orderDelivery/constants';
import { ToolbarHeader } from './toolbar';

const Edit = () => {
    const globalClasses = useGlobalStyles();
    const toolBar = useToolbar();
    const history = useHistory();
    const api = useApi(apiSettings);
    const { id } = useParams();
    const [state, setState] = useState(null);
    const formRef = useRef(null);

    const onSave = () => {
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
        }));
    };

    const onCancel = (data) => {
        if (data?.id) {
            history.push(`${viewEndPoint}/${data?.id}`);
        } else {
            history.push(endPoint);
        }
    };

    const onSubmit = (data) => {
        save(data.rows);
    };

    const save = (data) => {
        api.put(`${apiEndPoint}/${state.id}/status`, data).then((response) => {
            history.push(`${viewEndPoint}/${response.data.id}`);
            handleToolbarDisplay(response.data);
        });
    };

    const formData = () => state.items.map((item) => ({
        id: item.id,
        orderId: item.order.id,
        thirdPartyName: item.order.thirdParty.name,
        thirdPartyAddress: item.order.thirdParty.address.city.name,
        orderDate: item.order.date,
        orderStatus: orderSatus.delivered,
        orderItAmount: item.order.itAmount,
        deliveryStatus: deliverySatus.done,
    }));

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: {
                cancel: {
                    onClick: onCancel,
                },
                save: {
                    onClick: onSave,
                },
            },
        });
    };

    useEffect(() => {
        if (id) {
            api.get(`${apiEndPoint}/${id}`)
                .then((response) => {
                    setState(response.data);
                    handleToolbarDisplay(response.data);
                });
        } else {
            setState(initialValue);
            handleToolbarDisplay(initialValue);
        }
    }, []);

    return (

        <>
            {
                state && (

                    <div className={globalClasses.content}>
                        <Wizard forwardedRef={formRef} data={formData()} columns={statusColumns} onSubmit={onSubmit} />
                    </div>

                )
            }
        </>

    );
};

export default Edit;
