
export const key = 'invoices';
export const endPoint = '/invoices';
export const viewEndPoint = `${endPoint}/view`;
export const apiEndPoint = '/invoices';

export const status = {
    canceled: -1,
    draft: 0,
    validated: 1,
    partiallyPaid: 2,
    paid: 3,
};

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La facture {0} a été créée avec succés',
        put: 'La facture {0} a été mise à jour avec succés',
        'delete': 'La facture {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
