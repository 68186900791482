import { default as useApiBase } from '../../hooks/api';
import useSession from '../../hooks/session';
import { apiEndPoint } from './constants';

export default function useApi () {
    const session = useSession();
    const api = useApiBase({
        loader: false,
        alert: false,
        refresh: false,
    });

    const signin = (data) => api.post(`${apiEndPoint}/signin`, data)
        .then((response) => {
            session.update(response.data);
            const params = new URLSearchParams(window.location.search);

            window.location.replace(params.get('returnUrl') ?? `${window.location.origin}`);
        })
        .catch((error) => {
            if (error.response.status === 400) {
                throw new Error('Nom d\'utilisateur ou mot de passe incorrect');
            } else {
                throw new Error('Une erreur est survenue');
            }
        });

    const signout = () => api.post(`${apiEndPoint}/signout`)
        .then(() => {
            session.clear();
        });

    return {
        signin,
        signout,
    };
}
