import React from 'react';
import Page from '../../shared/page';
import 'moment/locale/fr';


const Index = () => (
    <>

        <Page title = "Stock">
        </Page>

    </>
);

export default Index;
