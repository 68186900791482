import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { currency } from '../../../services/currency';
import { toDateForForms } from '../../../services/date';
import { default as OrganizationLookup } from '../../organization/lookup';
import { getStatusKeyValues, getModeKeyValues } from '../services';

const Form = (props) => {
    const [initialized, setInitialized] = useState(false);

    const {
        forwardedRef,
        values: { createDate, mode, date, status, amount, reference, note, thirdParty, beneficiary },
        errors,
        touched,
        submitCount,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = props;

    useEffect(() => {
        if (!initialized) {
            setFieldValue('date', toDateForForms(date));
            setInitialized(true);
        }
    }, []);

    return (
        <form ref={forwardedRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <OrganizationLookup
                        disabled={Boolean(createDate)}
                        required
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="thirdParty"
                        value={thirdParty}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="date"
                        type="date"
                        helperText={submitCount > 0 && errors.date ? errors.date : ''}
                        error={submitCount > 0 && Boolean(errors.date)}
                        label="Date"
                        defaultValue={toDateForForms(date)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OrganizationLookup
                        disabled={Boolean(createDate)}
                        required
                        label="Bénéficiaire"
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="beneficiary"
                        value={beneficiary}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={4}>
                    <FormControl required variant="outlined" size="small" fullWidth>
                        <InputLabel id="modes-select-label">Type</InputLabel>
                        <Select
                            labelId="modes-select-label"
                            id="modes-select"
                            name="mode"
                            label="Mode"
                            value={mode}
                            onChange={
                                (e) => {
                                    handleChange(e);
                                    handleBlur(e);
                                }
                            }
                        >
                            {getModeKeyValues().map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="amount"
                        type="number"
                        helperText={submitCount > 0 && errors.amount ? errors.amount : ''}
                        error={submitCount > 0 && Boolean(errors.amount)}
                        label="Montant"
                        value={amount}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={4}>
                    <FormControl required variant="outlined" size="small" fullWidth>
                        <InputLabel id="status-select-label">Statut</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            name="status"
                            label="Statut"
                            value={status}
                            onChange={
                                (e) => {
                                    handleChange(e);
                                    handleBlur(e);
                                }
                            }
                        >
                            {getStatusKeyValues().map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        name="reference"
                        helperText={submitCount > 0 && errors.reference ? errors.reference : ''}
                        error={submitCount > 0 && Boolean(errors.reference)}
                        label="Référence"
                        value={reference}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={8}>
                    <TextField
                        multiline
                        rows={4}
                        rowsMax={4}
                        name="note"
                        helperText={submitCount > 0 && errors.note ? errors.note : ''}
                        error={submitCount > 0 && Boolean(errors.note)}
                        label="Note"
                        value={note}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
