/* eslint-disable max-len */
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import LabelIcon from '@material-ui/icons/Label';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuthorization from '../../hooks/authorization';
import { roles } from '../authorization/constants';
import useApi from './api';
import { default as useInvoiceApi } from '../invoice/api';
import { status as orderStatus } from './constants';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Action = (props) => {
    const classes = useStyles();
    const { title, icon, onClick } = props;

    return (
        <ListItem button onClick={onClick}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const Print = (props) => {
    const { data } = props;
    const authorization = useAuthorization();
    const api = useApi();
    const invoiceApi = useInvoiceApi();

    const deliveryDownload = () => {
        api.deliveryDownload(data.id);
    };

    const prepareDownload = () => {
        api.prepareDownload(data.id);
    };

    const detailedLabelsDownload = () => {
        api.detailedLabelsDownload(data.id);
    };

    const labelsDownload = () => {
        api.labelsDownload(data.id);
    };

    const invoiceDownload = (id) => {
        invoiceApi.download(id);
    };

    return (
        <List>
            {
                // eslint-disable-next-line max-len
                data?.status >= orderStatus.confirmed >= authorization.isInRoles(roles.system, roles.administrator, roles.supervisor, roles.operator, roles.preparer) &&
                <Action icon={<PlaylistAddCheckIcon />} title="Bon de préparation" onClick={() => prepareDownload()} />
            }
            <Action icon={<LabelIcon />} title="Étiquettes" onClick={() => labelsDownload()} />

            <Action icon={<LabelIcon />} title="Étiquettes complètes" onClick={() => detailedLabelsDownload()} />
            {
                data?.status >= orderStatus.confirmed && authorization.isInRoles(roles.system, roles.administrator, roles.supervisor, roles.operator, roles.preparer) && (
                    <>
                        <Action icon={<DescriptionIcon />} title="Bon de livraison" onClick={() => deliveryDownload()} />
                        {
                            data.invoice &&
                            <Action icon={<ReceiptIcon />} title={`Facture (${data.invoice.id})`} onClick={() => invoiceDownload(data.invoice.id)} />
                        }
                    </>
                )
            }
        </List>
    );
};

export default Print;
