import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { currency } from '../../services/currency';
import { default as LotAbstract } from '../lot/abstract';
import { default as LotLookup } from '../lot/lookup';
import { default as ProductAbstract } from '../product/abstract';
import { getDiscountTypeLabel, getBasicDiscountRate, getMarginRate, getTotalQuantity } from '../shared/services';

const Form = (props) => {
    const {
        values: { price, discountType, discountRate, quantity, totalQuantity, lot },
        errors,
        touched,
        submitCount,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = props;

    const marginRate = lot && price ? getMarginRate(lot?.product?.publicPrice, price, lot?.product?.vatRate, lot?.product?.extraCostHonorary) : 0;
    const basicDiscountRate = getBasicDiscountRate(discountRate ?? 0);

    const onChange = (name, value) => {
        setFieldValue(name, value?.id);
        setFieldValue('lot', value);
        setFieldValue('price', value?.price ?? 0);
        setFieldValue('discountType', value?.saleDiscountType ?? 0);
        setFieldValue('discountRate', value?.saleDiscountRate ?? 0);
    };

    const onQuantityChange = (event) => {
        setFieldValue('totalQuantity', getTotalQuantity(discountType, discountRate, parseInt(event.target.value, 10)));
        handleChange(event);
    };

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LotLookup
                        required
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="lot"
                        value={lot}
                        onChange={onChange}
                    />
                </Grid>
                {
                    lot && lot.product && (
                        <Grid item xs={6}>
                            <ProductAbstract {...lot.product} />
                        </Grid>
                    )
                }
                {
                    lot && (
                        <Grid item xs={6}>
                            <LotAbstract {...lot} />
                        </Grid>
                    )
                }
                <Grid item xs={6}>
                    <TextField
                        required
                        name="price"
                        type="number"
                        helperText={submitCount > 0 && errors.price ? errors.price : ''}
                        error={submitCount > 0 && Boolean(errors.price)}
                        label="PU"
                        value={price}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        name="marginRate"
                        type="number"
                        label="Marge"
                        value={marginRate}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl size="small" error={submitCount > 0 && Boolean(errors.discountType)} required>
                        <FormLabel component="legend" size="small">Remise</FormLabel>
                        <RadioGroup
                            name="discountType"
                            row
                            value={String(discountType)}
                            error={submitCount > 0 && Boolean(errors.discountType)}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="0" control={<Radio size="small" />} label={getDiscountTypeLabel(0)} />
                            <FormControlLabel value="1" control={<Radio size="small" />} label={getDiscountTypeLabel(1)} />
                        </RadioGroup>
                        <FormHelperText>{submitCount > 0 && errors.discountType ? errors.discountType : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="discountRate"
                        type="number"
                        helperText={submitCount > 0 && errors.discountRate ? errors.discountRate : ''}
                        error={submitCount > 0 && Boolean(errors.discountRate)}
                        label="Remise UG"
                        value={discountRate}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        name="quantity"
                        type="number"
                        helperText={submitCount > 0 && errors.quantity ? errors.quantity : ''}
                        error={submitCount > 0 && Boolean(errors.quantity)}
                        label="Quantité"
                        value={quantity}
                        onChange={onQuantityChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        name="basicDiscountRate"
                        type="number"
                        label="Remise financière"
                        value={basicDiscountRate}
                        variant="outlined"
                        size="small"
                        InputProps={
                            {
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        name="totalQuantity"
                        type="number"
                        helperText={submitCount > 0 && (errors.totalQuantity || errors.totalQuantity) ? errors.totalQuantity : ''}
                        error={submitCount > 0 && Boolean(errors.totalQuantity)}
                        label="Quantité total"
                        value={totalQuantity}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
