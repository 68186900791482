import React from 'react';

import { ToolbarHeader as SharedToolbarHeader } from '../shared/toolbar';
import Icon from './icon';
import { endPoint } from './constants';
import useStyles from './theme';

export const ToolbarHeader = (props) => {
    const classes = useStyles();
    const { data } = props;

    return (
        <SharedToolbarHeader icon={<Icon />} displayName={data?.id} className={classes.avatar} data={data} endPoint={endPoint} />
    );
};
