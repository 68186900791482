import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { getStatusLabel, getModeLabel } from './services';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Référence',
        field: 'reference',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'Client',
        field: 'thirdParty.name',
        sorting: true,
    },
    {
        label: 'Mode',
        field: 'mode',
        sorting: true,
        render: (index, column, row) => getModeLabel(row.mode),
    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusLabel(row.status),
    },
    {
        label: 'Montant',
        field: 'amount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.amount),
    },
];
