import { initialValue as personInitialValue } from '../person/constants';

export const endPoint = '/employees';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/employees';
export const initialValue = {
    ...personInitialValue,
    jobTitle: '',
};

export const getOrderApiEndPoint = (id) => `${apiEndPoint}/${id}/orders`;

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'L\'employé {0} a été créé avec succés',
        put: 'L\'employé {0} a été mis à jour avec succés',
        'delete': 'L\'employé {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
