import { status } from './constants';
import { mode } from '../payment/constants';
import { getModeLabel } from '../payment/services';

export const getStatusTypeLabel = (value) => {
    switch (value) {
        case status.returned:
            return 'Retournée';
        case status.canceled:
            return 'Annulée';
        case status.draft:
            return 'Brouillon';
        case status.confirmed:
            return 'Confirmée';
        case status.preparation:
            return 'En préparation';
        case status.ready:
            return 'Prête';
        case status.shipped:
            return 'Expédiée';
        case status.delivered:
            return 'Livrée';
        default:
            return 'Inconnu';
    }
};

export const getStatusKeyValues = () => {
    const result = [];

    result.push({
        key: status.returned,
        value: getStatusTypeLabel(status.returned),
    });
    result.push({
        key: status.canceled,
        value: getStatusTypeLabel(status.canceled),
    });
    result.push({
        key: status.draft,
        value: getStatusTypeLabel(status.draft),
    });
    result.push({
        key: status.confirmed,
        value: getStatusTypeLabel(status.confirmed),
    });
    result.push({
        key: status.preparation,
        value: getStatusTypeLabel(status.preparation),
    });
    result.push({
        key: status.ready,
        value: getStatusTypeLabel(status.ready),
    });
    result.push({
        key: status.shipped,
        value: getStatusTypeLabel(status.shipped),
    });
    result.push({
        key: status.delivered,
        value: getStatusTypeLabel(status.delivered),
    });

    return result;
};

export const getPaymentsModeKeyValues = () => {
    const result = [];

    result.push({
        key: mode.cash,
        value: getModeLabel(mode.cash),
    });
    result.push({
        key: mode.check,
        value: getModeLabel(mode.check),
    });
    result.push({
        key: mode.transfer,
        value: getModeLabel(mode.transfer),
    });
    result.push({
        key: mode.credit,
        value: getModeLabel(mode.credit),
    });

    return result;
};
