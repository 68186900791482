import { useSnackbar } from 'notistack';


const useSignalR = () => {
    const { enqueueSnackbar } = useSnackbar();
    const handle = (data, type) => {
        switch (data?.type) {
            case 'Alert':
                dispatch(data.message, data.level?.toLowerCase());
                break;
            default:
                dispatch(data, type);
                break;
        }
    };

    const dispatch = (message, type) => {
        enqueueSnackbar(message, {
            variant: type ?? 'default',
        });
    };

    return {
        handle,
    };
};

export default useSignalR;
