import { formatUtcToLocalDate } from '../../services/date';
import { getStatusTypeLabel } from '../order/services';
import { getStatusTypeLabel as getInvoiceStatusTypeLabel } from '../invoice/services';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const lotSort = {
    field: 'expirationDate',
    direction: 'asc',
};

export const orderColumns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
        filtering: false,
    },
    {
        label: 'Département',
        field: 'thirdParty.address.city.department.name',
        sorting: true,
    },
    {
        label: 'Client',
        field: 'thirdParty.name',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'date',
        type: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.status),
    },
    {
        label: 'Statut de la facture',
        field: 'lastInvoice.status',
        sorting: true,
        render: (index, column, row) => getInvoiceStatusTypeLabel(row.lastInvoice?.status),
    },
    {
        label: 'Vendeur',
        field: 'seller.name',
        sorting: true,
    },
];

export const lotColumns = [
    {
        label: 'N°',
        field: 'code',
        sorting: false,
    },
    {
        label: 'Nom',
        field: 'product.displayName',
        sorting: true,
        width: '30%',
    },
    {
        label: 'Date de péremp.',
        defaultSort: 'asc',
        field: 'expirationDate',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.expirationDate),
    },
    {
        label: 'Quantité',
        field: 'currentQuantity',
        sorting: true,
        align: 'right',
    },
];
