import React from 'react';

import { green, deepOrange } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export const EmailStatus = (props) => {
    const { data, fontSize } = props;

    return (
        <>
            {
                data.emailConfirmed && (
                    <CheckCircleOutlineIcon
                        fontSize={fontSize}
                        style={
                            {
                                color: green[500],
                            }
                        }
                    />
                )
            }
            {
                !data.emailConfirmed && (
                    <ErrorOutlineIcon
                        fontSize={fontSize}
                        style={
                            {
                                color: deepOrange[500],
                            }
                        }
                    />
                )
            }
        </>
    );
};

export const Status = (props) => {
    const { data, fontSize } = props;

    return (
        <>
            {
                data.lockoutEnd &&
                <BlockIcon fontSize={fontSize} color="secondary" />
            }
        </>
    );
};
