import { object, number, date } from 'yup';

const validationSchema = object({
    date: date().typeError('La date n\'est pas valide')
        .required('La date est requise'),
    deliveryManId: number().nullable()
        .typeError('Le livreur n\'est pas valide'),
});

export default validationSchema;
