import { useContext } from 'react';

import { GlobalContext } from '../globalState';

const useToolbar = () => {
    const { dispatch } = useContext(GlobalContext);

    const show = (toolbar) => {
        dispatch({
            toolbar: {
                ...toolbar,
                open: true,
            },
        });
    };

    const hide = () => {
        dispatch({
            toolbar: null,
        });
    };

    return {
        show,
        hide,
    };
};

export default useToolbar;
