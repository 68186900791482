import { makeStyles, createStyles } from '@material-ui/core/styles';

export const drawerWidth = 240;
export const closedDrawerWidth = 60;

const useStyles = makeStyles((theme) => createStyles({
    content: {
        padding: theme.spacing(2),
    },
    flexGrow: {
        flexGrow: 1,
    },
    detailsTable: {
        '& th': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            minWidth: '220px',
            width: '220px',
            maxWidth: '220px',
        },
    },
    importantTableCell: {
        fontWeight: 'bold',
        fontSize: '0.925rem',
    },
}));

export default useStyles;
