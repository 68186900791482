import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '../report/icon';
import BusinessCenterIcon from '../organization/icon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '../dashboard/icon';
import LocalPharmacyIcon from '../product/icon';
import ShoppingBasketIcon from '../purchases/icon';
import MoneyIcon from '../payment/icon';
import StorefrontIcon from '../lot/icon';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import { useUp } from '../../hooks/screen';
import logo from '../../images/logo-45.png';
import { roles } from '../authorization/constants';
import { drawerWidth, closedDrawerWidth } from './theme';
import GroupIcon from '../employee/icon';
import LocalGroceryStoreIcon from '../order/icon';
import ReceiptIcon from '../invoice/icon';
import LocalShippingIcon from '../delivery/icon';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: closedDrawerWidth,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    hide: {
        display: 'none',
    },
    logo: {
        flexGrow: 1,
    },
}));

const ListItemLink = (props) => {
    const { icon, className, primary, to } = props;
    const { dispatch } = useContext(GlobalContext);
    const upMd = useUp('md');
    const history = useHistory();

    const handleClick = (event) => {
        if (!upMd) {
            dispatch({
                drawerIsOpen: false,
            });
        }

        if (event?.ctrlKey) {
            const win = window.open(to, '_blank');

            win.focus();
        } else {
            history.push(to);
        }
    };

    return (
        <li>
            <ListItem button onClick={handleClick} className={className}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
};

export default function ClippedDrawer () {
    const classes = useStyles();
    const upMd = useUp('md');
    const { state: { drawerIsOpen, user }, dispatch } = useContext(GlobalContext);
    const authorization = useAuthorization();

    const handleToogleDrawer = () => {
        dispatch({
            drawerIsOpen: !drawerIsOpen,
        });
    };

    const handleCloseDrawer = () => {
        dispatch({
            drawerIsOpen: false,
        });
    };

    return (
        <Drawer
            variant={upMd ? 'permanent' : 'temporary'}
            anchor="left"
            open={drawerIsOpen}
            className={
                clsx(classes.drawer, {
                    [classes.drawerOpen]: drawerIsOpen,
                    [classes.drawerClose]: !drawerIsOpen,
                })
            }
            classes={
                {
                    paper: clsx({
                        [classes.drawerOpen]: drawerIsOpen,
                        [classes.drawerClose]: !drawerIsOpen,
                    }),
                }
            }
            onClose={handleCloseDrawer}
        >
            <div className={classes.toolbar}>
                <Box className={classes.logo}>
                    {
                        drawerIsOpen &&
                        <img src={logo} alt="logo" height="45" />
                    }
                </Box>
                <IconButton onClick={handleToogleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <div>
                <List>
                    <ListItemLink to="/" primary="Home" icon={<DashboardIcon />} />
                    <Divider />
                    <ListItemLink to="/orders" primary="Commandes" icon={<LocalGroceryStoreIcon />} />
                    <Divider />
                    {
                        authorization.isInRoles(roles.system, roles.administrator) && (
                            <>
                                <ListItemLink to="/purchases" primary="Commandes d'achat" icon={<ShoppingBasketIcon />} />
                                <Divider />
                            </>
                        )
                    }
                    {
                        !authorization.isInRoles(roles.preparer) && (
                            <>
                                <ListItemLink to="/invoices" primary="Factures" icon={<ReceiptIcon />} />
                                <Divider />
                            </>
                        )
                    }

                    {
                        authorization.isInRoles(roles.system, roles.administrator) && (
                            <>
                                <ListItemLink to="/payments" primary="Règlements" icon={<MoneyIcon />} />
                                <Divider />
                                <ListItemLink to="/deliveries" primary="Livraisons" icon={<LocalShippingIcon />} />
                                <Divider />
                            </>
                        )
                    }
                    <ListItemLink to="/products" primary="Références" icon={<LocalPharmacyIcon />} />
                    <Divider />
                    <ListItemLink to="/lots" primary="Stock" icon={<StorefrontIcon />} />
                    <Divider />
                    {
                        !authorization.isInRoles(roles.preparer) && (
                            <>
                                <ListItemLink to="/organizations" primary="Sociétés" icon={<BusinessCenterIcon />} />
                                <Divider />
                            </>
                        )
                    }
                    {
                        authorization.isInRoles(roles.system, roles.administrator, roles.supervisor) && (
                            <>
                                <ListItemLink to="/employees" primary="Employés" icon={<GroupIcon />} />
                                <Divider />
                            </>
                        )
                    }
                    {
                        (authorization.isInRoles(roles.system, roles.supervisor) || user?.employee) && (
                            <>
                                <ListItemLink to="/reports" primary="Rapports" icon={<AssessmentIcon />} />
                                <Divider />
                            </>
                        )
                    }
                </List>
            </div>
        </Drawer>
    );
}
