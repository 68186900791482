import { object, string, number } from 'yup';

const validationSchema = object({
    brand: string('Saisissez le nom commercial').required('Le nom commercial est requis'),
    name: string('Saisissez la DCI').required('La DCI est requise'),
    form: string('Saisissez la forme').required('La forme requise'),
    dosage: string('Saisissez le dosage').required('Le dosage est requis'),
    conditioning: string('Saisissez le conditionnement').required('Le conditionnement est requis'),
    price: number().typeError('Le PU n\'est pas valide')
        .min(1, 'Le PU n\'est pas valide')
        .required('Le PU est requis'),
    marginRate: number().typeError('La marge est requise')
        .min(0, 'Le marge n\'est pas valide')
        .required('Le marge est requise'),
    extraCostHonorary: number().typeError('Le SHP n\'est pas valide')
        .min(0, 'Le SHP n\'est pas valide')
        .required('Le SHP est requis'),
    vatRate: number().typeError('La TVA n\'est pas valide')
        .min(0, 'La TVA n\'est pas valide')
        .required('La TVA est requise'),
    publicPrice: number().typeError('Le PPA n\'est pas valide')
        .min(1, 'Le PPA n\'est pas valide')
        .required('Le PPA est requis'),
});

export default validationSchema;
