import React from 'react';

import Grid from '@material-ui/core/Grid';

import { formatter } from '../../services/currency';
import Property from '../shared/property';
import Info from './info';

const Summary = (props) => {
    const { discountAmount, etAmount, vatAmount, itAmount } = props;

    return (
        <Info {...props}>
            <Grid item xs={12} sm={6} >
                <Property label="Montant HT" color="default" value={formatter.format(etAmount)} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="Montant TTC" value={formatter.format(itAmount)} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <Property label="Montant TVA" color="default" value={formatter.format(vatAmount)} />
            </Grid>
            <Grid item xs={12} sm={6} >
                {
                    discountAmount > 0 &&
                    <Property label="Montant de la remise" color="secondary" value={formatter.format(discountAmount)} />
                }
            </Grid>
        </Info>
    );
};

export default Summary;
