import React from 'react';

import Link from '@material-ui/core/Link';

export const EmailAddress = (props) => {
    const { data } = props;

    if (!data) {
        return null;
    }

    return (
        <Link title={data} href={`mailto:${data}`}>
            {data}
        </Link>
    );
};

export default EmailAddress;
