import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import useApi from './api';
import useDialog from '../../../hooks/dialog';
import { toShipApiEndPoint } from '../../order/constants';
import { Table } from '../../shared/grid';
import { orderColumns, sortOrder } from './columns';

const Add = (props) => {
    const { data } = props;
    const dialog = useDialog();
    const api = useApi();
    const [state, setState] = useState(null);

    const hasItems = () => state?.all || state?.selected.length > 0 || state?.unselected.length > 0;

    const handleSelectionChange = (selection, seachText) => {
        setState({
            ...selection,
            seachText,
        });
    };

    const validate = () => {
        api.save(data.id, {
            all: state.all,
            selected: state.selected,
            unselected: state.unselected,
            search: state.seachText?.trim(),
        }).finally(() => {
            dialog.hide();
        });
    };

    const cancel = () => {
        dialog.hide();
    };

    return (
        <>
            <Table
                selection
                rowKey="id"
                endPoint={toShipApiEndPoint}
                columns={orderColumns}
                sort={sortOrder}
                onSelectionChange={handleSelectionChange}
                rowsPerPage={10}
            />
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={cancel}
                    color="default"
                >
                  Annuler
                </Button>
                <Button
                    disabled={!hasItems()}
                    variant="outlined"
                    onClick={
                        () => {
                            validate();
                        }
                    }
                    color="primary"
                >
                  Valider
                </Button>
            </DialogActions>
        </>
    );
};

export default Add;
