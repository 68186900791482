import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Line } from 'react-chartjs-2';

import 'moment/locale/fr';
import useAuthorization from '../../../../hooks/authorization';
import { formatter } from '../../../../services/currency';
import { timeZone, getUtcStartToday, dateDisplayFormat } from '../../../../services/date';
import { roles } from '../../../authorization/constants';
import Indicator from '../../../shared/indicator';
import { colors } from '../constants';
import { Indicator as IndicatorLoader } from '../loaders/indicator';
import { Line as LineLoader } from '../loaders/line';
import { format, getDataFromDate, getTurnover, getEmployeeTurnover, getPositive, getEmployeePositive, getProfit, getEmployeeProfit } from '../services';
import { getAmounts } from './services';

export const Amounts = ({ data, employee, isLoading }) => {
    const authorization = useAuthorization();

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <LineLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Line
                            height={200}
                            data={getAmounts(data, authorization.isInRoles(roles.system, roles.supervisor), authorization.isInRoles(roles.system), employee)}
                            options={
                                {
                                    title: {
                                        text: 'Chiffres',
                                        display: true,
                                    },
                                    tooltips: {
                                        callbacks: {
                                            label: format,
                                        },
                                    },
                                    maintainAspectRatio: false,
                                }
                            }
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export const TodayAmounts = ({ data, isLoading }) => {
    const authorization = useAuthorization();
    const date = getUtcStartToday();
    const positive = getPositive(getDataFromDate(data, date));
    const turnover = getTurnover(getDataFromDate(data, date));
    const profit = getProfit(getDataFromDate(data, date));

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <IndicatorLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Indicator
                            header="Chiffre"
                            subheader={`Le ${dateDisplayFormat(date.tz(timeZone))}`}
                            value={formatter.format(positive)}
                            subvalue1={formatter.format(turnover)}
                            subvalue2={authorization.isInRoles(roles.system) ? profit > 0 ? `+${formatter.format(profit)}` : formatter.format(profit) : null}
                            valueColor={colors.global.positive}
                            subvalueColor1={colors.global.turnover}
                            subvalueColor2={authorization.isInRoles(roles.system) ? (profit > 0 ? colors.global.profit : colors.global.negative) : null}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export const TodayEmployeeAmounts = ({ data, employee, isLoading }) => {
    const authorization = useAuthorization();
    const date = getUtcStartToday();
    const positive = getEmployeePositive(getDataFromDate(data, date), employee);
    const turnover = getEmployeeTurnover(getDataFromDate(data, date), employee);
    const profit = getEmployeeProfit(getDataFromDate(data, date), employee);

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <IndicatorLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Indicator
                            header={employee?.name}
                            subheader={`Le ${dateDisplayFormat(date.tz(timeZone))}`}
                            value={formatter.format(positive)}
                            subvalue1={formatter.format(turnover)}
                            subvalue2={authorization.isInRoles(roles.system) ? profit > 0 ? `+${formatter.format(profit)}` : formatter.format(profit) : null}
                            valueColor={colors.employee.positive}
                            subvalueColor1={colors.employee.turnover}
                            subvalueColor2={authorization.isInRoles(roles.system) ? (profit > 0 ? colors.employee.profit : colors.employee.negative) : null}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export default Amounts;
