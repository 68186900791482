import React from 'react';
import { orange, green } from '@material-ui/core/colors';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { formatter } from '../../services/currency';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DisplayContent } from '../shared/entityLink';


const useStyles = makeStyles((theme) => createStyles({
    container: {
        padding: '6px 6px 6px 0',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2) !important',
    },
    label: {
        minWidth: 160,
        width: 160,
        maxWidth: 160,
        overflow: 'hidden',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
        padding: '0px 4px 0px 0px',
    },
    value: {
        lineHeight: 0,
        overflow: 'hidden',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
    },
}));

const Property = (props) => {
    const { labelVariant, label, valueVariant, value, labelColor, valueColor, children } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Box display="flex">
                <Box className={classes.label}>
                    <Tooltip title={label}>
                        <Typography
                            variant={labelVariant ?? 'overline'}
                            color={labelColor ?? 'default'}
                            style={
                                {
                                    fontSize: '0.70rem',
                                    lineHeight: '1.5',
                                }
                            }
                        >
                            {label}
                        </Typography>
                    </Tooltip>
                </Box>
                <Box className={classes.value}>
                    <Typography
                        variant={valueVariant ?? 'body2'}
                        color={valueColor ?? 'default'}
                        style={
                            {
                                lineHeight: '1.8',
                                visibility: value ? 'inherit' : 'hidden',
                                display: !value && children ? 'none' : 'inherit',
                            }
                        }
                    >
                        {value && value !== '' ? value : 'Aucune valeur'}
                    </Typography>
                </Box>
                {children}
            </Box>
        </div>

    );
};

export default Property;

export const Balance = (props) => {
    const { data } = props;

    return (
        <DisplayContent
            label={formatter.format(data)}
            avatar={
                (data === 0)
                    ? <ArrowUpwardIcon color="action"/> :
                    data > 0
                        ? <ArrowUpwardIcon style={
                            {
                                color: orange[500],
                            }
                        }
                        />
                        : <ArrowDownwardIcon color="secondary"/>

            }
        />
    );
};

export const Profit = (props) => {
    const { data } = props;


    return (
        <DisplayContent
            label={formatter.format(data)}
            avatar={
                data > 0
                    ? <ArrowUpwardIcon style={
                        {
                            color: green[500],
                        }
                    }
                    />
                    : <ArrowDownwardIcon color="secondary"/>

            }
        />
    );
};

