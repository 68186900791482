/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PrintIcon from '@material-ui/icons/Print';
import NoteIcon from '@material-ui/icons/ChatBubble';
import { useParams } from 'react-router';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { default as useOrderItemApi } from '../orderItem/api';
import useApi from './api';
import Tabs from '../shared/tabs';
import { status as orderStatus, getInvoicesApiEndPoint } from './constants';
import { apiSettings as orderItemApiSettings } from '../orderItem/constants';
import { columns as orderItemsColumns, sort } from '../orderItem/columns';
import { viewEndPoint as invoiceViewEndPoint } from '../invoice/constants';
import { columns as invoiceColumns } from '../invoice/columns';
import { roles } from '../authorization/constants';
import Info from './info';
import Print from './print';
import BottomAppBar from '../shared/bottomAppBar';
import Property from '../shared/property';
import { formatter } from '../../services/currency';
import { default as useGlobalStyles } from '../shared/theme';
import useStyles from './theme';
import Page from '../shared/page';
import { ToolbarHeader } from './toolbar';
import { Table, useStyles as useTableStyles } from '../shared/grid';
import { useHistory } from 'react-router-dom';

const Details = () => {
    const globalClasses = useGlobalStyles();
    const tableClasses = useTableStyles();
    const classes = useStyles();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const api = useApi();
    const orderItemApi = useOrderItemApi(orderItemApiSettings);
    const authorization = useAuthorization();
    const { id } = useParams();
    const { state: { refresh, user } } = useContext(GlobalContext);
    const [state, setState] = useState(null);
    const history = useHistory();


    const handleRowClick = (event, key, row) => {
        const path = `${invoiceViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const handleAddItem = (data) => {
        dialog.addOrderItem(data);
    };

    const handleDisplayItem = (event, index, row) => {
        dialog.displayOrderItem(row);
    };

    const handleDeleteItem = (data) => {
        dialog.confirm(`Voulez-vous supprimer l'article ${data.id}`, () => {
            handleDeleteItemConfirmed(data);
        });
    };

    const handleDeleteItemConfirmed = (data) => {
        orderItemApi.deleTe(state.id, data.id);
    };

    const handleEdit = (data) => {
        dialog.updateOrder(data);
    };

    const handleEditNote = (data) => {
        dialog.updateOrderNote(data);
    };

    const handleDelete = (data) => {
        dialog.confirm(`Voulez-vous supprimer la commande ${data.id}`, () => {
            hendleDeleteConfirmed(data);
        });
    };

    const hendleDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const handleConfirm = (data) => {
        dialog.confirm(`Voulez-vous confirmer la commande ${data.id}`, () => {
            handleStatusChangeConfirmed(data, orderStatus.confirmed);
        });
    };

    const handlePreparation = (data) => {
        dialog.confirm(`Confirmez-vous que la prépation de la commande ${data.id} est en cours`, () => {
            handleStatusChangeConfirmed(data, orderStatus.preparation);
        });
    };

    const handleReady = (data) => {
        dialog.updateOrderStatusReady(data);
    };

    const handleStatusChangeConfirmed = (data, status) => {
        api.updateStatus(data, status);
    };

    const handleDownload = (data) => {
        dialog.popup(<Print data={data} />);
    };

    const isOwner = (data) => data?.seller && user?.employee && data?.seller.id === user?.employee.id;

   const getItemActions = (data) => {
        const result = [];

        if (authorization.isInRoles(roles.system, roles.administrator) || isOwner(data)) {
            result.push({
                icon: <DeleteOutline />,
                color: 'secondary',
                tooltip: 'Supprimer',
                onClick: (event, data) => {
                    handleDeleteItem(data);
                },
                hide: state?.status !== orderStatus.draft,
            });
        }

        return result;
    };

    const getActions = (data) => ({
        edit: {
            onClick: handleEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: handleDelete,
            hide: true,
        },
        customs: [
            {
                icon: <PlaylistAddIcon fontSize="small" />,
                label: 'Ajouter un article',
                onClick: handleAddItem,
                hide: data.status !== orderStatus.draft || (!authorization.isInRoles(roles.system, roles.administrator) && !isOwner(data)),
            },
            {
                icon: <NoteIcon fontSize="small" />,
                label: 'Note',
                onClick: handleEditNote,
                hide: data.status !== orderStatus.draft || authorization.isInRoles(roles.preparer),
            },
            {
                icon: <PrintIcon fontSize="small" />,
                label: 'Imprimer',
                onClick: handleDownload,
                hide: !authorization.isInRoles(roles.system, roles.administrator, roles.preparer) && !isOwner(data),
            },
            {
                icon: <CheckIcon fontSize="small" />,
                label: 'Confirmer',
                onClick: handleConfirm,
                hide: data.status !== orderStatus.draft || (!authorization.isInRoles(roles.system, roles.administrator) && !isOwner(data)),
            },
            {
                icon: <MoveToInboxIcon fontSize="small" />,
                label: 'Préparation en cours',
                onClick: handlePreparation,
                hide: data.status !== orderStatus.confirmed || !authorization.isInRoles(roles.system, roles.administrator, roles.preparer),
            },
            {
                icon: <AssignmentTurnedInIcon fontSize="small" />,
                label: 'Préparation terminée',
                onClick: handleReady,
                hide: data.status !== orderStatus.preparation || !authorization.isInRoles(roles.system, roles.administrator, roles.preparer),
            },
        ],
    });

    const handleGetRowClasses = (key, row) => {
        const result = [];

        if (!row.lot.inCatalog) {
            result.push(tableClasses.info);
        } else if (row.discountRate > row.lot.purchaseDiscountRate) {
            result.push(tableClasses.error);
        } else if (row.discountRate >= row.lot.saleDiscountRate) {
            result.push(tableClasses.warning);
        }

        return result;
    };


    const GeneralContent = ({ data }) => <div>
        <Info {...data} />
        <Table
           rowKey="id"
            sort={sort}
            data={state.items}
            actions={getItemActions(state)}
            onRowClick={handleDisplayItem}
            columns={orderItemsColumns}
            getRowClasses={handleGetRowClasses}
            rowsPerPage={10}
        />
                                         </div>;

     const InvoicesContent = ({ data }) => <Table
        rowKey="id"
        sort={sort}
        endPoint={getInvoicesApiEndPoint(data.id)}
        onRowClick={handleRowClick}
        columns={invoiceColumns}
     />;

    const tabs = [
        {
            label: 'Général',
            component: <GeneralContent data={state} />,
        },
        {
            label: 'Factures',
            component: <InvoicesContent data={state} />,
            hide: authorization.isInRoles(roles.preparer),
        },

    ];

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    useEffect(() => {
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    return (

        <>
            {
                state && (
                    <Page title ={`[${state.id}] Commande`}>
                        <Tabs tabs={tabs} data={state} />
                        <div className={globalClasses.content}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <BottomAppBar>
                                        <Box className={classes.flexGrow} />
                                        <Box display="flex" spacing={1}>
                                            {
                                                state.discountAmount > 0 && (
                                                    <Box className={classes.amountProperty}>
                                                        <Property
                                                            valueVariant="subtitle1"
                                                            label="MT REMISE"
                                                            valueColor="secondary"
                                                            value={formatter.format(state.discountAmount)}
                                                        />
                                                    </Box>
                                                )
                                            }
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant HT" valueColor="default" value={formatter.format(state.etAmount)} />
                                            </Box>
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant TVA" valueColor="default" value={formatter.format(state.vatAmount)} />
                                            </Box>
                                            <Box className={classes.amountProperty}>
                                                <Property valueVariant="subtitle1" label="Montant TTC" value={formatter.format(state.itAmount)} />
                                            </Box>
                                        </Box>
                                    </BottomAppBar>
                                </Grid>
                            </Grid>
                        </div>
                    </Page>)
            }
        </>

    );
};

export default Details;
