import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { formatPercent } from '../../services/number';
import { getDiscountTypeLabel } from '../shared/services';
import { getDiscountRate, getQuantity } from './services';

export const sort = {
    field: 'orderItemId',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'orderItemId',
        sorting: true,
    },
    {
        label: 'Produit',
        field: 'label',
        sorting: true,
        width: '20%',
    },
    {
        label: 'N° de lot',
        field: 'lotCode',
        sorting: true,
    },
    {
        label: 'Date de péremp.',
        field: 'expirationDate',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.expirationDate),
    },
    {
        label: 'PU',
        field: 'price',
        sorting: true,
        align: 'right',
        render: (index, column, row) => formatter.format(row.price),
    },
    {
        label: 'Remise',
        field: 'discountType',
        sorting: true,
        render: (index, column, row) => getDiscountTypeLabel(row.discountType),
    },
    {
        label: '% Remise',
        field: 'discountRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getDiscountRate(row),
    },
    {
        label: 'Quantité',
        field: 'quantity',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getQuantity(row),
    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'TVA',
        field: 'vatRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatPercent(row.vatRate),
    },
    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),
    },
];
