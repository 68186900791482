import { status, mode } from './constants';

export const getStatusLabel = (value) => {
    switch (value) {
        case status.canceled:
            return 'Annulée';
        case status.confirmed:
            return 'Confirmée';
        default:
            return 'Brouillon';
    }
};

export const getModeLabel = (value) => {
    switch (value) {
        case mode.check:
            return 'Chèque';
        case mode.transfer:
            return 'Transfert';
        case mode.credit:
            return 'Avoir';
        default:
            return 'Espèce';
    }
};

export const getStatusKeyValues = () => {
    const result = [];

    result.push({
        key: status.canceled,
        value: getStatusLabel(status.canceled),
    });
    result.push({
        key: status.draft,
        value: getStatusLabel(status.draft),
    });
    result.push({
        key: status.confirmed,
        value: getStatusLabel(status.confirmed),
    });

    return result;
};

export const getModeKeyValues = () => {
    const result = [];

    result.push({
        key: mode.cash,
        value: getModeLabel(mode.cash),
    });
    result.push({
        key: mode.check,
        value: getModeLabel(mode.check),
    });
    result.push({
        key: mode.transfer,
        value: getModeLabel(mode.transfer),
    });
    result.push({
        key: mode.credit,
        value: getModeLabel(mode.credit),
    });

    return result;
};
