import React, { useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import { default as MAutocomplete } from '@material-ui/lab/Autocomplete';

import useApi from '../../hooks/api';

export default function Autocomplete (props) {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const client = useApi({
        loader: false,
        alert: false,
        refresh: false,
    });
    const rowCount = props.rowCount > 0 ? props.rowCount : 100;
    const url = `${props.url}?take=${rowCount}&page=1&search=`;

    const onInputValueChange = (newValue) => {
        setInputValue(newValue);
    };

    const onValueChange = (newValue) => {
        setValue(newValue);
        if (props.onChange) {
            props.onChange(props.name, newValue);
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur(props.name);
        }
    };

    useEffect(() => {
        let active = true;

        if (!inputValue || inputValue === '' || inputValue.length < 2) {
            return undefined;
        }

        (async () => {
            const response = await client.get(url + inputValue);

            const entities = response.data;

            if (active) {
                setOptions(entities.data);
            }
        })();

        return () => {
            active = false;
        };
    }, [inputValue]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (
        <>
            <MAutocomplete
                disabled={props.disabled}
                id={props.id}
                open={open}
                value={value}
                onOpen={
                    () => {
                        setOpen(true);
                    }
                }
                onClose={
                    () => {
                        setOpen(false);
                    }
                }
                getOptionSelected={(option, value) => option.id === value?.id}
                getOptionLabel={typeof props.getOptionLabel === 'function' ? props.getOptionLabel : ((option) => option.name)}
                onChange={
                    (event, value) => {
                        onValueChange(value);
                    }
                }
                onBlur={
                    (event, value) => {
                        onBlur(value);
                    }
                }
                onInputChange={
                    (event, value) => {
                        onInputValueChange(value);
                    }
                }
                options={options}
                filterOptions={props.filterOptions}
                size="small"
                renderInput={
                    (params) => (
                        <TextField
                            required={props.required}
                            helperText={props.submitCount > 0 && props.errors[props.name] ? props.errors[props.name] : ''}
                            error={props.submitCount > 0 && Boolean(props.errors[props.name])}
                            size="small"
                            {...params}
                            label={props.label}
                            variant="outlined"
                            id={props.name}
                            InputProps={
                                {
                                    ...params.InputProps,
                                }
                            }
                        />
                    )
                }
            />
        </>
    );
}
