import React, { useEffect, useState, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { useParams } from 'react-router';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useLoader from '../../hooks/loader';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import { default as EmployeeInfo } from '../employee/info';
import ModifiedOn from '../entity/modifiedOn';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import useStyles from './theme';
import Property from '../shared/property';
import { EmailStatus, Status } from './status';
import { getRolesLabels } from '../authorization/services';


const Details = () => {
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const loader = useLoader();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const authorization = useAuthorization();
    const { id } = useParams();
    const { state: { refresh, user } } = useContext(GlobalContext);
    const api = useApi();
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        dialog.updateUser(data);
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez vous supprimer l'utilisateur ${data.name}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onSettingsEdit = (data) => {
        dialog.updateUserSettings(data);
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const getTitleComponent = (data) => <CardHeader
        avatar={<Avatar className={classes.avatar}><VerifiedUserIcon /></Avatar>}
        title={data.name}
        subheader={<ModifiedOn {...data} />}
    />;

    const getActions = (data) => ({
        'delete': {
            onClick: onDelete,
            hide: !authorization.isInRoles(roles.system) || data.roles.includes(roles.system),
        },
        customs: [
            {
                icon: <LockIcon fontSize="small" />,
                label: 'Mot de passe',
                onClick: onEdit,
                hide: !authorization.isInRoles(roles.system) || (data.roles.includes(roles.system) && user.id !== data.id),
            },
            {
                icon: <SettingsIcon fontSize="small" />,
                label: 'Paramètres',
                onClick: onSettingsEdit,
                hide: !authorization.isInRoles(roles.system) || (data.roles.includes(roles.system) && user.id !== data.id),
            },
        ],
    });

    const load = () => {
        loader.show();
        api.get(id)
            .then((data) => {
                setState(data);
                toolBar.show({
                    data,
                    title: getTitleComponent(data),
                    actions: getActions(data),
                });
            });
    };

    useEffect(() => {
        load();
    }, [refresh]);

    return (

        <div className={globalClasses.content}>
            {
                state && (
                    <Page title={state?.id ? `[${state.id}] Utilisateur modification ` : 'Utilisateur création'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} >
                                <Property label="Email vérifié" >
                                    <EmailStatus data={state} />
                                </Property>
                                <Property label="Email" value={state.id} />
                                <Property label="Roles" value={getRolesLabels(state.roles)} />
                                <Property label="Statut" >
                                    <Status data={state} />
                                </Property>
                            </Grid>
                            {
                                state.employee && (
                                    <Grid item xs={12} >
                                        <EmployeeInfo {...state?.employee} />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Page>)
            }
        </div>

    );
};

export default Details;
