import { status } from './constants';

export const getStatusTypeLabel = (value) => {
    switch (value) {
        case status.missed:
            return 'Manquée';
        case status.removed:
            return 'Supprimée';
        case status.canceled:
            return 'Annulée';
        case status.scheduled:
            return 'Programmée';
        case status.done:
            return 'Terminée';
        default:
            return null;
    }
};

export const getStatusKeyValues = () => {
    const result = [];

    result.push({
        key: status.missed,
        value: getStatusTypeLabel(status.missed),
    });
    result.push({
        key: status.removed,
        value: getStatusTypeLabel(status.removed),
    });
    result.push({
        key: status.canceled,
        value: getStatusTypeLabel(status.canceled),
    });
    result.push({
        key: status.scheduled,
        value: getStatusTypeLabel(status.scheduled),
    });
    result.push({
        key: status.done,
        value: getStatusTypeLabel(status.done),
    });

    return result;
};
