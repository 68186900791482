import React, { useContext } from 'react';

import { Formik } from 'formik';
import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useApi from './api';
import Form from './forms/register';
import validationSchema from './validationSchemas/register';

const Edit = (props) => {
    const dialog = useDialog();
    const api = useApi();
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        if (data.id) {
            api.save(toEditDataContract(data));
        } else {
            api.save(toCreatDataContract(data));
        }
    };

    const toCreatDataContract = (data) => ({
        id: data.id,
        email: data.email,
        password: data.password,
        confirmedPassword: data.confirmedPassword,
    });

    const toEditDataContract = (data) => ({
        id: data.id,
        password: data.password,
        confirmedPassword: data.confirmedPassword,
    });


    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={props.values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    );
};

export default Edit;
