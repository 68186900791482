import { object, number, date } from 'yup';

const validationSchema = object({
    thirdParty: object().nullable()
        .required('Le client est requis'),
    date: date().typeError('La date n\'est pas valide')
        .required('La date est requise'),
    amount: number().typeError('Le montant n\'est pas valide')
        .required('Le montant est requis'),
    mode: number().typeError('Le mode n\'est pas valide')
        .min(0, 'Le mode n\'est pas valide')
        .required('Le mode est requis'),
    status: number().typeError('Le statut n\'est pas valide')
        .min(-1, 'Le statut n\'est pas valide')
        .required('Le statut est requis'),
    beneficiary: object().nullable()
        .required('Le bénéficiaire est requis'),
});

export default validationSchema;
