import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import brown from '@material-ui/core/colors/brown';
import cyan from '@material-ui/core/colors/cyan';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import indigo from '@material-ui/core/colors/indigo';
import lightBlue from '@material-ui/core/colors/lightBlue';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import orange from '@material-ui/core/colors/orange';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';

export const endPoint = '/reports';
export const apiEndPoint = '/reports';
export const colors = {
    global: {
        count: '#009688',
        turnover: '#673ab7',
        positive: '#00b8d4',
        profit: '#00c853',
        negative: '#ef5350',
    },
    employee: {
        count: '#1e88e5',
        turnover: '#3f51b5',
        positive: '#00b8d4',
        profit: '#64dd17',
        negative: '#ef5350',
    },
    palette: {
        red,
        deepPurple,
        lightBlue,
        green,
        yellow,
        deepOrange,
        blueGrey,
        pink,
        indigo,
        cyan,
        lightGreen,
        amber,
        brown,
        purple,
        blue,
        teal,
        lime,
        orange,
        grey,
    },
};
