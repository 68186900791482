import CryptoJS from 'crypto-js';

class Crypto {
    static getEncryptSHA256Key (value) {
        // eslint-disable-next-line new-cap
        return CryptoJS.SHA256(value ? value.toString() : '').toString();
    }

    static encrypt (value) {
        return CryptoJS.AES.encrypt(
            value,
            this.getEncryptSHA256Key(),
        ).toString();
    }

    static decrypt (value) {
        return CryptoJS.AES.decrypt(
            value,
            this.getEncryptSHA256Key(),
        ).toString(CryptoJS.enc.Utf8);
    }
}

export default Crypto;
