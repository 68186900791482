import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Line } from 'react-chartjs-2';

import 'moment/locale/fr';
import useAuthorization from '../../../../hooks/authorization';
import { timeZone, getUtcStartToday, dateDisplayFormat } from '../../../../services/date';
import { roles } from '../../../authorization/constants';
import Indicator from '../../../shared/indicator';
import { colors } from '../constants';
import { Indicator as IndicatorLoader } from '../loaders/indicator';
import { Line as LineLoader } from '../loaders/line';
import { getDataFromDate, getCount, getEmployeeCount } from '../services';
import { getCounts } from './services';

export const Counts = ({ data, employee, isLoading }) => {
    const authorization = useAuthorization();

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <LineLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Line
                            height={200}
                            data={getCounts(data, authorization.isInRoles(roles.system, roles.supervisor), employee)}
                            options={
                                {
                                    title: {
                                        text: 'Ventes',
                                        display: true,
                                    },
                                    maintainAspectRatio: false,
                                }
                            }
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export const TodayCount = ({ data, isLoading }) => {
    const date = getUtcStartToday();

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <IndicatorLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Indicator
                            header="Ventes"
                            subheader={`Le ${dateDisplayFormat(date.tz(timeZone))}`}
                            value={getCount(getDataFromDate(data, date))}
                            valueColor={colors.global.count}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export const TodayEmployeeCount = ({ data, employee, isLoading }) => {
    const date = getUtcStartToday();

    return (
        <Grid container spacing={2}>
            {
                isLoading && (
                    <Grid item xs={12}>
                        <IndicatorLoader />
                    </Grid>
                )
            }
            {
                !isLoading && (
                    <Grid item xs={12}>
                        <Indicator
                            header={`${employee.name} (ventes)`}
                            subheader={`Le ${dateDisplayFormat(date.tz(timeZone))}`}
                            value={getEmployeeCount(getDataFromDate(data, date), employee)}
                            valueColor={colors.employee.count}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

export default Counts;
