import { default as useApiBase } from '../../hooks/api';
import { apiEndPoint, apiSettings } from './constants';

export default function useApi () {
    const api = useApiBase(apiSettings);

    const get = (id) => api.get(`${apiEndPoint}/${id}`)
        .then((response) => response.data);

    const updateStatus = (data, status) => api.put(`${apiEndPoint}/${data.id}/status`, {
        status,
    });

    const download = (id) => api.download(`${apiEndPoint}/download/${id}`);

    return {
        get,
        download,
        updateStatus,
    };
}
