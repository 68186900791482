
export const endPoint = '/products';
export const apiEndPoint = '/products';
export const initialValue = {
    name: '',
    brand: '',
    dosage: '',
    form: '',
    conditioning: '',
    price: 0,
    marginRate: 0,
    extraCostHonorary: 0,
    publicPrice: 0,
    vatRate: 0,
    storageType: 0,
};

export const getLotApiEndPoint = (id) => `${apiEndPoint}/${id}/lots`;


export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'Le produit {0} a été créé avec succés',
        put: 'Le produit {0} a été mis à jour avec succés',
        'delete': 'Le produit {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
