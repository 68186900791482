
import { apiEndPoint as ordersApiEndPoint } from '../order/constants';


export const apiEndPoint = ordersApiEndPoint;

export const initialValue = {
    lot: null,
    quantity: 0,
    price: 0,
    totalQuantity: 0,
    discountType: 1,
    discountRate: 0,
};


export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'L\'article {0} a été créé avec succés',
        put: 'L\'article {0} a été mis à jour avec succés',
        'delete': 'L\'article {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
