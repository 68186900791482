import React, { useContext } from 'react';

import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { default as MAlert } from '@material-ui/lab/Alert';

import { GlobalContext } from '../../globalState';

const useStyles = makeStyles((theme) => createStyles({
    alert: {
        marginBottom: theme.spacing(2),
    },
}));

export default function Alert () {
    const classes = useStyles();
    const { state, dispatch } = useContext(GlobalContext);

    if (state.alert && state.alert.dismissible && state.alert.open) {
        setTimeout(() => {
            dispatch({
                alert: {
                    ...state.alert,
                    open: false,
                },
            });
        }, 3000);
    }

    return (
        <>
            {
                state.alert && (
                    <Collapse in={state.alert.open}>
                        <MAlert variant={state.alert.variant} severity={state.alert.severity} className={classes.alert}>
                            {state.alert.content}
                        </MAlert>
                    </Collapse>
                )
            }
        </>
    );
}
