import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import useAuthorization from '../../../hooks/authorization';
import { default as PaymentMode } from '../../order/status';
import { toDateForForms } from '../../../services/date';
import { roles } from '../../authorization/constants';
import { default as EmployeeLookup } from '../../employee/lookup';
import { default as OrganizationLookup } from '../../organization/lookup';
import { status as orderStatus } from '../constants';
import { getStatusKeyValues } from '../services';

export const Form = (props) => {
    const authorization = useAuthorization();
    const [initialized, setInitialized] = useState(false);

    const {
        values: { id, createDate, date, status, seller, normalParcelNumber, coldParcelNumber, thirdParty, defaultPaymentMode },
        errors,
        touched,
        submitCount,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
    } = props;

    useEffect(() => {
        if (!initialized) {
            setFieldValue('date', toDateForForms(date));
            setInitialized(true);
        }
    }, []);

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <OrganizationLookup
                        disabled={Boolean(!authorization.isInRoles(roles.system) && Boolean(createDate))}
                        required
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="thirdParty"
                        value={thirdParty}
                        onChange={setFieldValue}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="date"
                        type="date"
                        helperText={touched.date ? errors.date : ''}
                        error={Boolean(errors.date)}
                        label="Date"
                        defaultValue={toDateForForms(date)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                {
                    (Boolean(createDate)) &&
                    <Grid item xs={12} md={6} />
                }

                <Grid item xs={12} md={6}>
                    <PaymentMode
                        disabled={Boolean(!authorization.isInRoles(roles.system))}
                        name="defaultPaymentMode"
                        label="Mode de payment"
                        value={defaultPaymentMode}
                        onChange={handleChange}
                    />
                </Grid>
                {
                    authorization.isInRoles(roles.system, roles.administrator) && id &&
                    (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id="status-select-label">Statut</InputLabel>
                                    <Select
                                        disabled={!touched.status && (status === orderStatus.canceled || status === orderStatus.returned)}
                                        labelId="status-select-label"
                                        id="status-select"
                                        name="status"
                                        label="Statut"
                                        value={status}
                                        onChange={
                                            (e) => {
                                                handleChange(e);
                                                handleBlur(e);
                                            }
                                        }
                                    >
                                        {getStatusKeyValues().map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <EmployeeLookup
                                    submitCount={submitCount}
                                    errors={errors}
                                    touched={touched}
                                    label="Vendeur"
                                    name="seller"
                                    value={seller}
                                    onChange={setFieldValue}
                                />
                            </Grid>
                        </>
                    )
                }


                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="normalParcelNumber"
                        type="number"
                        helperText={touched.normalParcelNumber ? errors.normalParcelNumber : ''}
                        error={Boolean(errors.normalParcelNumber)}
                        label="Colis normal"
                        value={normalParcelNumber}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="coldParcelNumber"
                        type="number"
                        helperText={touched.coldParcelNumber ? errors.coldParcelNumber : ''}
                        error={Boolean(errors.coldParcelNumber)}
                        label="Colis froid"
                        value={coldParcelNumber}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>

            </Grid>
        </form>
    );
};
export const NoteForm = (props) => {
    const {
        values: { note },
        errors,
        touched,
        handleSubmit,
        handleChange,
    } = props;

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        required
                        name="note"
                        multiline
                        rows={4}
                        rowsMax={4}
                        helperText={touched.note ? errors.note : ''}
                        error={Boolean(errors.note)}
                        label="Note"
                        value={note}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                    />
                </Grid>
            </Grid>
        </form>
    );
};


