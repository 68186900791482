import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { getStatusTypeLabel } from './services';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
        options: {
            draggable: true,
        },
    },
    {
        label: 'Commande',
        field: 'orderId',
        sorting: true,
        options: {
            draggable: true,
        },
    },
    {
        label: 'Client',
        field: 'thirdParty.name',
        sorting: true,
        options: {
            draggable: true,
        },
    },
    {
        label: 'Date',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),

    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.status),

    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'Montant TVA',
        field: 'vatAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.vatAmount),
    },
    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),
    },
];

