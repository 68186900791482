import React, { useEffect, useState } from 'react';

import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import useApi from '../../hooks/api';
import { formatUtcToLocalDate } from '../../services/date';
import Autocomplete from '../shared/autocomplete';
import { apiEndPoint, availablesApiEndPoint, apiSettings } from './constants';

export default function Lookup ({
    required,
    label,
    disabled,
    submitCount,
    errors,
    touched,
    name,
    url,
    value,
    onChange,
}) {
    const [state, setState] = useState(null);
    const api = useApi(apiSettings);

    const getLabel = (data) => `${data.product.brand
    } ${data.product.dosage
    } ${data.product.form
    } ${data.product.conditioning
    } ${formatUtcToLocalDate(data.expirationDate, 'DD/MM/YY')
    } (${data.currentQuantity})`;

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => `${option.product.name} ${getLabel(option)}`,
    });

    useEffect(() => {
        if (typeof value === 'number') {
            api.get(`${apiEndPoint}/${value}`)
                .then((response) => {
                    setState(response.data);
                });
        }
    }, []);

    return (
        <Autocomplete
            required={required}
            label={label ?? 'Lot'}
            disabled={disabled}
            submitCount={submitCount}
            errors={errors}
            touched={touched}
            name={name}
            url={url ?? availablesApiEndPoint}
            value={state ?? value}
            getOptionLabel={getLabel}
            onChange={onChange}
            filterOptions={filterOptions}
        />
    );
}
