import { default as useApiBase } from '../../hooks/api';
import { apiEndPoint, apiSettings } from './constants';

export default function useApi () {
    const api = useApiBase(apiSettings);

    const deleTe = (orderId, id) => api.deleTe(`${apiEndPoint}/${orderId}/items/${id}`);

    const save = (orderId, data) => api.post(`${apiEndPoint}/${orderId}/items`, data)
        .then((response) => response.data);

    return {
        deleTe,
        save,
    };
}
