import { object, string } from 'yup';
import { default as addressValidationSchema } from '../address/validationSchema';
import { parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';

const validationSchema = object({
    firstName: string('Saisissez le prénom').required('Le prénom est requis'),
    lastName: string('Saisissez le nom').required('Le nom est requis'),
    email: string('Saisissez l\'email').nullable()
        .required('L\'adresse email est requis')
        .email('L\'adresse email n\'est pas valide'),
    phone: string('Saisissez le numéro de téléphone')
        .required('Le numéro de téléphone est requis')
        .test(
            'number',
            'Le numéro de téléphone n\'est pas valide',
            (value) => {
                try {
                    const parse = parsePhoneNumber(value, 'DZ');

                    return Boolean(parse?.isValid() && value.startsWith('+') && getCountryCallingCode('DZ') === value.trim().substring(1, 4));
                } catch {
                    return false;
                }
            },
        ),
    address: addressValidationSchema,
});

export default validationSchema;
