import { useLayoutEffect, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useUp = (key) => {
    const theme = useTheme();

    return useMediaQuery(theme.breakpoints.up(key));
};

export const useDown = (key) => {
    const theme = useTheme();

    return useMediaQuery(theme.breakpoints.down(key));
};

export const useOnly = (key) => {
    const theme = useTheme();

    return useMediaQuery(theme.breakpoints.only(key));
};

export const useBetween = (start, end) => {
    const theme = useTheme();

    return useMediaQuery(theme.breakpoints.between(start, end));
};

export const useWindowSize = () => {
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    useLayoutEffect(() => {
        const updateSize = () => {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', updateSize);
        updateSize();

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
};

export const useFormatWindow = () => {
    const size = useWindowSize();

    return size.width > size.height ? 'landscape' : 'portrait';
};

export const useHeightToolbar = () => {
    let height;
    const format = useFormatWindow();
    const upSm = useUp('sm');

    if (upSm) {
        height = 64;
    } else {
        height = format === 'landscape' ? 48 : 56;
    }

    return height;
};

export const useRootHeight = (toolbarNumber, paddingRoot) => {
    const theme = useTheme();
    const { height } = useWindowSize();
    const heightToolbar = useHeightToolbar();

    if (!paddingRoot) {
        paddingRoot = theme.spacing(3);
    }

    return height - (paddingRoot * 2) - (heightToolbar * toolbarNumber);
};
