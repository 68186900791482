import React, { useReducer } from 'react';

import crypto from './services/crypto';
import { GlobalContext } from './globalState';

const persistables = [
    {
        key: 'user',
        inStore: true,
    },
    {
        key: 'views',
        inStore: true,
    },
];

const save = (key, value) => {
    localStorage.setItem(
        key,
        crypto.encrypt(JSON.stringify(value)),
    );
};

const get = (key) => {
    const value = localStorage.getItem(key);

    if (typeof value === 'undefined' || value === null) {
        return null;
    }

    return JSON.parse(crypto.decrypt(value));
};

const getGlobalState = () => ({
    drawerIsOpen: false,
    user: get('user'),
    views: get('views'),
});

const Provider = (props) => {
    const [state, dispatch] = useReducer(
        (reduceState, newValue) => {
            Object.keys(newValue).forEach((key) => {
                const prop = persistables.find((x) => x.key === key);

                if (prop?.inStore) {
                    save(key, newValue[key]);
                }
            });

            return {
                ...reduceState,
                ...newValue,
            };
        },
        getGlobalState(),
    );

    return (
        <GlobalContext.Provider value={
            {
                state,
                dispatch,
            }
        }
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default Provider;
