/* eslint-disable max-len */
import React from 'react';
import { roles } from '../authorization/constants';
import Grid from '@material-ui/core/Grid';
import Product from '../product/property';
import Lot from '../lot/property';
import useAuthorization from '../../hooks/authorization';
import { formatter } from '../../services/currency';
import { formatPercent } from '../../services/number';
import Property from '../shared/property';
import { getDiscountTypeLabel } from '../shared/services';
import { getDiscountRate } from './services';

const Abstract = (props) => {
    const {
        lot, extraCostHonorary, price, publicPrice, freeQuantity, vatRate,
        vatAmount, etAmount, ownedAmounts, discountType,
    } = props;
    const authorization = useAuthorization();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Property label="Produit" value={<Product data={lot.product} />} />
                <Property label="Lot" value={<Lot data={lot} />} />
                <Property label="Code du lot" value={lot.id} />
                <Property label="N° du lot" value={lot.code} />
                <Property label="SHP" value={formatter.format(extraCostHonorary)} />
                <Property label="PU" value={formatter.format(price)} />
                <Property label="PPA" value={formatter.format(publicPrice)} />
                {
                    authorization.isInRoles(roles.system, roles.administrator) && (
                        <>
                            <Property label="Remise d'achat" value={getDiscountTypeLabel(lot.purchaseDiscountType)} />
                            <Property label="Taux d'achat" value={formatPercent(lot.purchaseDiscountRate)} />

                        </>)
                }
                <Property label="Quantité dans le Colis" value={lot.quantityPackage} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="Remise de vente" value={getDiscountTypeLabel(lot.saleDiscountType)} />
                <Property label="Taux de vente" value={formatPercent(lot.saleDiscountRate)} />
                <Property label="Remise appliquée" value={getDiscountTypeLabel(discountType)} />
                <Property label="Taux appliqué" value={getDiscountRate(props)} />
                <Property label="Quantité gratuite" value={freeQuantity} />
                <Property label="Quantité Total" value={props.totalQuantity} />
                <Property label="TVA" value={formatPercent(vatRate)} />
                <Property label="Montant de la TVA" value={formatter.format(vatAmount)} />
                <Property label="Montant HT" value={formatter.format(etAmount)} />
                <Property label="Montant TTC" value={formatter.format(ownedAmounts)} valueColor="secondary" />
            </Grid>
        </Grid>
    );
};

export default Abstract;
