
import { roles } from '../authorization/constants';


export const endPoint = '/users';
export const apiEndPoint = '/users';
export const initialValue = {
    role: roles.operator,
    email: '',
    password: '',
    confirmedPassword: '',
};


export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'L\'utilisateur {0} a été créé avec succés',
        put: 'L\'utilisateur {0} a été mis à jour avec succés',
        'delete': 'L\'utilisateur {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
