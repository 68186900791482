import React, { useContext } from 'react';

import { AppBar, Toolbar as MToolbar, IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import green from '@material-ui/core/colors/green';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { GlobalContext } from '../../globalState';
import { useUp } from '../../hooks/screen';
import ModifiedOn from '../entity/modifiedOn';
import { drawerWidth, closedDrawerWidth } from './theme';

const useStyles = makeStyles((theme) => createStyles({
    title: {
        flexGrow: 1,
        '& .MuiCardHeader-root': {
            marginLeft: '8px',
            padding: '0 !important',
        },
    },
    content: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0),
        minHeight: '58px',
        '& button': {
            marginRight: theme.spacing(1),
        },
    },
    saveButton: {
        color: green[500],
        border: `1px solid ${alpha(green[500], 0.5)}`,
        '&:hover': {
            backgroundColor: alpha(green[500], theme.palette.action.hoverOpacity),
            border: `1px solid ${green[500]}`,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&$disabled': {
            border: `1px solid ${theme.palette.action.disabled}`,
        },
    },

}));

const ToolbarButton = (props) => {
    const { title, color, icon, onClick, className } = props;

    return (
        <Tooltip title={title} >
            <IconButton
                style={
                    {
                        border: '1px solid',
                        padding: '8px',
                    }
                }
                color={color ?? 'default'}
                onClick={onClick}
                className={className}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default function Toolbar () {
    const { state: { toolbar, drawerIsOpen } } = useContext(GlobalContext);
    const classes = useStyles();
    const upMd = useUp('md');

    if (toolbar && toolbar.open) {
        const { data, title, actions } = toolbar;

        return (
            <AppBar
                position="fixed"
                color="default"
                style={
                    {
                        left: upMd ? drawerIsOpen ? drawerWidth : closedDrawerWidth : 0,
                        right: 0,
                        width: 'auto',
                    }
                }
            >
                <MToolbar />
                <MToolbar className={classes.content}>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    {
                        actions && actions.cancel && !actions.cancel.hide && typeof (actions.cancel.onClick) === 'function' &&
                        <ToolbarButton
                            icon={<CloseIcon fontSize="small" />}
                            title={actions.save.label ?? 'Annuler'}
                            onClick={() => actions.cancel.onClick(data)}
                        />
                    }
                    {
                        actions && actions.save && !actions.save.hide && typeof (actions.save.onClick) === 'function' &&
                        <ToolbarButton
                            icon={<SaveIcon fontSize="small" />}
                            title={actions.save.label ?? 'Enregistrer'}
                            onClick={() => actions.save.onClick(data)}
                            className={classes.saveButton}
                        />
                    }
                    {
                        actions && actions.add && !actions.add.hide && typeof (actions.add.onClick) === 'function' &&
                        <ToolbarButton
                            icon={<AddIcon fontSize="small" />}
                            title={actions.add.label ?? 'Ajouter'}
                            onClick={() => actions.add.onClick(data)}
                        />
                    }
                    {
                        actions && actions.edit && !actions.edit.hide && typeof (actions.edit.onClick) === 'function' &&
                        <ToolbarButton
                            icon={<EditIcon fontSize="small" />}
                            title={actions.edit.label ?? 'Modifier'}
                            onClick={() => actions.edit.onClick(data)}
                        />
                    }
                    {
                        actions && actions.delete && !actions.delete.hide && typeof (actions.delete.onClick) === 'function' &&
                        <ToolbarButton
                            color="secondary"
                            icon={<DeleteIcon fontSize="small" />}
                            title={actions.delete.label ?? 'Supprimer'}
                            onClick={() => actions.delete.onClick(data)}
                        />
                    }
                    {
                        actions && actions.customs &&
                        actions.customs.map((item) => {
                            if (!item.hide) {
                                return (
                                    <ToolbarButton color={item.color}
                                        icon={item.icon}
                                        title={item.label}
                                        onClick={
                                            () => {
                                                item.onClick(data);
                                            }
                                        }
                                    />
                                );
                            }
                        })
                    }
                </MToolbar>
            </AppBar>
        );
    }

    return (null);
}

export const ToolbarHeader = (props) => {
    const { icon, data, displayName, className } = props;

    return (
        <CardHeader avatar={<Avatar className={className}>{icon}</Avatar>} title={displayName ?? data?.displayName} subheader={<ModifiedOn {...data} />} />
    );
};
