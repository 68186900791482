import React, { useContext } from 'react';

import { Grid, FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { GlobalContext } from '../../../globalState';
import { roles } from '../../authorization/constants';
import { getRoleLabel } from '../../authorization/services';
import { default as EmployeeLookup } from '../../employee/lookup';

const Form = (props) => {
    const { state: { user } } = useContext(GlobalContext);

    const {
        values: { id, employee, role },
        errors,
        touched,
        submitCount,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = props;

    return (
        <form ref={props.setRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item>
                    <FormHelperText error>
                        {errors.general}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                            disabled={user.id === id}
                            labelId="role-select-label"
                            id="role-select"
                            name="role"
                            label="Role"
                            value={role}
                            onChange={handleChange}
                        >
                            <MenuItem value="">--Aucun--</MenuItem>
                            {Object.keys(roles).map((item) => <MenuItem value={roles[item]} key={item}>{getRoleLabel(roles[item])}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <EmployeeLookup
                        required
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        name="employee"
                        value={employee}
                        onChange={setFieldValue}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
