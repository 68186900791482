import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import useAuthorization from '../../../hooks/authorization';
import { toDateForForms, utcToLocalDateTime } from '../../../services/date';
import { roles } from '../../authorization/constants';
import { default as EmployeeLookup } from '../../employee/lookup';
import { getStatusKeyValues } from '../services';

const Form = (props) => {
    const authorization = useAuthorization();
    const [initialized, setInitialized] = useState(false);

    const {
        forwardedRef,
        values: { id, date, status, deliveryMan },
        errors,
        touched,
        submitCount,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = props;

    useEffect(() => {
        if (!initialized) {
            setFieldValue('date', utcToLocalDateTime(date));
            setInitialized(true);
        }
    }, []);

    return (
        <form ref={forwardedRef} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <TextField
                        required
                        name="date"
                        type="date"
                        helperText={touched.date ? errors.date : ''}
                        error={Boolean(errors.date)}
                        label="Date"
                        defaultValue={toDateForForms(date)}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        InputLabelProps={
                            {
                                shrink: true,
                            }
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        authorization.isInRoles(roles.system, roles.administrator) && id &&
                    (
                        <>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="status-select-label">Statut</InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status-select"
                                    name="status"
                                    label="Statut"
                                    value={status}
                                    onChange={handleChange}
                                >
                                    {getStatusKeyValues().map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </>
                    )
                    }
                </Grid>
                <Grid item xs={12} md={4} />
                <Grid item xs={12} md={4}>
                    <EmployeeLookup
                        submitCount={submitCount}
                        errors={errors}
                        touched={touched}
                        label="Livreur"
                        name="deliveryMan"
                        value={deliveryMan}
                        onChange={setFieldValue}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default Form;
