import React, { useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import useAuthorization from '../../hooks/authorization';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { Table, useStyles as useTableStyles } from '../shared/grid';
import { default as useGlobalStyles } from '../shared/theme';
import { apiEndPoint, viewEndPoint, status as orderStatus } from './constants';
import { columns, sort } from './columns';
import { roles } from '../authorization/constants';
import useStyles from './theme';
import moment from 'moment-timezone';

const List = () => {
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const authorization = useAuthorization();

    const history = useHistory();
    const { state: { refresh } } = useContext(GlobalContext);

    const onAdd = () => {
        dialog.addOrder();
    };

    const handleRowClick = (event, key, row) => {
        const path = `${viewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    const getTitleComponent = () => <CardHeader avatar={<Avatar className={classes.avatar}><LocalGroceryStoreIcon /></Avatar>} />;

    useEffect(() => {
        toolBar.show({
            title: getTitleComponent(),
            actions: {
                add: {
                    onClick: onAdd,
                    hide: authorization.isInRoles(roles.preparer),
                },
            },
        });
    }, [refresh]);

    const handleGetRowClasses = (key, row) => {
        const rowDate = moment(new Date(row.date)).add(1, 'days');
        const nowDate = moment(new Date().toUTCString());
        const result = [];

        if (row.status === orderStatus.draft && rowDate.isSameOrBefore(nowDate)) {
            result.push(tableClasses.error);
        } else if (row.status === orderStatus.confirmed && rowDate.isSameOrBefore(nowDate)) {
            result.push(tableClasses.warning);
        };

        return result;
    };

    return (
        <Page title = "Commandes">
            <div className={globalClasses.content}>
                <Table
                    rowKey="id"
                    sort={sort}
                    endPoint={apiEndPoint}
                    onRowClick={handleRowClick}
                    columns={columns}
                    getRowClasses={handleGetRowClasses}
                    refresh={refresh}
                />
            </div>
        </Page>
    );
};

export default List;
