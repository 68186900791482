import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import useApi from '../../hooks/api';
import useError from '../../hooks/error';
import { timeZone } from '../../services/date';
import { apiEndPoint } from '../report/sales/constants';
import 'moment/locale/fr';
import { Amounts as DaySalesAmounts,
    TodayEmployeeAmounts } from '../report/sales/day/amounts';
import { Counts as DaySalesCounts,
    TodayEmployeeCount as TodayEmployeeSalesCount } from '../report/sales/day/counts';
import { Amounts as MonthAmounts,
    CurrentMonthEmployeeAmounts } from '../report/sales/month/amounts';
import { Counts as MonthCounts,
    CurrentMonthEmployeeCount } from '../report/sales/month/counts';

const Report = ({ data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(null);
    const errorHandler = useError();
    const startDate = moment.utc().tz(timeZone)
        .add(-3, 'M')
        .startOf('month');
    const endDate = moment.utc().tz(timeZone)
        .endOf('month');
    const client = useApi();

    useEffect(() => {
        const load = () => {
            setIsLoading(true);
            client.get(`${apiEndPoint}/sales/sellers?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&timeZone=${timeZone}`)
                .then((response) => {
                    setState(response.data);
                })
                .catch((error) => {
                    errorHandler.handle(error.response.status);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };

        load();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
                <DaySalesAmounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TodayEmployeeAmounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <DaySalesCounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TodayEmployeeSalesCount data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <MonthAmounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={2}>
                <CurrentMonthEmployeeAmounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <MonthCounts data={state} isLoading={isLoading} employee={data} />
            </Grid>
            <Grid item xs={12} sm={2}>
                <CurrentMonthEmployeeCount data={state} isLoading={isLoading} employee={data} />
            </Grid>
        </Grid>
    );
};

export default Report;
