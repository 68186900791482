export const loginPath = '/login';

export const roles = {
    system: 'System',
    administrator: 'Administrator',
    supervisor: 'Supervisor',
    operator: 'Operator',
    preparer: 'Preparer',
    deliveryMan: 'DeliveryMan',
};
