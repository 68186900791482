
export const endPoint = '/deliveries';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/deliveries';
export const initialValue = {
    status: 0,
    date: new Date(),
    deliveryMan: null,
};

export const status = {
    missed: -3,
    removed: -2,
    canceled: -1,
    scheduled: 0,
    done: 1,
};

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La livraison {0} a été créée avec succés',
        put: 'La livraison {0} a été mise à jour avec succés',
        'delete': 'La livraison {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
