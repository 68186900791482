import React, { useCallback } from 'react';

import MuiLink from '@material-ui/core/Link';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';

import useDialog from '../../hooks/dialog';

const Link = withStyles({
    root: {
        '&[disabled]': {
            color: 'grey',
            cursor: 'default',
            '&:hover': {
                textDecoration: 'none',
            },
        },
    },
})(MuiLink);


const useStyles = makeStyles((theme) => createStyles({
    cardHeaderRoot: {
        margin: 0,
        padding: 0,
    },
    cardHeaderAvatar: {
        marginRight: 4,
        '& div': {
            width: 26,
            height: 26,
        },
        '& svg': {
            width: '0.7em',
            height: '0.7em',
        },
    },
}));

export const EntityLink = (props) => {
    const { href, label, title, avatar, disabled } = props;
    const classes = useStyles();
    const history = useHistory();
    const dialog = useDialog();

    const onClick = useCallback(
        (event) => {
            event.preventDefault();
            dialog.hide();
            history.push(href);
        },
        [],
    );

    return (
        <Link component="button" className={classes.button} title={title} onClick={onClick} disabled={disabled}>
            <CardHeader
                classes={
                    {
                        root: classes.cardHeaderRoot,
                        avatar: classes.cardHeaderAvatar,
                    }
                }
                avatar={avatar}
                title={label}
            />
        </Link>
    );
};

export default EntityLink;

export const DisplayContent = (props) => {
    const { label, avatar } = props;
    const classes = useStyles();


    return (
        <CardHeader
            classes={
                {
                    root: classes.cardHeaderRoot,
                    avatar: classes.cardHeaderAvatar,
                }
            }
            title={label}
            avatar={avatar}
        />
    );
};
