import 'moment/locale/fr';
import moment from 'moment-timezone';

import { timeZone } from '../../../../services/date';
import { colors } from '../constants';
import { getSellersGroupedData } from '../services';

export const getCounts = (data, displayGlobal, employee) => {
    const group = getSellersGroupedData(data);

    if (!group) {
        return null;
    }

    const result = {
        labels: group.map((item) => item.date.clone().tz(timeZone)
            .format('DD/MM')),
        datasets: [],
    };

    if (displayGlobal) {
        result.datasets.push({
            label: 'Ventes',
            data: group.map((item) => item.count),
            fill: false,
            borderColor: colors.global.count,
        });
    }

    if (employee) {
        const countData = [];

        group.forEach((item) => {
            const sale = data.find((item1) => item1.sellerId === employee.id && item.date.isSame(moment.utc(item1.date)));

            if (sale) {
                countData.push(sale.count);
            } else {
                countData.push(0);
            }
        });

        result.datasets.push({
            label: employee.name,
            data: countData,
            fill: false,
            borderColor: colors.employee.count,
        });
    }

    return result;
};

export const getAmounts = (data, turnoverDisplay, profitDisplay, employee) => {
    const group = getSellersGroupedData(data);

    if (!group) {
        return null;
    }

    const result = {
        labels: group.map((item) => item.date.clone().tz(timeZone)
            .format('DD/MM')),
        datasets: [],
    };

    if (turnoverDisplay) {
        result.datasets.push({
            label: 'Chiffre',
            data: group.map((item) => item.turnover),
            fill: false,
            borderColor: colors.global.turnover,
        });
    }

    if (turnoverDisplay) {
        result.datasets.push({
            label: 'Chiffre positif',
            data: group.map((item) => item.positive),
            fill: false,
            borderColor: colors.global.positive,
        });
    }

    if (profitDisplay) {
        result.datasets.push({
            label: 'Bénéfice',
            data: group.map((item) => item.profit),
            fill: false,
            borderColor: colors.global.profit,
        });
    }

    if (employee) {
        const turnoverData = [];
        const positiveData = [];
        const profitData = [];

        group.forEach((item) => {
            const sale = data.find((item1) => item1.sellerId === employee.id && item.date.isSame(moment.utc(item1.date)));

            if (sale) {
                turnoverData.push(sale.turnover);
                positiveData.push(sale.positive);
                profitData.push(sale.profit);
            } else {
                turnoverData.push(0);
                positiveData.push(0);
                profitData.push(0);
            }
        });

        result.datasets.push({
            label: employee.name,
            data: turnoverData,
            fill: false,
            borderColor: colors.employee.turnover,
        });

        result.datasets.push({
            label: `${employee.name} (Positif)`,
            data: positiveData,
            fill: false,
            borderColor: colors.employee.positive,
        });

        if (profitDisplay) {
            result.datasets.push({
                label: `${employee.name} (Bénéfice)`,
                data: profitData,
                fill: false,
                borderColor: colors.employee.profit,
            });
        }
    }

    return result;
};
