import { object, number, ref } from 'yup';

const validationSchema = object({
    lot: object().nullable()
        .required('Le produit est requis'),
    price: number().typeError('Le PU n\'est pas valide')
        .required('Le prix est requis')
        .min(0, 'Le prix n\'est pas valide')
        .required('Le prix n\'est pas valide'),
    discountType: number().typeError('La remise n\'est pas valide')
        .min(0, 'La remise n\'est pas valide')
        .required('La remise est requise'),
    discountRate: number().typeError('Le taux UG n\'est pas valide')
        .min(0, 'Le taux UG n\'est pas valide')
        .required('Le taux UG est requis'),
    quantity: number().typeError('La quantité n\'est pas valide')
        .min(1, 'La quantité n\'est pas valide')
        .required('La quantité est requise')
        .max(ref('lot.currentQuantity'), 'La quantité n\'est pas valide'),
    totalQuantity: number().typeError('La quantité n\'est pas valide')
        .min(1, 'La quantité n\'est pas valide')
        .required('La quantité est requise')
        .test(
            'quantitytest',
            'La quantité n\'est pas valide',
            // eslint-disable-next-line func-names
            function (totalQuantity) {
                // eslint-disable-next-line no-invalid-this
                if (!this.parent.lot) {
                    return false;
                }

                // eslint-disable-next-line no-invalid-this
                const { currentQuantity } = this.parent.lot;

                if (totalQuantity > currentQuantity) {
                    return false;
                }

                return true;
            },
        ),
});

export default validationSchema;
