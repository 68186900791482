/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
import axios from 'axios';

import { apiEndPoint } from '../components/authentification/login/constants';

const getClient = (baseUrl, accessToken, refresh) => {
    const options = {
        baseURL: baseUrl,
    };

    const service = axios.create(options);

    service.interceptors.request.use(
        (config) => {
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        },
        (error) => Promise.reject(error),
    );

    service.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            // eslint-disable-next-line no-underscore-dangle
            if (error?.response?.status === 401 && !originalRequest._retry) {
                // eslint-disable-next-line no-underscore-dangle
                originalRequest._retry = true;

                try {
                    const response = await axios.create(options).post(
                        `${apiEndPoint}/refresh`,
                    );

                    if (response.status === 200) {
                        refresh(response.data);
                        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;

                        return axios(originalRequest);
                    }

                    throw new Error('Refresh token failed');
                } catch (err) {
                    refresh(null);

                    return Promise.reject(err);
                }
            } else {
                return Promise.reject(error);
            }
        },
    );

    return service;
};

export default class ApiClient {
    constructor (baseUrl, accessToken, refresh) {
        this.client = getClient(baseUrl, accessToken, refresh);
    }

    get (path) {
        return this.client.get(path)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }

    delete (path) {
        return this.client.delete(path)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }

    post (path, data = {
    }) {
        return this.client.post(path, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }

    put (path, data = {
    }) {
        return this.client.put(path, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    }

    download (path, data = {
    }) {
        return this.client.request({
            method: 'POST',
            url: path,
            responseType: 'blob',
            data: data,
        }).then((response) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');

            const contentDisposition = response.headers['content-disposition'];

            const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

            const fileName = match[1];

            link.href = downloadUrl;
            link.setAttribute('download', fileName ?? 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    }
}
