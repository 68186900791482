import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import EntityLink from '../shared/entityLink';
import Icon from './icon';
import { getStatusTypeLabel } from './services';
import { viewEndPoint } from './constants';
import useStyles from './theme';
import useAuthorization from '../../hooks/authorization';
import { roles } from '../authorization/constants';


export const Property = (props) => {
    const { data } = props;
    const classes = useStyles();
    const authorization = useAuthorization();

    if (!data) {
        return null;
    }

    let id = null;

    const status = null;

    let displayName = null;

    if (typeof data === 'object') {
        // eslint-disable-next-line prefer-destructuring
        id = data.id;
        // eslint-disable-next-line prefer-destructuring
        id = data.status;
        // eslint-disable-next-line prefer-destructuring
        displayName = `${id} (${getStatusTypeLabel(status)})`;
    } else {
        id = data;
        displayName = data;
    }

    return (
        <EntityLink
            title={displayName}
            label={displayName}
            href={`${viewEndPoint}/${id}`}
            disabled={Boolean(!(authorization.isInRoles(roles.system) || authorization.isInRoles(roles.administrator)))}
            avatar={
                <Avatar
                    className={classes.avatar}
                >
                    {<Icon />}
                </Avatar>
            }
        />
    );
};

export default Property;
