import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
    avatar: {
        color: '#FFF',
        backgroundColor: '#3DB5D6',
    },
}));

export default useStyles;
