import { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { loginPath } from '../components/authorization/constants';
import { GlobalContext } from '../globalState';

const useError = () => {
    const { dispatch } = useContext(GlobalContext);
    const history = useHistory();

    const handle = (status) => {
        switch (status) {
            case 401:
                dispatch({
                    user: null,
                });
                history.push(`${loginPath}?returnUrl=${encodeURI(window.location.href)}`);
                break;
            default:
                history.push(`/error/${status}`);
                break;
        }
    };

    return {
        handle,
    };
};

export default useError;
