export const key = 'purchases';
export const endPoint = '/purchases';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/purchases';

export const initialValue = {
    number: null,
    date: new Date(),
    deliveryDate: new Date(),
    thirdParty: null,
    lots: [],
    etAmount: 0,
    vatAmount: 0,
    itAmount: 0,
};

export const status = {
    draft: 0,
    confirmed: 1,
};

export const type = {
    purchase: 0,
    sale: 1,
};

export const apiStatusEndPoint = `/purchases/statuses?statuses=${status.confirmed}&statuses=${status.preparation}`;


export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La commande d\'achat {0} a été créée avec succés',
        put: 'La commande d\'achat {0} a été mise à jour avec succés',
        'delete': 'La commande d\'achat {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
