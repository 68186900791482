/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState, useContext } from 'react';
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import { viewEndPoint as orderViewEndPoint } from '../order/constants';
import { columns as orderColumns, sort as orderSort } from '../order/columns';
import { Table } from '../shared/grid';
import Tabs from '../shared/tabs';
import useApi from './api';
import { editEndPoint, getOrderApiEndPoint } from './constants';
import Info from './info';
import Report from './report';
import { ToolbarHeader } from './toolbar';
const Details = () => {
    const toolBar = useToolbar();
    const authorization = useAuthorization();
    const dialog = useDialog();
    const history = useHistory();
    const api = useApi();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez vous supprimer l'employé${data.name}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const download = (data) => {
        api.download(data.id);
    };

    const getActions = () => ({
        edit: {
            onClick: onEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: onDelete,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        customs: [
            {
                icon: <PrintIcon fontSize="small" />,
                label: 'Imprimer',
                onClick: download,
                hide: !authorization.isInRoles(roles.system, roles.administrator),
            },
        ],
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };
    const handleOrderRowClick = (event, key, row) => {
        const path = `${orderViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };
    const load = () => {
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    };

    useEffect(() => {
        load();
    }, [refresh]);

    const GeneralContent = ({ data }) => <Info {...data} />;

    const ReportContent = ({ data }) => <Report data={data} />;

    const OrdersContent = ({ data }) => (
        <Table
            rowKey="id"
            sort={orderSort}
            endPoint={getOrderApiEndPoint(data.id)}
            onRowClick={handleOrderRowClick}
            columns={orderColumns}
        />
    );

    const tabs = [
        {
            label: 'Général',
            component: <GeneralContent data={state} />,
        },
        {
            label: 'Commandes',
            component: <OrdersContent data={state} />,
        },
        {
            label: 'Rapports',
            component: <ReportContent data={state} />,
            hide: !authorization.isInRoles(roles.system, roles.supervisor),
        },
    ];

    return (

        <>
            {
                state &&
                (<Page title = {`[${state.id}] Employé`}>
                    <>
                        <Tabs tabs={tabs} data={state} />
                    </>
                </Page>
                )
            }
        </>

    );
};

export default Details;
