import { makeStyles, createStyles } from '@material-ui/core/styles';

import Color from './color';

const useStyles = makeStyles((theme) => createStyles({
    avatar: {
        color: theme.palette.getContrastText(Color[500]),
        backgroundColor: Color[500],
    },
}));

export default useStyles;
