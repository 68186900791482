import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getStatusKeyValues } from './services';

const Status = (props) => {
    const { id, name, label, value, onChange } = props;

    return (
        <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="status-select-label">{label}</InputLabel>
            <Select
                id={id}
                labelId="status-select-label"
                name={name}
                label={label}
                value={value}
                onChange={onChange}
            >
                {getStatusKeyValues().map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default Status;
