import React from 'react';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

export const Line = () => (
    <Grid container spacing={1}>
        <Grid item xs={12}>
            <Skeleton variant="rect" height={20} />
        </Grid>
        <Grid item xs={12}>
            <Skeleton variant="rect" animation="wave" height={160} />
        </Grid>
        <Grid item xs={12}>
            <Skeleton variant="rect" animation="wave" height={20} />
        </Grid>
    </Grid>
);

export default Line;
