import { useContext } from 'react';

import { GlobalContext } from '../globalState';

const useAuthorization = () => {
    const { state } = useContext(GlobalContext);

    const isInRoles = (...args) => {
        if (!args || args.length <= 0 || !state.user || !state.user.roles || state.user.roles.length <= 0) {
            return false;
        }

        for (const role of args) {
            if (state.user.roles.indexOf(role) > -1) {
                return true;
            }
        }

        return false;
    };

    return {
        isInRoles,
    };
};

export default useAuthorization;
