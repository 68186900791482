import { status } from './constants';

export const getStatusTypeLabel = (value) => {
    switch (value) {
        case status.draft:
            return 'Brouillon';
        case status.canceled:
            return 'Annulée';
        case status.validated:
            return 'Validée';
        case status.partiallyPaid:
            return 'Partiellement Payée';
        case status.paid:
            return 'Payée';
        default:
            return 'Inconnu';
    }
};

export const getStatusKeyValues = () => {
    const result = [];

    result.push({
        key: status.canceled,
        value: getStatusTypeLabel(status.canceled),
    });
    result.push({
        key: status.draft,
        value: getStatusTypeLabel(status.draft),
    });
    result.push({
        key: status.validated,
        value: getStatusTypeLabel(status.validated),
    });

    return result;
};

