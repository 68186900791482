import React, { useEffect } from 'react';


const Page = (props) => {
    const { title } = props;


    useEffect(() => {
        document.title = title;
    }, []);

    return (
        <>
            {props.children}
        </>
    );
};

export default Page;
