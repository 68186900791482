import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../globalState';
import useStyles from './theme';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import AssessmentIcon from '@material-ui/icons/Assessment';
import 'moment/locale/fr';
import useAuthorization from '../../hooks/authorization';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { default as Sales } from './sales/index';
import { default as Store } from './store/index';
import { reportsType } from './constants';
const Index = () => {
    const { state: { user } } = useContext(GlobalContext);
    const classes = useStyles();
    const toolBar = useToolbar();
    const [type, setType] = useState(reportsType.sales);
    const authorization = useAuthorization();

    const handleChange = (event) => {
        setType(event.target.value);
    };


    const getTitleComponent = () => <CardHeader avatar={<Avatar className={classes.avatar}><AssessmentIcon /></Avatar> }
        action={
            authorization.isInRoles(roles.system) &&
            <FormControl className={classes.formControl}>
                <InputLabel id="Reports-type-select-label"></InputLabel>
                <Select
                    labelId="Reports-type-select-label"
                    id="Reports-type-select"
                    name="ReportsType"
                    label="Reports"
                    value={type}
                    onChange={handleChange}
                >
                    <MenuItem value={reportsType.sales}>Ventes</MenuItem>
                    <MenuItem value={reportsType.store}>Stock</MenuItem>
                </Select>
            </FormControl>
        }
    />;


    useEffect(() => {
        toolBar.show({
            title: getTitleComponent(),
        });
    }, [type]);


    return (
        <>  {
            (authorization.isInRoles(roles.system, roles.supervisor) || user?.employee) && (type === reportsType.sales) &&
           (
               <Sales/>
           )
        }
        {
            authorization.isInRoles(roles.system) && (type === reportsType.store) &&
        (
            <Store/>
        )
        }
        </>
    );
};

export default Index;
