import React, { useContext } from 'react';

import { Formik } from 'formik';

import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useApi from './api';
import Form from './form';
import validationSchema from './validationSchema';

const Edit = (props) => {
    const dialog = useDialog();
    const api = useApi();
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.save(data.order.id, toEditDataContract(data));
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        price: data.price,
        quantity: data.quantity,
        discountType: parseInt(data.discountType, 10),
        discountRate: data.discountRate,
        lotId: data.lot?.id,
    });

    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={
                {
                    ...props.values,
                }
            }
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    );
};

export default Edit;
