import { default as useApiBase } from '../../hooks/api';
import { endPoint, apiEndPoint, apiSettings } from './constants';

export default function useApi () {
    const api = useApiBase(apiSettings);

    const get = (id) => api.get(`${apiEndPoint}/${id}`)
        .then((response) => response.data);

    const deleTe = (id) => api.deleTe(`${apiEndPoint}/${id}`, endPoint);

    const save = (data) => {
        if (data.id) {
            return api.put(`${apiEndPoint}/${data.id}`, data)
                .then((response) => response.data);
        }

        return api.post(apiEndPoint, data)
            .then((response) => response.data);
    };

    const catalogDownload = (data) => {
        api.download(`${apiEndPoint}/download/catalog`, data);
    };

    const detailedCatalogDownload = (data) => {
        api.download(`${apiEndPoint}/download/detailedCatalog`, data);
    };

    const storeDownload = (data) => {
        api.download(`${apiEndPoint}/download/store`, data);
    };

    return {
        get,
        deleTe,
        save,
        catalogDownload,
        detailedCatalogDownload,
        storeDownload,
    };
}
