import React from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: 'auto',
        position: 'relative',
        backgroundColor: 'transparent',
        height: 200,
    },
    center: {
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    topButton: {
        top: 5,
        position: 'absolute',
    },
    settingsButton: {
        right: 5,
        position: 'absolute',
    },
    refreshButton: {
        right: 25,
        position: 'absolute',
    },
}));

const Indicator = (props) => {
    const { header, subheader, value, subvalue1, subvalue2, valueColor, subvalueColor1, subvalueColor2 } = props;
    const classes = useStyles();

    return (
        <Paper className={classes.paper} elevation={1} square>
            <Box
                display="flex"
                justifyContent="center"
                bgcolor="grey.100"
                sx={
                    {
                        height: '30%',
                    }
                }
            >
                <Box p={1}>
                    <Typography variant="subtitle1" align="center" color="primary">
                        {header}
                    </Typography>
                    <Typography variant="subtitle2" align="center" color="textPrimary">
                        {subheader}
                    </Typography>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={
                    {
                        height: '50%',
                    }
                }
            >
                <Box>
                    <Typography
                        variant="h5"
                        align="center"
                        style={
                            valueColor
                                ? {
                                    color: valueColor,
                                }
                                : undefined
                        }
                    >
                        {value}
                    </Typography>
                    {
                        subvalue1 &&
                        (
                            <Typography
                                variant="subtitle2"
                                align="center"
                                style={
                                    subvalueColor1
                                        ? {
                                            color: subvalueColor1,
                                        }
                                        : undefined
                                }
                            >
                                {subvalue1}
                            </Typography>
                        )
                    }
                    {
                        subvalue2 &&
                        (
                            <Typography
                                variant="subtitle2"
                                align="center"
                                style={
                                    subvalueColor2
                                        ? {
                                            color: subvalueColor2,
                                        }
                                        : undefined
                                }
                            >
                                {subvalue2}
                            </Typography>
                        )
                    }
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                bgcolor="grey.100"
                sx={
                    {
                        height: '20%',
                    }
                }
            />
            {
                false && (
                    <>
                        <IconButton size="small" className={clsx(classes.topButton, classes.settingsButton)} disabled>
                            <SettingsIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" className={clsx(classes.topButton, classes.refreshButton)} disabled>
                            <RefreshIcon fontSize="small" />
                        </IconButton>
                    </>
                )
            }
        </Paper>
    );
};

export default Indicator;
