

export const endPoint = '/lots';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/lots';
export const expirationApiEndPoint = '/lots/expiration';
export const availablesApiEndPoint = '/lots/availables';

export const initialValue = {
    code: '',
    inCatalog: true,
    catalogMinimumQuantity: 0,
    price: -1,
    purchaseDiscountType: 0,
    purchaseDiscountRate: 0,
    saleDiscountType: 1,
    saleDiscountRate: 0,
    purchaseDate: new Date(),
    expirationDate: new Date(),
    warehousePosition: '',
    warehouse: {
        id: 1,
        name: '1',
    },
    initialQuantity: 0,
    currentQuantity: 0,
};

export const getOrdersApiEndPoint = (id) => `${apiEndPoint}/${id}/orders`;

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'Le lot {0} a été créé avec succés',
        put: 'Le lot {0} a été mis à jour avec succés',
        'delete': 'Le lot {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
