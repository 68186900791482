import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Organization from '../organization/property';
import Employee from '../employee/property';
import Address from '../address/property';
import Property from '../shared/property';
import { endPoint } from './constants';
import { getStatusTypeLabel } from '../invoice/services';
import EmailAddress from '../shared/emailAddress';
import Phone from '../shared/phone';
import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import useAuthorization from '../../hooks/authorization';
import { roles } from '../authorization/constants';

const Info = (props) => {
    const name = props.link ? <Link to={`${endPoint}/${props.id}`}>{props.name}</Link> : props.name;
    const authorization = useAuthorization();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} >
                <Property label="Nom" value={<Organization data={name} />}/>
                <Property label="Email" value={<EmailAddress data={props.email} />}/>
                <Property label="Téléphone" value={<Phone data={props.phone} />} />
                <Property label="Fax" value={<Phone data={props.fax} />} />
                <Property label="N° de registre de commerce" value={props.crn} />
                <Property label="N° d'identification fiscale" value={props.tin} />
                <Property label="Article d'imposition" value={props.ta} />
                <Property label="Adresse" value={<Address data={props?.address} />} />
            </Grid>
            <Grid item xs={12} sm={4} >
                <Property label="Employé" value={<Employee data={props.employee} />} />
                <Property label="Statut de la facture" value={getStatusTypeLabel(props.defaultInvoiceStatus)} />
            </Grid>
            {
                authorization.isInRoles(roles.system) && (
                    <Grid item xs={12} sm={4} >
                        <Property label="Nombre de commandes" value={props.ordersCount} />
                        <Property label="Date de la dernière commande" value={formatUtcToLocalDate(props.lastOrderDate)} />
                        <Property label="Nombre de commandes en cours" value={props.currentOrdersCount} />
                        <Property label="Date de la dernière commande en cours" value={formatUtcToLocalDate(props.currentLastOrderDate)} />
                        <Property label="Montant facturé" value={formatter.format(props.billedAmount)} />
                        <Property label="Solde des factures" value={formatter.format(props.balanceInvoices)} />
                        <Property label="Montant des commandes" value={formatter.format(props.orderAmount)} />
                        <Property label="Solde des commandes" value={formatter.format(props.balanceOrders)} />
                        <Property label="Montant payé" value={formatter.format(props.paidAmount)} />
                        <Property label="Date du dernier paiement" value={formatUtcToLocalDate(props.lastPaymentDate)} />
                        <Property label="Montant du bénéfice" value={formatter.format(props.profitAmount)} />
                    </Grid>)
            }
        </Grid>
    );
};

export default Info;
