import React from 'react';

import MoneyIcon from '@material-ui/icons/Money';

import { ToolbarHeader as SharedToolbarHeader } from '../shared/toolbar';
import { endPoint } from './constants';
import useStyles from './theme';

export const ToolbarHeader = (props) => {
    const classes = useStyles();
    const { data } = props;

    return (
        <SharedToolbarHeader icon={<MoneyIcon />} className={classes.avatar} data={data} endPoint={endPoint} />
    );
};
