import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { getDiscountTypeLabel } from '../shared/services';
import { getDiscountRate, getQuantity } from './services';


export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Produit',
        field: 'productName',
        sorting: true,
    },
    {
        label: 'N° de lot',
        field: 'lotCode',
        sorting: true,

    },
    {
        label: 'Date de péremp.',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'PU',
        field: 'price',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.price),
    },
    {
        label: 'Remise',
        field: 'discountType',
        sorting: true,
        render: (index, column, row) => getDiscountTypeLabel(row.discountType),

    },
    {
        label: '% Remise',
        field: 'discountRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getDiscountRate(row),
    },
    {
        label: 'Quantité',
        field: 'quantity',
        align: 'right',
        sorting: true,
        render: (index, column, row) => getQuantity(row),
    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'TVA',
        field: 'vatRate',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.vatAmount),
    },

    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),

    },
];

