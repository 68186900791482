import { formatPercent } from '../../services/number';

export const getDiscountRate = (data) => {
    switch (data.discountType) {
        case 0:
        case '0':
            return `${formatPercent(data.finantialDiscountRate)} (${formatPercent(data.discountRate)})`;
        default:
            return formatPercent(data.discountRate);
    }
};

export const getQuantity = (data) => {
    switch (data.discountType) {
        case 0:
        case '0':
            return data.totalQuantity;
        default:
            return `${data.totalQuantity} (${data.quantity})`;
    }
};
