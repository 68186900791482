/* eslint-disable max-len */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatter } from '../../services/currency';
import { formatPercent } from '../../services/number';
import Property from '../shared/property';
import { getDiscountTypeLabel } from '../shared/services';
import { getDiscountRate, getQuantity } from './services';

const Abstract = (props) => {
    const {
        extraCostHonorary, price, publicPrice, freeQuantity, vatRate,
        vatAmount, etAmount, itAmount, label, lotId, lotCode,
    } = props;


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Property label="Produit" value={label} />
                <Property label="Code du lot" value={lotId} />
                <Property label="N° de lot" value={lotCode} />
                <Property label="SHP" value={formatter.format(extraCostHonorary)} />
                <Property label="PU" value={formatter.format(price)} />
                <Property label="PPA" value={formatter.format(publicPrice)} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="Remise appliquée" value={getDiscountTypeLabel(props)} />
                <Property label="Taux appliquée" value={getDiscountRate(props)} />
                <Property label="Quantité gratuite" value={freeQuantity} />
                <Property label="Quantité Total" value={getQuantity(props)} />
                <Property label="TVA" value={formatPercent(vatRate)} />
                <Property label="Montant de la TVA" value={formatter.format(vatAmount)} />
                <Property label="Montant HT" value={formatter.format(etAmount)} />
                <Property label="Net à payer" value={formatter.format(itAmount)} valueColor="secondary" />
            </Grid>
        </Grid>
    );
};

export default Abstract;
