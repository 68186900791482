/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react';
import Phone from '../shared/phone';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useLoader from '../../hooks/loader';
import useToolbar from '../../hooks/toolbar';
import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { roles } from '../authorization/constants';
import Property from '../shared/property';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { editEndPoint } from './constants';
import { getStatusLabel, getModeLabel } from './services';
import { ToolbarHeader } from './toolbar';
import Organization from '../organization/property';

const Details = () => {
    const globalClasses = useGlobalStyles();
    const loader = useLoader();
    const toolBar = useToolbar();
    const history = useHistory();
    const dialog = useDialog();
    const api = useApi();
    const authorization = useAuthorization();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        history.push(`${editEndPoint}/${data.id}`);
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez-vous supprimer le règlement ${data.id}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const getActions = () => ({
        edit: {
            onClick: onEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: onDelete,
            hide: !authorization.isInRoles(roles.system),
        },
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: getActions(data),
        });
    };

    useEffect(() => {
        loader.show();
        api.get(id)
            .then((data) => {
                handleToolbarDisplay(data);
                setState(data);
            });
    }, [refresh]);

    return (

        <div className={globalClasses.content}>
            {
                state &&
                (<Page title = {`[${state.id}] Règlement`}>
                    <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Property label="Mode" value={getModeLabel(state.type)} />
                                    <Property label="Date" value={formatUtcToLocalDate(state.date)} />
                                    <Property label="Statut" value={getStatusLabel(state.status)} />
                                    <Property label="Référence" value={state.reference} />
                                    <Property label="Note" value={state.note} />
                                    <Property label="Montant" valueColor={state.amount > 0 ? 'default' : 'secondary'} value={formatter.format(state.amount)} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Property label="Client" value={<Organization data={state.thirdParty} />}/>
                                    <Property label="Email" value={state.thirdParty.email} />
                                    <Property label="Téléphone" value={<Phone data={state.thirdParty.phone} />}/>
                                    <Property label="Fax" value={<Phone data={state.thirdParty.fax} />}/>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Property label="Bénéficiaire" value={<Organization data={state.beneficiary} />}/>
                                </Grid>
                    </Grid>
                 </Page>
                )
            }
        </div>

    );
};

export default Details;
