import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import { Table } from '../shared/grid';
import { default as useGlobalStyles } from '../shared/theme';
import { viewEndPoint, editEndPoint, apiEndPoint } from './constants';
import { columns, sort } from './columns';
import { ToolbarHeader } from './toolbar';

const List = () => {
    const globalClasses = useGlobalStyles();
    const toolBar = useToolbar();
    const history = useHistory();
    const authorization = useAuthorization();
    const { state: { refresh } } = useContext(GlobalContext);

    const onAdd = () => {
        history.push(editEndPoint);
    };

    const handleRowClick = (event, key, row) => {
        const path = `${viewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    useEffect(() => {
        toolBar.show({
            title: <ToolbarHeader />,
            actions: {
                add: {
                    onClick: onAdd,
                    hide: !authorization.isInRoles(roles.system, roles.administrator, roles.supervisor, roles.operator),
                },
            },
        });
    }, [refresh]);

    return (
        <Page title="Sociétés">
            <div className={globalClasses.content}>
                <Table
                    rowKey="id"
                    sort={sort}
                    endPoint={apiEndPoint}
                    onRowClick={handleRowClick}
                    columns={columns}
                />
            </div>
        </Page>
    );
};

export default List;
