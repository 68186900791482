import { object, string, ref } from 'yup';

import { passwordRegExp } from '../../../constants/global';

const validationSchema = object({
    email: string('Saisissez l\'email').required('L\'emal est requis')
        .email('L\'adresse email n\'est pas valide'),
    password: string().required('Le mot de passe est requis')
        // eslint-disable-next-line max-len
        .matches(passwordRegExp, 'Le mot de passe doit contenir un caractère majuscule, un caractère minuscule, un chiffre et un caractère non alphanumérique. il doit comporter au moins six caractères'),
    confirmedPassword: string().required('Le mot de passe de confirmation est requis')
        .oneOf([ref('password'), null], 'Le mot de passe et le mot de passe de confirmation ne correspondent pas'),
});

export default validationSchema;
