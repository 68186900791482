import { object, date, array, number, string } from 'yup';

const validationSchema = object({
    thirdParty: object().nullable()
        .required('Le vendeur est requis'),
    date: date().typeError('La date n\'est pas valide')
        .required('La date est requise'),
    deliveryDate: date().typeError('La date n\'est pas valide')
        .required('La date est requise'),
    lots: array()
        .of(
            object().shape({
                product: object().nullable()
                    .required('Le product est requis'),
                code: string('Saisissez le n° de lot').required('Le N° de lot est requis'),
                price: number().typeError('Le prix n\'est pas valide')
                    .min(0, 'Le prix de vente n\'est pas valide')
                    .required('Le prix est requise'),
                purchaseDiscountType: number().typeError('La remise d\'achat n\'est pas valide')
                    .min(0, 'La remise d\'achat n\'est pas valide')
                    .required('La remise d\'achat est requise'),
                purchaseDiscountRate: number().typeError('Le taux UG d\'achat n\'est pas valide')
                    .min(0, 'Le UG remise d\'achat n\'est pas valide')
                    .required('Le taux UG d\'achat est requis'),
                saleDiscountType: number().typeError('La remise de vente n\'est pas valide')
                    .min(0, 'La remise de vente n\'est pas valide')
                    .required('La remise de vente est requise'),
                saleDiscountRate: number().typeError('Le taux UG de vente n\'est pas valide')
                    .min(0, 'Le taux de UG de vente n\'est pas valide')
                    .required('Le taux UG de vente est requis'),
                expirationDate: date().typeError('La date de péremption n\'est pas valide')
                    .required('La date de péremption est requise'),
                initialQuantity: number().typeError('La quantité achetée n\'est pas valide')
                    .min(1, 'La quantité achetée n\'est pas valide')
                    .required('La quantité achetée est requise'),
            }),
        ),
});

export default validationSchema;
