import { formatter } from '../../services/currency';
import { formatUtcToLocalDate } from '../../services/date';
import { getStatusTypeLabel } from './services';
import { getStatusTypeLabel as getInvoiceStatusTypeLabel } from '../invoice/services';
// import { Profit } from '../shared/property';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'N°',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Client',
        field: 'thirdParty.name',
        sorting: true,
    },
    {
        label: 'Département',
        field: 'thirdParty.address.city.department.name',
        sorting: true,
    },
    {
        label: 'Vendeur',
        field: 'seller.name',
        sorting: true,
    },
    {
        label: 'Date',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.status),
    },
    {
        label: 'Statut de la facture',
        field: 'lastInvoice.status',
        sorting: true,
        render: (index, column, row) => getInvoiceStatusTypeLabel(row.lastInvoice?.status),
    },
    {
        label: 'Montant HT',
        field: 'etAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.etAmount),
    },
    {
        label: 'Montant TVA',
        field: 'vatAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.vatAmount),
    },
    {
        label: 'Montant TTC',
        field: 'itAmount',
        align: 'right',
        sorting: true,
        render: (index, column, row) => formatter.format(row.itAmount),
    },

    /* {
        label: 'Bénéfice',
        field: 'profitAmount',
        align: 'right',
        render: (index, column, row) => formatter.format(row.profitAmount),
        // render: (index, column, row) => <Profit data={row.profitAmount} />,
    },*/
];
