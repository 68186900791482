/* eslint-disable max-len */
import { initialValue as addressInitialValue } from '../address/constants';
import { status } from '../invoice/constants';
import { fileType } from '../shared/constants';
import { getUtcStartCurrentYear } from '../../services/date';
export const endPoint = '/organizations';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/organizations';


const startDate = getUtcStartCurrentYear();

export const stateInitialValue = {
    startDate,
    endDate: new Date(),
    fileType: fileType.pdf,
};

export const getOrdersApiEndPoint = (id) => `${apiEndPoint}/${id}/orders`;

export const getPurchasesApiEndPoint = (id) => `${apiEndPoint}/${id}/purchases`;

export const getInvoicesApiEndPoint = (id) => `${apiEndPoint}/${id}/invoices`;

export const getPaymentApiEndPoint = (id) => `${apiEndPoint}/${id}/payments`;

export const getPurchasesPaymentApiEndPoint = (id) => `${apiEndPoint}/${id}/purchasesPayments`;

export const getStateApiEndPoint = (data) => `${apiEndPoint}/${data.id}/${data.fileType}/download?startDate=${data.startDate}&endDate=${data.endDate}`;

export const initialValue = {
    name: '',
    employee: null,
    email: '',
    phone: '',
    fax: '',
    address: addressInitialValue,
    crn: '',
    tin: '',
    ta: '',
    defaultInvoiceStatus: status.draft,
};

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La société {0} a été créée avec succés',
        put: 'La société {0} a été mise à jour avec succés',
        'delete': 'La société {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
