import React, { useEffect, useState, useRef } from 'react';

import { Formik } from 'formik';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { toDateFromForms } from '../../services/date';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { initialValue, endPoint, viewEndPoint } from './constants';
import Form from './forms/edit';
import { ToolbarHeader } from './toolbar';
import validationSchema from './validationSchema';

const Edit = () => {
    const globalClasses = useGlobalStyles();
    const dialog = useDialog();
    const toolBar = useToolbar();
    const { id } = useParams();
    const [state, setState] = useState(null);
    const history = useHistory();
    const api = useApi();
    const formRef = useRef(null);

    const onSave = () => {
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
        }));
    };

    const onCancel = (data) => {
        if (data?.id) {
            history.push(`${viewEndPoint}/${data?.id}`);
        } else {
            history.push(endPoint);
        }
    };

    const onSubmit = (data) => {
        dialog.hide();
        api.save(toEditDataContract(data)).then((d) => {
            if (!data?.id) {
                history.push(`${viewEndPoint}/${d.id}`);
            }
        });
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        code: data.code,
        inCatalog: data.inCatalog,
        catalogMinimumQuantity: data.catalogMinimumQuantity,
        price: data.price,
        purchaseDiscountType: parseInt(data.purchaseDiscountType, 10),
        purchaseDiscountRate: data.purchaseDiscountRate,
        saleDiscountType: parseInt(data.saleDiscountType, 10),
        saleDiscountRate: data.saleDiscountRate,
        purchaseDate: toDateFromForms(data.purchaseDate),
        expirationDate: toDateFromForms(data.expirationDate),
        initialQuantity: data.initialQuantity,
        currentQuantity: data.currentQuantity,
        warehousePosition: data.warehousePosition,
        warehouseId: data.warehouse.id,
        productId: data.product.id,
        quantityPackage: data.quantityPackage,
        lastUpdateDate: data.lastUpdateDate,
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: {
                cancel: {
                    onClick: onCancel,
                },
                save: {
                    onClick: onSave,
                },
            },
        });
    };

    useEffect(() => {
        if (id) {
            api.get(id)
                .then((data) => {
                    setState(data);
                });
        } else {
            const newState = {
                ...initialValue,
                product: history.location.state?.product,
            };

            setState(newState);
            history.replace();
        }
    }, []);

    useEffect(() => {
        handleToolbarDisplay(state);
    }, []);

    return (

        <div className={globalClasses.content}>
            {
                state && (
                    <Page title={state?.id ? `[${state.id}] Lot:modification ` : 'Lot:création'}>
                        <Formik
                            enableReinitialize
                            render={(props) => <Form forwardedRef={formRef} {...props} />}
                            initialValues={state}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        />
                    </Page>)
            }
        </div>

    );
};

export default Edit;
