import { useContext } from 'react';

import { GlobalContext } from '../globalState';

export default function useSession () {
    const { dispatch, state: { user } } = useContext(GlobalContext);

    const get = () => user;

    const update = (session) => {
        dispatch({
            user: session,
        });
    };

    const clear = () => {
        dispatch({
            user: null,
        });
    };

    return {
        get,
        update,
        clear,
    };
}
