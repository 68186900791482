import React, { createRef } from 'react';

import { Grid, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';

import editorLogo128 from '../../../images/lilauz-solutions_128_28.png';
import logo from '../../../images/logo-60.png';
import useApi from '../api';
import { initialValue } from './constants';
import validationSchema from './validationSchema';

const useStyles = makeStyles((theme) => createStyles({
    marginTop: {
        marginTop: '5%',
    },
    editorLogoContainer: {
        marginTop: '5%',
        height: 28,
        position: 'relative',
    },
    editorLogo: {
        position: 'absolute',
        right: 0,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const Form = () => {
    const classes = useStyles();
    const form = createRef();
    const api = useApi();

    const onSubmit = (data, { setFieldError, setSubmitting }) => {
        setFieldError('general', '');

        api.signin(data)
            .catch((error) => {
                setFieldError('general', error.message);
                setSubmitting(false);
            });
    };

    return (
        <Grid container height="100%">
            <Grid item xs={12} className={classes.marginTop}>
                <Paper className={classes.paper}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} align="center">
                            <img src={logo} alt="logo" height="60" />
                            <Typography>
                            We provide the best tool
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Formik
                                initialValues={initialValue}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {
                                    (props) => {
                                        const {
                                            values: { username, password, rememberMe },
                                            touched,
                                            errors,
                                            isSubmitting,
                                            handleChange,
                                            handleSubmit,
                                        } = props;

                                        return (
                                            <form ref={form} onSubmit={handleSubmit}>
                                                {
                                                    errors.general && (
                                                        <FormHelperText error>
                                                            {errors.general}
                                                        </FormHelperText>
                                                    )
                                                }
                                                <TextField
                                                    fullWidth
                                                    helperText={touched.username ? errors.username : ''}
                                                    error={Boolean(errors.username)}
                                                    name="username"
                                                    label="Email"
                                                    margin="normal"
                                                    variant="filled"
                                                    value={username}
                                                    onChange={handleChange}
                                                />
                                                <TextField
                                                    fullWidth
                                                    helperText={touched.password ? errors.password : ''}
                                                    error={Boolean(errors.password)}
                                                    name="password"
                                                    label="Mot de passe"
                                                    margin="normal"
                                                    type="password"
                                                    variant="filled"
                                                    value={password}
                                                    onChange={handleChange}
                                                />
                                                <FormControl fullWidth>
                                                    <FormControlLabel
                                                        control={
                                                            (
                                                                <Checkbox
                                                                    name="rememberMe"
                                                                    checked={rememberMe}
                                                                    onChange={handleChange}
                                                                />
                                                            )
                                                        }
                                                        label="Se rappeler de moi"
                                                    />
                                                </FormControl>
                                                <FormControl margin="normal" fullWidth>
                                                    <Button fullWidth type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Connexion...' : 'Se connecter'}
                                                    </Button>
                                                </FormControl>
                                            </form>

                                        );
                                    }
                                }
                            </Formik>
                        </Grid>
                        <Grid item xs={12} className={classes.marginTop}>
                            <Typography variant="caption">
                                {`Powered by Lilauz solutions, © ${new Date().getFullYear()} phimcloud. All rights reserved.`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.editorLogoContainer}>
                            <img src={editorLogo128} className={classes.editorLogo} alt="logo" height="28" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Form;
