import 'moment/locale/fr';
import moment from 'moment-timezone';

export const timeZone = 'Africa/Algiers';
export const dateDisplayPattern = 'DD/MM/yyyy';
export const monthDisplayPattern = 'MMM YYYY';
export const dateFormPattern = 'yyyy-MM-DD';

export const getUtcToday = () => moment.utc();

export const getUtcStartToday = () => moment.utc(getUtcToday().clone()
    .tz(timeZone)
    .startOf('day')
    .utc()
    .format());

export const getUtcEndToday = () => moment.utc(getUtcToday().clone()
    .tz(timeZone)
    .endOf('day')
    .utc()
    .format());

export const getUtcStartCurrentMonth = () => moment.utc(getUtcToday().clone()
    .tz(timeZone)
    .startOf('month')
    .utc()
    .format());

export const getUtcEndCurrentMonth = () => moment.utc(getUtcToday().clone()
    .tz(timeZone)
    .endOf('month')
    .utc()
    .format());

export const getUtcStartCurrentYear = () => moment.utc(getUtcToday().clone()
    .tz(timeZone)
    .startOf('year')
    .endOf('day')
    .utc()
    .format());

const getCounterDate = (date) => {
    if (date !== null) {
        const date1 = new Date(date);
        const date2 = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        const diffTime = date2.getTime() - date1.getTime();
        const diffDays = Math.floor(diffTime / oneDay);

        if (diffDays === 0) {
            return 'Ajourd\'huit';
        } else if (diffDays < 30) {
            return `Il y a ${diffDays} jours`;
        } else if (diffDays > 365) {
            return `Il y a ${Math.floor(diffDays / 365)} ans`;
        } else if (Math.round(diffDays / 30.5) === 12) {
            return `Il y a ${Math.floor(diffDays / 30.5)} mois`;
        }

        return `Il y a ${Math.round(diffDays / 30.5)} mois`;
    }

    return null;
};

export const utcToLocalDateTime = (date) => (date ? moment(date).tz(timeZone) : null);

export const formatUtcToLocalDate = (date, pattern = dateDisplayPattern) => (date ? utcToLocalDateTime(date).format(pattern) : null);

export const formatUtcToLocalTime = (date) => utcToLocalDateTime(date).format('HH:mm:ss');

export const formatUtcToLocalDateTime = (date) => utcToLocalDateTime(date).format('DD MMM YYYY HH:mm:ss');

export const localDateTimeToUtc = (date) => moment(date).utc();

export const toDateFromForms = (date) => moment(date, dateFormPattern).format(dateFormPattern);

export const toUtcFromForms = (date) => localDateTimeToUtc(toDateFromForms(date));

export const toDateForForms = (date) => moment(date).format(dateFormPattern);

export const dateDisplayFormat = (date) => date.format(dateDisplayPattern);

export const monthDisplayFormat = (date) => date.format(monthDisplayPattern);

export const dateCounter = (data) => getCounterDate(data);
