import moment from 'moment-timezone';

export const endPoint = '/payments';
export const viewEndPoint = `${endPoint}/view`;
export const editEndPoint = `${endPoint}/edit`;
export const apiEndPoint = '/payments';
export const initialValue = {
    mode: 0,
    status: 1,
    date: moment().startOf('day'),
    amount: 0,
    reference: '',
    note: '',
    beneficiary: null,
};

export const status = {
    canceled: -1,
    draft: 0,
    confirmed: 1,
};

export const mode = {
    cash: 0,
    check: 1,
    transfer: 2,
    credit: 3,
};

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'Le règlement {0} a été créé avec succés',
        put: 'Le règlement {0} a été mis à jour avec succés',
        'delete': 'Le règlement {0} a été suprimé avec succés',
    },
    error: 'Une erreur est survenue',
};
