import React from 'react';
import Grid from '@material-ui/core/Grid';
import Property from '../shared/property';
import { Link } from 'react-router-dom';
import { formatter } from '../../services/currency';
import { formatPercent } from '../../services/number';
import { endPoint } from './constants';
import { getStorageTypeLabel } from './services';

const Info = (props) => {
    const name = props.link ? <Link to={`${endPoint}/${props.id}`}>{props.name}</Link> : props.name;
    const { brand, form, dosage, conditioning, storageType, price, extraCostHonorary, vatRate, publicPrice, marginRate, quantity } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Property label="Nom commercial" value={brand} />
                <Property label="DCI" value={name} />
                <Property label="Forme" value={form} />
                <Property label="Dosage" value={dosage} />
                <Property label="Conditionnement" value={conditioning} />
                <Property label="Nature de stockage" value={getStorageTypeLabel(storageType)} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="PU" value={formatter.format(price)} />
                <Property label="SHP" value={formatter.format(extraCostHonorary)} />
                <Property label="TVA" value={formatPercent(vatRate)} />
                <Property label="PPA" value={formatter.format(publicPrice)} />
                <Property label="Marge" value={formatPercent(marginRate)} />
                <Property label="Quantité disponible" value={quantity} />
            </Grid>
        </Grid>
    );
};

export default Info;
