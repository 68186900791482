export const apiCityEndPoint = '/cities';

export const initialValue = {
    line1: '',
    line2: '',
    cityId: null,
};

export const apiSettings = {
    loader: true,
    alert: true,
    refresh: true,
    success: {
        post: 'La ville {0} a été créée avec succés',
        put: 'La ville {0} a été mise à jour avec succés',
        'delete': 'La ville {0} a été suprimée avec succés',
    },
    error: 'Une erreur est survenue',
};
