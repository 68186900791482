import { formatUtcToLocalDate } from '../../services/date';
import { getStatusTypeLabel } from './services';

export const sort = {
    field: 'id',
    direction: 'desc',
};

export const columns = [
    {
        label: 'Code',
        field: 'id',
        sorting: true,
    },
    {
        label: 'Départements',
        field: 'departments',
        sorting: true,
        render: (index, column, row) => row.departments?.map((item) => item.name).join(','),
    },
    {
        label: 'Date',
        field: 'date',
        sorting: true,
        render: (index, column, row) => formatUtcToLocalDate(row.date),
    },
    {
        label: 'Statut',
        field: 'status',
        sorting: true,
        render: (index, column, row) => getStatusTypeLabel(row.status),
    },
    {
        label: 'Livreur',
        field: 'deliveryMan.name',
        sorting: true,
    },
];
