import React, { useState, useEffect, useRef } from 'react';

import { Formik } from 'formik';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import useDialog from '../../hooks/dialog';
import useToolbar from '../../hooks/toolbar';
import { toDateFromForms } from '../../services/date';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { initialValue, endPoint, viewEndPoint } from './constants';
import Form from './forms/edit';
import { ToolbarHeader } from './toolbar';
import validationSchema from './validationSchema';

const Edit = () => {
    const globalClasses = useGlobalStyles();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const history = useHistory();
    const api = useApi();
    const { id } = useParams();
    const [state, setState] = useState(null);
    const formRef = useRef(null);

    const onSave = () => {
        formRef.current.dispatchEvent(new Event('submit', {
            cancelable: true,
        }));
    };

    const onCancel = (data) => {
        if (data?.id) {
            history.push(`${viewEndPoint}/${data?.id}`);
        } else {
            history.push(endPoint);
        }
    };

    const onSubmit = (data) => {
        dialog.hide();
        api.save(toEditDataContract(data)).then((d) => {
            if (!data?.id) {
                history.push(`${viewEndPoint}/${d.id}`);
            }
        });
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        status: data.status,
        date: toDateFromForms(data.date),
        deliveryManId: data.deliveryMan?.id,
    });

    const handleToolbarDisplay = (data) => {
        toolBar.show({
            data,
            title: <ToolbarHeader data={data} />,
            actions: {
                cancel: {
                    onClick: onCancel,
                },
                save: {
                    onClick: onSave,
                },
            },
        });
    };

    useEffect(() => {
        if (id) {
            api.get(id)
                .then((data) => {
                    setState(data);
                });
        } else {
            setState(initialValue);
        }
    }, []);

    useEffect(() => {
        handleToolbarDisplay(state);
    }, [state]);

    return (

        <div className={globalClasses.content}>
            {
                state && (
                    <Page title={state?.id ? `[${state.id}] Livraison:modification ` : 'Livraison:création'}>
                        <Formik
                            enableReinitialize
                            render={(props) => <Form forwardedRef={formRef} {...props} />}
                            initialValues={state}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        />
                    </Page>)
            }
        </div>

    );
};

export default Edit;
