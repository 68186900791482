import { formatUtcToLocalDate, formatUtcToLocalTime } from '../../services/date';

export const formatDates = (entity) => {
    const { createDate, lastUpdateDate } = entity;

    if (lastUpdateDate) {
        return `${formatUtcToLocalDate(lastUpdateDate)} à ${formatUtcToLocalTime(lastUpdateDate)}`;
    }

    if (createDate) {
        return `${formatUtcToLocalDate(createDate)} à ${formatUtcToLocalTime(createDate)}`;
    }

    return null;
};
