import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { blue } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoneyIcon from '@material-ui/icons/Money';
import useApi from './api';
import useAuthorization from '../../hooks/authorization';
import { roles } from '../authorization/constants';
import { status as deliveryStatus } from './constants';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Action = (props) => {
    const classes = useStyles();
    const { title, icon, onClick } = props;

    return (
        <ListItem button onClick={onClick}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {icon}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} />
        </ListItem>
    );
};

const Print = (props) => {
    const { data } = props;
    const authorization = useAuthorization();
    const client = useApi();

    const areaDownload = () => {
        client.areaDownload(data.id);
    };

    const recoveryDownload = () => {
        client.recoveryDownload(data.id);
    };

    const missionDownload = () => {
        client.missionDownload(data.id);
    };

    return (
        <List>
            {
                authorization.isInRoles(roles.system, roles.administrator) && (
                    <>
                        <Action icon={<LocationOnIcon />} title="Distribution" onClick={() => areaDownload()} />
                        <Action icon={<MoneyIcon />} title="Distribution et recouvrement" onClick={() => recoveryDownload()} />
                    </>
                )
            }
            {
                data?.status >= deliveryStatus.scheduled && authorization.isInRoles(roles.system, roles.administrator) && data?.deliveryMan &&
                <Action icon={<AssignmentIcon />} title="Ordre de mission" onClick={() => missionDownload()} />
            }
        </List>
    );
};

export default Print;
