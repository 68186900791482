import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import EntityLink from '../shared/entityLink';
import Icon from './icon';
import { viewEndPoint } from './constants';
import useStyles from './theme';
import { roles } from '../authorization/constants';
import useAuthorization from '../../hooks/authorization';


export const Property = (props) => {
    const { data } = props;
    const classes = useStyles();
    const authorization = useAuthorization();

    if (!data) {
        return null;
    }

    const { id } = data;
    const displayName = `${data.product.displayName}`;

    return (
        <EntityLink
            title={displayName}
            label={displayName}
            href={`${viewEndPoint}/${id}`}
            disabled={Boolean(!(authorization.isInRoles(roles.system) || authorization.isInRoles(roles.administrator)))}
            avatar={
                <Avatar
                    className={classes.avatar}
                >
                    {<Icon />}
                </Avatar>
            }
        />
    );
};

export default Property;
