import { default as useApiBase } from '../../../hooks/api';
import { apiEndPoint, apiSettings } from './constants';

export default function useApi () {
    const api = useApiBase(apiSettings);

    const deleTe = (deliveryId, id) => api.deleTe(`${apiEndPoint}/${deliveryId}/items/${id}`);

    const save = (deliveryId, data) => api.post(`${apiEndPoint}/${deliveryId}/items`, data);

    const deleteByDepartement = (deliveryId, id) => {
        api.deleTe(`${apiEndPoint}/${deliveryId}/departments/${id}/items`);
    };

    const deleteByCity = (deliveryId, id) => {
        api.deleTe(`${apiEndPoint}/${deliveryId}/cities/${id}/items`);
    };

    const onOrderDeliveryDeleteConfirmed = (deliveryId, id) => {
        api.deleTe(`${apiEndPoint}/${deliveryId}/items/${id}`);
    };

    return {
        deleTe,
        save,
        deleteByDepartement,
        deleteByCity,
        onOrderDeliveryDeleteConfirmed,
    };
}
