import React from 'react';
import Grid from '@material-ui/core/Grid';
import { formatUtcToLocalDate } from '../../services/date';
import Address from '../address/property';
import Organization from '../organization/property';
import Property from '../shared/property';
import EmailAddress from '../shared/emailAddress';
import Phone from '../shared/phone';
import { getStatusTypeLabel } from './services';
import Employee from '../employee/property';

const Info = (props) => {
    const { id, date, deliveryDate, seller, note, status, thirdParty, children } = props;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Property label="Numéro" value={id} />
                <Property label="Statut" value={getStatusTypeLabel(status)} />
                <Property label="Date" value={formatUtcToLocalDate(date)} />
                <Property label="Date de livraison" value={formatUtcToLocalDate(deliveryDate)} />
                <Property label="Note" value={note} />
                <Property label="Employé" value={<Employee data={seller} />} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Property label="Vendeur" value={<Organization data={thirdParty} />} />
                <Property label="Email" value={<EmailAddress data={thirdParty?.email} />} />
                <Property label="Téléphone" value={<Phone data={thirdParty?.phone} />} />
                <Property label="Fax" value={<Phone data={thirdParty?.fax} />} />
                <Property label="N° de registre de commerce" value={thirdParty?.crn} />
                <Property label="N° d'identification fiscale" value={thirdParty?.tin} />
                <Property label="Article d'imposition" value={thirdParty?.ta} />
                <Property label="Adresse" value={<Address data={thirdParty?.address} />} />
            </Grid>
            { children }
        </Grid>
    );
};

export default Info;
