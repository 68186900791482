import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import { formatAddress } from './services';

const useStyles = makeStyles((theme) => createStyles({
    part: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whitespace: 'nowrap',
    },
}));

export const Property = (props) => {
    const { data } = props;
    const classes = useStyles();

    if (!data) {
        return null;
    }

    const { line1, line2, city } = data;

    const address = formatAddress(data);

    const getPart = (part) => {
        if (part) {
            return (
                <div className={classes.part}>
                    {part}
                </div>
            );
        }

        return null;
    };

    return (
        <Link target="_blank" title={address} href={`https://www.google.com/maps?q=${encodeURI(address)}`}>
            {getPart(line1)}
            {getPart(line2)}
            {getPart(`${city?.zipCode ?? ''} ${city?.name}`)}
            {getPart(city?.department?.country?.name)}
        </Link>
    );
};

export default Property;
