
export const fileType = {
    csv: 'Csv',
    pdf: 'Pdf',
};

export const getFileTypeLabel = (file) => {
    if (file === 0) {
        return 'Csv';
    }

    return 'Pdf';
};

