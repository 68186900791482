import React, { useContext } from 'react';
import { Formik } from 'formik';
import { GlobalContext } from '../../globalState';
import useDialog from '../../hooks/dialog';
import useApi from './api';
import Form from './form';
import validationSchema from './validationSchema';

const Edit = (props) => {
    const dialog = useDialog();
    const api = useApi();
    const { state } = useContext(GlobalContext);

    const onSubmit = (data) => {
        dialog.hide();
        api.save(toEditDataContract(data));
    };

    const toEditDataContract = (data) => ({
        id: data.id,
        name: data.name,
        brand: data.brand,
        form: data.form,
        dosage: data.dosage,
        conditioning: data.conditioning,
        price: data.price,
        marginRate: data.marginRate,
        extraCostHonorary: data.extraCostHonorary,
        vatRate: data.vatRate,
        publicPrice: data.publicPrice,
        storageType: data.storageType,
    });

    return (
        <Formik
            render={(props) => <Form setRef={state.form.setRef} {...props} />}
            initialValues={props.values}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    );
};

export default Edit;
