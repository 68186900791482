import 'moment/locale/fr';
import moment from 'moment-timezone';

import { monthDisplayFormat, timeZone } from '../../../../services/date';
import { colors } from '../constants';
import { getSellersGroupedData, getRandomColor } from '../services';

export const getCounts = (data, displayGlobal, employee) => {
    const group = getSellersGroupedData(data, 'month');

    if (!group) {
        return null;
    }

    const result = {
        labels: group.map((item) => monthDisplayFormat(item.date.clone().tz(timeZone))),
        datasets: [],
    };

    if (displayGlobal) {
        result.datasets.push({
            label: 'Ventes',
            data: group.map((item) => item.count),
            // fill: false,
            backgroundColor: colors.global.count,
        });
    }

    if (employee) {
        const countData = [];

        group.forEach((item) => {
            let count = 0;

            data.forEach((item1) => {
                if (item1.sellerId === employee.id && item.date.isSame(moment.utc(item1.date).tz(timeZone)
                    .startOf('month')
                    .utc())) {
                    count += item1.count;
                }
            });

            countData.push(count);
        });

        result.datasets.push({
            label: employee.name,
            data: countData,
            // fill: false,
            backgroundColor: colors.employee.count,
        });
    }

    return result;
};

export const getAmounts = (data, turnoverDisplay, profitDisplay, employee) => {
    const group = getSellersGroupedData(data, 'month');

    if (!group) {
        return null;
    }

    const result = {
        labels: group.map((item) => monthDisplayFormat(item.date.clone().tz(timeZone))),
        datasets: [],
    };

    if (turnoverDisplay) {
        result.datasets.push({
            label: 'Chiffre',
            data: group.map((item) => item.turnover),
            backgroundColor: colors.global.turnover,
        });
    }

    if (turnoverDisplay) {
        result.datasets.push({
            label: 'Chiffre positif',
            data: group.map((item) => item.positive),
            backgroundColor: colors.global.positive,
        });
    }

    if (profitDisplay) {
        result.datasets.push({
            label: 'Bénéfice',
            data: group.map((item) => item.profit),
            backgroundColor: colors.global.profit,
        });
    }

    if (employee) {
        const turnoverData = [];
        const positiveData = [];
        const profitData = [];

        group.forEach((item) => {
            let turnover = 0;

            let positive = 0;

            let profit = 0;

            data.forEach((item1) => {
                if (item1.sellerId === employee.id && item.date.isSame(moment.utc(item1.date).tz(timeZone)
                    .startOf('month')
                    .utc())) {
                    turnover += item1.turnover;
                    positive += item1.positive;
                    profit += item1.profit;
                }
            });

            turnoverData.push(turnover);
            positiveData.push(positive);
            profitData.push(profit);
        });

        result.datasets.push({
            label: employee.name,
            data: turnoverData,
            backgroundColor: colors.employee.turnover,
        });

        result.datasets.push({
            label: `${employee.name} (Positif)`,
            data: positiveData,
            backgroundColor: colors.employee.positive,
        });

        if (profitDisplay) {
            result.datasets.push({
                label: `${employee.name} (Bénéfice)`,
                data: profitData,
                backgroundColor: colors.employee.profit,
            });
        }
    }

    return result;
};

export const getAmountsBySeller = (data, type = 'positive') => {
    const group = getSellersGroupedData(data, 'month', true);

    if (!group) {
        return null;
    }

    const groupByDate = {
    };

    group.forEach((item) => {
        if (!groupByDate[item.date]) {
            groupByDate[item.date] = {
                date: item.date,
                data: [],
            };
        }

        groupByDate[item.date].data.push(item);
    });

    const groupBySeller = {
    };

    group.forEach((item) => {
        if (!groupBySeller[item.sellerId]) {
            groupBySeller[item.sellerId] = {
                sellerId: item.sellerId,
                name: item.name,
                data: [],
            };
        }

        groupBySeller[item.sellerId].data.push(item);
    });

    const groupByDateArray = Object.values(groupByDate);
    const groupBySellerArray = Object.values(groupBySeller);

    const result = {
        labels: groupByDateArray.map((item) => monthDisplayFormat(item.date.clone().tz(timeZone))),
        datasets: [],
    };

    const preparedData = [];

    groupBySellerArray.forEach((item) => {
        const d = {
            sellerId: item.sellerId,
            name: item.name,
            data: [],
        };

        groupByDateArray.forEach((item1) => {
            let match = null;

            item.data.forEach((item2) => {
                if (item1.date.isSame(item2.date)) {
                    match = item2;
                }
            });

            if (match) {
                d.data.push(match);
                match = null;
            } else {
                d.data.push(null);
            }
        });

        preparedData.push(d);
    });

    for (let i = 0; i < groupBySellerArray.length; i++) {
        const item = groupBySellerArray[i];

        result.datasets.push({
            label: item.name ?? 'Inconnue',
            data: item.data.map((item1) => item1 ? item1[type] : 0),
            backgroundColor: getRandomColor(i)[300],
        });
    }

    return result;
};
