import { initialValue as addressInitialValue } from '../address/constants';

export const initialValue = {
    civility: 1,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    fax: '',
    address: addressInitialValue,
};
