import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    content: {
        flexGrow: 1,
    },
}));

export default function Content (props) {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            {props.children}
        </div>
    );
}
