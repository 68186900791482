import React, { useEffect, useState, useContext } from 'react';

import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Page from '../shared/page';
import { GlobalContext } from '../../globalState';
import useAuthorization from '../../hooks/authorization';
import useDialog from '../../hooks/dialog';
import useLoader from '../../hooks/loader';
import useToolbar from '../../hooks/toolbar';
import { roles } from '../authorization/constants';
import ModifiedOn from '../entity/modifiedOn';
import { viewEndPoint as lotViewEndPoint, editEndPoint as lotEditEndPoint } from '../lot/constants';
import { columns as lotColumns, sort } from '../lot/columns';
import { Table } from '../shared/grid';
import { default as useGlobalStyles } from '../shared/theme';
import useApi from './api';
import { getLotApiEndPoint } from './constants';
import Info from './info';
import useStyles from './theme';

const Details = () => {
    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    const loader = useLoader();
    const toolBar = useToolbar();
    const dialog = useDialog();
    const authorization = useAuthorization();
    const history = useHistory();
    const api = useApi();
    const { id } = useParams();
    const { state: { refresh } } = useContext(GlobalContext);
    const [state, setState] = useState(null);

    const onEdit = (data) => {
        dialog.updateProduct(data);
    };

    const onStockAdd = (data) => {
        history.push(lotEditEndPoint, {
            product: data,
        });
    };

    const onDelete = (data) => {
        dialog.confirm(`Voulez vous supprimer le produit ${data.name}`, () => {
            onDeleteConfirmed(data);
        });
    };

    const onDeleteConfirmed = (data) => {
        api.deleTe(data.id);
    };

    const getTitleComponent = (data) => <CardHeader
        avatar={<Avatar className={classes.avatar}><LocalPharmacyIcon /></Avatar>}
        title={data.displayName}
        subheader={<ModifiedOn {...data} />}
    />;

    const getActions = () => ({
        edit: {
            onClick: onEdit,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        'delete': {
            onClick: onDelete,
            hide: !authorization.isInRoles(roles.system, roles.administrator),
        },
        customs: [
            {
                icon: <PlusOneIcon fontSize="small" />,
                label: 'Approvisionner',
                onClick: onStockAdd,
                hide: !authorization.isInRoles(roles.system, roles.administrator),
            },
        ],
    });
    const handleRowClick = (event, key, row) => {
        const path = `${lotViewEndPoint}/${row.id}`;

        if (event?.ctrlKey) {
            const win = window.open(path, '_blank');

            win.focus();
        } else {
            history.push(path);
        }
    };

    useEffect(() => {
        loader.show();
        api.get(id)
            .then((data) => {
                setState(data);
                toolBar.show({
                    data,
                    title: getTitleComponent(data),
                    actions: getActions(),
                });
            });
    }, [refresh]);

    return (

        <div className={globalClasses.content}>
            {
                state &&
                (
                    <Page title ={`[${state.id}] Produit`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Info {...state} />
                            </Grid>
                            <Grid item xs={12}>
                                <Table
                                    rowKey="id"
                                    sort={sort}
                                    endPoint={getLotApiEndPoint(state.id)}
                                    onRowClick={handleRowClick}
                                    rowsPerPage={10}
                                    columns={lotColumns}
                                />
                            </Grid>
                        </Grid>
                    </Page>)
            }
        </div>

    );
};

export default Details;
