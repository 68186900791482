export const getCivilityLabel = (value) => {
    switch (value) {
        case 1:
            return 'Monsieur';
        case 2:
            return 'Madame';
        default:
            return '';
    }
};
